import { Button, Grid, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import Textarea from '../../components/Textarea'
import { DisabledInput } from '../../components/DisabledInput'
import { format } from 'date-fns'
import { Box } from '../../components/Box'
import Edit from '../../icons/Edit'
import Delete from '../../icons/Delete'
import { useDeleteVehicleOwner } from '../../model/api/useDeleteVehicleOwner'
import DeleteModal from '../../components/DeleteModal'
import getOverlappingDaysInIntervals from 'date-fns/esm/fp/getOverlappingDaysInIntervals/index.js'

const CustomerDetail = ({
  customer,
  onCustomerDetailModalClose,
  editClick,
}: {
  customer?: any
  onCustomerDetailModalClose: () => void
  editClick: () => void
}) => {
  const [notes] = useState(customer?.notes.length || 1)
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()
  const { mutate: mutateDelete } = useDeleteVehicleOwner()
  return (
    <>
      <Grid
        gridTemplateColumns={{ sm: 'initial', md: 'repeat(2, 1fr)' }}
        gap={'1rem 3rem'}
        mb={'1rem'}
        w={{ sm: '90%', md: 'initial' }}
        m={{ sm: '2rem auto 1rem auto', md: 'initial' }}
      >
        <DisabledInput defaultValue={customer.firstname} label="meno" />
        <DisabledInput defaultValue={customer.lastname} label="priezvisko" />
        <DisabledInput defaultValue={customer.contactEmail || '-'} label="e-mail" />
        <DisabledInput
          defaultValue={
            customer.birthdate ? format(new Date(customer.birthdate), 'dd.MM.yyyy') : '-'
          }
          label="dátum narodenia"
          type={'date'}
        />
        <DisabledInput defaultValue={customer.phoneNumber || '-'} label="telefónne číslo" />
      </Grid>
      <Box
        maxH={{ sm: '12rem', md: '27rem' }}
        overflowY={'auto'}
        m={'auto'}
        w={{ sm: '90%', md: 'initial' }}
        mt={{ sm: 'initial', md: '1rem' }}
      >
        {Array.from(Array(notes)).map((note: any, index: number) => {
          return (
            <>
              <Textarea
                isReadOnly
                label={`poznámky`}
                placeholder={customer.notes[index]?.note || ''}
              />
              <Box mb={'1rem'}></Box>
            </>
          )
        })}
      </Box>

      <Grid placeItems={'center'} mb={{ sm: '8rem', md: 'initial' }}>
        <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap={'1rem'} w={'50%'}>
          <Button
            variant={'blue'}
            onClick={() => {
              editClick()
              onCustomerDetailModalClose()
            }}
          >
            <Edit />
          </Button>
          <Button variant={'red'} onClick={() => onDeleteModalOpen()}>
            <Delete />
          </Button>
        </Grid>
      </Grid>
      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        onDeleteModalClose={onDeleteModalClose}
        titleValue={customer.firstname + ' ' + customer.lastname}
        onClickAction={() => {
          mutateDelete(customer.id)
          onCustomerDetailModalClose()
        }}
      />
    </>
  )
}

export default CustomerDetail
