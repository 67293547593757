import { VStack } from '@chakra-ui/react'
import { colors } from '../theme'
import IconButton from './IconButton'
import Delete from '../icons/Delete'
import Save from '../icons/Save'
import Cancel from '../icons/Cancel'
import Settings from '../icons/Settings'
import Edit from '../icons/Edit'
import ShareIcon from '../icons/ShareIcon'
import { EmailIcon } from '@chakra-ui/icons'

interface IRightSideMenu {
  blueClickCancel?: () => void
  blueClickEdit?: () => void
  greenClick?: () => void
  redClick?: () => void
  darkClickSettings?: () => void
  darkClickEmail?: () => void
  darkClickShare?: () => void
}

const RightSideMenu = ({ blueClickEdit, blueClickCancel, greenClick, redClick, darkClickSettings, darkClickEmail, darkClickShare }: IRightSideMenu) => {
  return (
    <VStack
      spacing={2}
      align={'center'}
      justify={'center'}
      p={2}
      position={'absolute'}
      right={0}
      top={'50%'}
      w={'6rem'}
      h={'20rem'}
      bg={colors.blue[100]}
      transform={'translateY(-50%)'}
    >
      {blueClickCancel && <IconButton icon={<Cancel />} variant={'blue'} onClick={blueClickCancel} />}
      {blueClickEdit && <IconButton icon={<Edit />} variant={'blue'} onClick={blueClickEdit} />}
      {darkClickSettings && <IconButton icon={<Settings />} variant={'dark'} onClick={darkClickSettings} />}
      {darkClickShare && <IconButton icon={<ShareIcon />} variant={'dark'} onClick={darkClickShare} />}
      {darkClickEmail && <IconButton icon={<EmailIcon />} variant={'dark'} onClick={darkClickEmail} />}
      {greenClick && <IconButton
        icon={<Save />}
        variant={'green'}
        onClick={() => {
          greenClick()
        }}
      />}
      
      {redClick && <IconButton icon={<Delete />} variant={'red'} onClick={redClick} />}
    </VStack>
  )
}

export default RightSideMenu
