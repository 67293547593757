import { useMutation, useQueryClient } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

export const useForgottenPasswordChange = () => {
  const query = useQueryClient()
  return useMutation(
    ({ userLink, password }: { userLink: string; password: string }) =>
      api.post(`${ApiRouteEnum.USER_LINK}/${userLink}${ApiRouteEnum.RESTART_PASSWORD}`, {
        password: password,
      }),
    {
      mutationKey: [`${ApiRouteEnum.USER_LINK}${ApiRouteEnum.RESTART_PASSWORD}`],
      onSuccess: () => {
        query.invalidateQueries(`${ApiRouteEnum.USER_LINK}${ApiRouteEnum.RESTART_PASSWORD}`)
      },
    },
  )
}
