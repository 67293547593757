import { RegisterFormData } from '../constants/RegisterInitialValues'

const useInitialValuesRegisterStep2 = ({registerData}: {registerData: RegisterFormData}) => {
  const initialValues = {
    title: registerData.workspaceMarketingData.title || '',
    phone: registerData.phone.replace(/\s/g, '') || '',
    url: registerData.workspaceMarketingData.url || '',
    description: registerData.workspaceMarketingData.description || '',
    showOnWeb:
      registerData.workspaceMarketingData.showOnWeb !== undefined
        ? registerData.workspaceMarketingData.showOnWeb
        : false,
  }
  return {initialValues}
}

export default useInitialValuesRegisterStep2