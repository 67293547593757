import { useState, useEffect } from 'react'
import { Select as ChakraSelect, Flex, Grid } from '@chakra-ui/react'
import { Box } from './Box'
import '../css/select.css'
import DownChevronIcon from '../icons/DownChevronIcon'
import { colors } from '../theme'
import UpChevronIcon from '../icons/UpChevronIcon'

interface CustomSelectProps {
  field?: {
    value: any
    onChange: (value: any) => void
    onBlur: () => void
    name: string
  }
  title?: string
  variant?: string
  setFieldValue?: any
  label?: string
  options?: { label: string; isLast: boolean }[]
  boxWidth?: string
  isReadOnly?: boolean
  isDisabled?: boolean
  setFieldTouched?: any
}

const getSelectedLabel = (title: string | undefined, field: any): string => {
  if (title) {
    return title
  } else if (field && field.value.label !== undefined && field.value.label !== '') {
    return field.value.label
  } else {
    return 'zvoľte možnosť'
  }
}

export const Select: React.FC<CustomSelectProps> = ({
  field,
  label,
  title,
  variant = 'default',
  options,
  setFieldValue,
  boxWidth,
  isReadOnly,
  isDisabled = false,
  setFieldTouched,
}) => {
  const [selectedValue, setSelectedValue] = useState({
    label: getSelectedLabel(title, field),
    value: field ? field.value.value : '',
  })

  const [isOptionsOpen, setIsOptionsOpen] = useState(false)

  const handleSelect = (value: any) => {
    setSelectedValue(value)
    setIsOptionsOpen(false)
    if (field) {
      setFieldTouched(field.name, true)
      setFieldValue(field.name, value)
    }
  }

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen)
  }

  const closeOptions = (event: any) => {
    if (!event.target.closest('.custom-select')) {
      setIsOptionsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', closeOptions)

    return () => {
      document.removeEventListener('click', closeOptions)
    }
  }, [])
  const bgColor = (() => {
    switch (variant) {
      case 'default':
        return colors.blue[100]
      case 'dark':
        return colors.gray[700]
    }
  })()
  return (
    <Flex
      w={boxWidth ? boxWidth : '100%'}
      justifyContent={'flex-end'}
      flexFlow={'column'}
      alignItems={'flex-start'}
    >
      {label && (
        <Box fontSize={'0.8rem'} fontWeight={'semibold'}>
          {label}
        </Box>
      )}
      <Flex
        className="custom-select"
        fontSize={'0.8rem'}
        flexFlow={'column nowrap'}
        w={'100%'}
        h={'3rem'}
        bg={bgColor}
        fontWeight={'semibold'}
      >
        <Flex
          className={`select-selected ${isOptionsOpen ? 'select-arrow-active' : ''}`}
          onClick={!isReadOnly ? toggleOptions : undefined}
          cursor={!isDisabled ? 'pointer' : 'not-allowed'}
          h={'100%'}
          alignItems={'center'}
          color={variant !== 'default' ? colors.gray[50] : 'black'}
        >
          <Box />
          {selectedValue.label}
          <Grid placeItems={'center'} bg={colors.gray[700]} h={'100%'} w={'20%'}>
            {!isDisabled &&
              !isReadOnly &&
              (isOptionsOpen ? (
                <UpChevronIcon color={colors.gray[50]} fontSize={'1rem'} />
              ) : (
                <UpChevronIcon
                  color={colors.gray[50]}
                  fontSize={'1rem'}
                  transform={'rotate(180deg)'}
                />
              ))}
          </Grid>
        </Flex>
        <ChakraSelect
          {...field}
          name={field && field.name}
          isReadOnly
          value={field && field.value}
          onFocus={toggleOptions}
          style={{ display: 'none' }}
        >
          {options &&
            options.map((option: any, index: number) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
        </ChakraSelect>
        <Box className={`select-items ${isOptionsOpen ? '' : 'select-hide'}`}>
          {options &&
            options.map((option: any, index: number) => (
              <Box
                key={index}
                className={`select-item ${
                  option.label === selectedValue.label ? 'same-as-selected' : ''
                } ${option.isLast && 'isLast'}`}
                onClick={() => handleSelect(option)}
              >
                {option.label}
              </Box>
            ))}
        </Box>
      </Flex>
    </Flex>
  )
}
