import {
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import useGetAllVehicleOwners from '../../../model/api/useGetAllVehicleOwners'
import Spinner from '../../../components/Spinner'
import Button from '../../../components/Button'
import { Add } from '../../../icons/Add'
import { colors } from '../../../theme'
import PersonIcon from '../../../icons/PersonIcon'
import CustomerCard from '../../../components/CustomerCard'
import CustomerForm from '../CustomerForm'
import Menu from '../../../components/Menu'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

const CustomersOverviewMobile = () => {
  const { data, isLoading } = useGetAllVehicleOwners()
  const navigate = useNavigate()
  const [isOpenByDefault, setIsOpenByDefault] = useState(false)
  const {
    isOpen: isCreateCustomerModalOpen,
    onOpen: onCreateCustomerModalOpen,
    onClose: onCreateCustomerModalClose,
  } = useDisclosure()

  const location = useLocation()
  useEffect(() => {
    setIsOpenByDefault(location.state?.openByDefault || false)
  }, [location.state?.openByDefault])

  if (isLoading) {
    return (
      <>
        <Grid
          fontSize={'1.2rem'}
          fontWeight={'semibold'}
          w={'100%'}
          h={'4rem'}
          placeItems={'center'}
          bg={colors.gray[700]}
          color={colors.blue[100]}
          zIndex={100}
          position={'sticky'}
          top={'0px'}
        >
          Evidencia a spravovanie zákazníkov
        </Grid>
        <Grid h={'40rem'}>
          <Spinner />
        </Grid>
        <Menu type={'basic'} />
      </>
    )
  }

  return (
    <Flex w={'100%'} alignItems={'center'} flexFlow={'column'} bg={colors.blue[50]}>
      <Grid
        fontSize={'1.2rem'}
        fontWeight={'semibold'}
        w={'100%'}
        h={'4rem'}
        placeItems={'center'}
        bg={colors.gray[700]}
        color={colors.blue[100]}
        zIndex={100}
        position={'sticky'}
        top={'0px'}
      >
        Evidencia a spravovanie zákazníkov
      </Grid>
      <Flex
        mt={'2rem'}
        flexFlow={'column'}
        gap={'1rem'}
        w={'100%'}
        alignItems={'center'}
        pb={'8rem'}
        overflow={'auto'}
      >
        {data &&
          data.map((item: any) => {
            return <CustomerCard item={item} key={item.id} />
          })}
        <Button
          size="large"
          variant="basic"
          w={'90%'}
          h={'unset'}
          bg={colors.blue[100]}
          onClick={onCreateCustomerModalOpen}
        >
          <Flex w={'8rem'} alignItems={'center'} justifyContent={'space-around'} mb={'1rem'}>
            <Add fontSize={'2rem'} />
            <PersonIcon fontSize={'2rem'} />
          </Flex>
          pridať zákazníka
        </Button>
      </Flex>

      <Modal
        size={'full'}
        isOpen={isCreateCustomerModalOpen || isOpenByDefault}
        onClose={onCreateCustomerModalClose}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody w={'100%'} p={0}>
            <Grid
              fontSize={'1.2rem'}
              fontWeight={'semibold'}
              w={'100%'}
              h={'4rem'}
              placeItems={'center'}
              bg={colors.gray[700]}
              color={colors.blue[100]}
              zIndex={100}
              position={'sticky'}
              top={'0px'}
            >
              Evidencia a spravovanie zákazníkov
            </Grid>
            <CustomerForm
              setIsOpenByDefault={setIsOpenByDefault}
              onCreateCustomerModalClose={onCreateCustomerModalClose}
            />
            <Menu type={'basic'} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Menu back={() => navigate(-1)} type={'basic'} />
    </Flex>
  )
}

export default CustomersOverviewMobile
