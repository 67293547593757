import ApiRouteEnum from '../enums/ApiRouteEnum'
import { useMutation } from 'react-query'
import api from './api'

const useUpdatePassword = () => {
  return useMutation(
    ({
      updatePasswordData,
      id,
    }: {
      updatePasswordData: { password: string; newPassword: string }
      id: string
    }) =>
      api.post(`${ApiRouteEnum.USER}${ApiRouteEnum.CHANGE_PASSWORD}`, updatePasswordData),
    {
      mutationKey: [ApiRouteEnum.USER],
    },
  )
}

export default useUpdatePassword
