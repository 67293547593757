import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Grid } from '@chakra-ui/react'
import CustomerDetail from '../pages/customerManagement/CustomerDetail'
import PersonIcon from '../icons/PersonIcon'
import Close from '../icons/Close'
import { colors } from '../theme'
import useIsMobile from '../model/helpers/useIsMobile'
import Menu from './Menu'

const CustomerDetailModal = ({
  isCustomerDetailModalOpen,
  onCustomerDetailModalClose,
  customer,
  editClick,
}: {
  isCustomerDetailModalOpen: boolean
  onCustomerDetailModalClose: () => void
  customer: any
  editClick: () => void
}) => {
  const isMobile = useIsMobile()
  if (isMobile) {
    return (
      <Modal
        size={'full'}
        isOpen={isCustomerDetailModalOpen}
        onClose={onCustomerDetailModalClose}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody w={'100%'} p={0}>
            <Grid
              fontSize={'1.2rem'}
              fontWeight={'semibold'}
              w={'100%'}
              h={'4rem'}
              placeItems={'center'}
              bg={colors.gray[700]}
              color={colors.blue[100]}
              zIndex={100}
              position={'sticky'}
              top={'0px'}
            >
              Evidencia a spravovanie zákazníkov
            </Grid>
            <CustomerDetail
              onCustomerDetailModalClose={onCustomerDetailModalClose}
              customer={customer}
              editClick={editClick}
            />
            <Menu type={'basic'} />
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
  return (
    <Modal
      size={{ sm: 'full', md: 'xl' }}
      isOpen={isCustomerDetailModalOpen}
      onClose={onCustomerDetailModalClose}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display={'flex'} alignItems={'center'} p={0}>
          <PersonIcon fontSize={'1.6rem'} color={colors.blue[500]} m={'0 6rem 0 2rem'} /> detail
          zákazníka{' '}
          <Close
            fontSize={'1.6rem'}
            bg={colors.red[500]}
            ml={'auto'}
            cursor={'pointer'}
            onClick={() => onCustomerDetailModalClose()}
            p={4}
          />
        </ModalHeader>
        <ModalBody>
          <CustomerDetail
            onCustomerDetailModalClose={onCustomerDetailModalClose}
            customer={customer}
            editClick={editClick}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CustomerDetailModal
