import { Grid, useDisclosure } from '@chakra-ui/react'
import { colors } from '../theme'
import { Image } from '@chakra-ui/react'
import useGetImage from '../model/api/useGetImage'
import ImageIcon from '../icons/ImageIcon'
import { Search } from '../icons/Search'
import { Box } from './Box'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

const ImagePlaceholder = ({ image, src }: { image?: any; src?: string }) => {
  const { data } = useGetImage(image)
  const { isOpen, onOpen, onClose } = useDisclosure()
  if (image) {
    return (
      <Box position={'relative'}>
        {data && (
          <>
            <Image src={data.path} aria-label="image" onClick={onOpen} cursor={'pointer'} />
            <Modal size={'2xl'} isOpen={isOpen} onClose={onClose} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalBody>
                  <Image src={data.path} aria-label="image" />
                </ModalBody>
              </ModalContent>
            </Modal>
          </>
        )}
      </Box>
    )
  }
  if (src) {
    return <Image src={src} aria-label="image" cursor={'pointer'} />
  }
  return (
    <Grid placeItems={'center'} h={'8rem'} bg={colors.gray[400]} cursor={'pointer'}>
      <ImageIcon color={colors.blue[100]} fontSize={'4rem'} />
    </Grid>
  )
}

export default ImagePlaceholder
