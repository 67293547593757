import { Query, useMutation, useQueryClient } from 'react-query'
import ApiRouteEnum from '../enums/ApiRouteEnum'
import api from './api'

export const useDeleteInsurance = () => {
  const query = useQueryClient()
  return useMutation((id) => api.delete(`${ApiRouteEnum.VEHICLE_INSURANCE}/${id}`), {
    onSuccess: () => {
      query.invalidateQueries(ApiRouteEnum.VEHICLE_INSURANCE)
      query.invalidateQueries(ApiRouteEnum.VEHICLE)
    }
  })
}
