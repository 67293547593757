import { useQuery } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useGetAllVehicleOwners = () => {
  const { data, isLoading } = useQuery(ApiRouteEnum.VEHICLE_OWNER, async () => {
    const response = await api.get(ApiRouteEnum.VEHICLE_OWNER)
    return response.data
  })
  return { data, isLoading }
}

export default useGetAllVehicleOwners
