import { Button as ChakraButton } from '@chakra-ui/react'

const Button = (props: any) => {
  if (props.size === 'full' || props.size === 'medium' || props.size === 'large') {
    return <ChakraButton {...props} />
  }
  return <ChakraButton {...props} />
}

export default Button
