import {
  Textarea as ChakraTextarea,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
} from '@chakra-ui/react'
import { colors } from '../theme'
import { Box } from './Box'
import Delete from '../icons/Delete'

interface TextareaProps {
  field?: {
    name: string
    value: string
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    onBlur: () => void
  }
  label: string
  placeholder?: string
  helperText?: string
  boxWidth?: string
  onDeleteClick?: () => void
  isReadOnly?: boolean
}

const Textarea = ({
  field,
  label,
  placeholder,
  helperText,
  boxWidth,
  isReadOnly,
  onDeleteClick,
  ...otherProps
}: TextareaProps) => {
  return (
    <Flex
      w={boxWidth ? boxWidth : '100%'}
      flexFlow={'column nowrap'}
      {...otherProps}
      position={'relative'}
    >
      <FormControl>
        <FormLabel fontWeight={'semibold'} variant={'textarea'}>
          {label}
        </FormLabel>
        <ChakraTextarea
          {...field}
          value={(field && field.value) || placeholder}
          bg={colors.blue[100]}
          isReadOnly={isReadOnly}
        />
        {helperText && (
          <FormHelperText textAlign={'center'} color={'gray'} fontWeight={'semibold'}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
      {onDeleteClick && (
        <Grid h={'3rem'} w={'3rem'} bg={colors.red[500]} position={'absolute'} right={0} onClick={onDeleteClick}>
          <Delete color={colors.blue[100]} m={'auto'} />
        </Grid>
      )}
    </Flex>
  )
}

export default Textarea
