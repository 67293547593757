import useIsMobile from "../../../model/helpers/useIsMobile"
import AccountOverview from "../AccountOverview"
import AccountMenuMobile from "../mobile/AccountMenuMobile"

const AccountManagementWrapper = () => {
  const isMobile = useIsMobile()

  return isMobile ? <AccountMenuMobile /> : <AccountOverview />
}
export default AccountManagementWrapper
