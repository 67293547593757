import { Grid, Flex } from '@chakra-ui/layout'
import { Input } from '../../components/Input'
import { Select } from '../../components/Select'
import { colors } from '../../theme'
import RightSideMenu from '../../components/RightSideMenu'
import { useLocation, useNavigate } from 'react-router-dom'
import RouterEnum from '../../model/enums/RouterEnum'
import { Box } from '../../components/Box'
import CarIcon from '../../icons/CarIcon'
import Textarea from '../../components/Textarea'
import MOTdropdown from '../../components/MOTdropdown'
import HighwaySignsDropdown from '../../components/HighwaySignsDropdown'
import InsuranceDropdown from '../../components/InsuranceDropdown'
import { useDeleteCar } from '../../model/api/useDeleteCar'
import { useDisclosure } from '@chakra-ui/react'
import { DisabledInput } from '../../components/DisabledInput'
import DeleteModal from '../../components/DeleteModal'
import CreateServiceModal from '../../components/CreateServiceModal'
import ApiRouteEnum from '../../model/enums/ApiRouteEnum'
import { useQueryClient } from 'react-query'
import { useCustomToast } from '../../components/Toast'

const VehicleDetail = () => {
  const query = useQueryClient()
  const { showCustomToast } = useCustomToast()
  const mutateDelete = useDeleteCar()
  const navigate = useNavigate()
  const location = useLocation()
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()
  const {
    isOpen: isConfirmModalOpen,
    onOpen: onConfirmModalOpen,
    onClose: onConfirmModalClose,
  } = useDisclosure()
  const car = location.state.car

  if (!car) {
    return null
  }
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        flexFlow: 'column',
        overflow: 'auto',
        paddingBottom: '3rem',
      }}
    >
      <Flex w={'45%'} m={'3rem 0'} justifyContent={'flex-start'}>
        <Grid w={'3rem'} h={'3rem'} bg={colors.gray[700]} placeItems={'center'}>
          <CarIcon color={colors.blue[500]} />
        </Grid>
        <Box w={'50%'} m={'0 auto'} fontSize={'2rem'} textAlign={'center'} fontWeight={'semibold'}>
          {car.displayName}
        </Box>
      </Flex>
      <Flex w={'45%'} gap={'1rem'}>
        <Flex w={'50%'} flexFlow={'column wrap'} columnGap={'2rem'} gap={'1rem'}>
          <DisabledInput
            bg={colors.blue[100]}
            name="vinCode"
            defaultValue={car.vinCode}
            label={'VIN'}
            component={Input}
          />
          <DisabledInput
            bg={colors.blue[100]}
            name="licensePlate"
            defaultValue={car.licensePlate ? car.licensePlate : '-'}
            label={'SPZ'}
            component={Input}
          />
          <DisabledInput
            bg={colors.blue[100]}
            name="brand"
            defaultValue={car.brand}
            label={'značka / výrobca vozidla'}
            component={Input}
          />
          <DisabledInput
            bg={colors.blue[100]}
            name="model"
            defaultValue={car.model}
            label={'model vozidla'}
            component={Input}
          />
          <DisabledInput
            bg={colors.blue[100]}
            name="engineModel"
            defaultValue={car.engineModel ? car.engineModel : '-'}
            label={'model motoru vozidla'}
            component={Input}
          />
          <DisabledInput
            bg={colors.blue[100]}
            name="color"
            defaultValue={car.color ? car.color : '-'}
            label={'farba vozidla'}
            component={Input}
          />
          <DisabledInput
            bg={colors.blue[100]}
            name="manufacturedAt"
            defaultValue={car.manufacturedAt}
            label={'rok výroby'}
            component={Input}
          />
        </Flex>
        <Flex w={'50%'} flexFlow={'column wrap'} columnGap={'2rem'} gap={'1rem'}>
          <Textarea isReadOnly label={'Poznámky'} placeholder={car.description} />
          <Select
            label={'Majiteľ vozidla'}
            title={`${car.vehicleOwner.firstname} ${car.vehicleOwner.lastname}`}
            isReadOnly={true}
          />
          {car.vehicleMOT && <MOTdropdown mode={'viewOnly'} vehicleMOT={car.vehicleMOT} />}
          {car.highwaySigns && (
            <HighwaySignsDropdown mode={'viewOnly'} highwaySigns={car.highwaySigns} />
          )}
          {car.insurance && (
            <InsuranceDropdown mode={'viewOnly'} vehicleInsurance={car.insurance} />
          )}
        </Flex>
      </Flex>

      <RightSideMenu
        blueClickEdit={() =>
          navigate('/' + RouterEnum.VEHICLE_MANAGEMENT + '/' + RouterEnum.VEHICLE_FORM_EDIT, {
            state: { carId: car.id },
          })
        }
        redClick={() => onDeleteModalOpen()}
        darkClickSettings={() => onConfirmModalOpen()}
      />

      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        onDeleteModalClose={onDeleteModalClose}
        titleValue={car.displayName}
        onClickAction={() => {
          mutateDelete.mutate(car.id, {
            onSuccess: () => {
              query.invalidateQueries(ApiRouteEnum.VEHICLE)
              query.invalidateQueries('workspaceByQuotas')
              navigate('/' + RouterEnum.VEHICLE_MANAGEMENT)
              showCustomToast({
                status: 'success',
                title: 'Položka úspešne odstránená',
                label: (
                  <div>
                    Vozidlo <b>{car.displayName}</b> bolo úspešne odstránené z evidencie
                  </div>
                ),
              })
            },
            onError: () => {
              showCustomToast({
                status: 'error',
                title: 'Položku sa nepodarilo odstrániť',
                label: (
                  <div>
                    Vozidlo <b>{car.displayName}</b> sa nepodarilo odstrániť
                  </div>
                ),
              })
            },
          })
        }}
      />

      <CreateServiceModal
        isConfirmModalOpen={isConfirmModalOpen}
        onConfirmModalClose={onConfirmModalClose}
        onClickAction={() =>
          navigate(
            '/' + RouterEnum.VEHICLE_SERVIS_OVERVIEW + '/' + RouterEnum.VEHICLE_SERVIS_CREATE,
            { state: { car: { label: car.displayName, value: car.id } } },
          )
        }
      />
    </Box>
  )
}

export default VehicleDetail
