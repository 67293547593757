import * as Yup from 'yup'
import { IS_EMAIL, IS_HOUSENUMBER, IS_IDENTIFICATION_NUMBER, IS_REQUIRED, IS_VAT, IS_ZIP } from '../constants/ValidationMessages'
export const RegisterStep1Schema = Yup.object({
  name: Yup.string().required(IS_REQUIRED),
  email: Yup.string()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, IS_EMAIL)
    .required(IS_REQUIRED),
  companyIdentificationNumber: Yup.string()
    .nullable()
    .optional()
    .matches(/^\d{6,18}$/, IS_IDENTIFICATION_NUMBER),
  vatId: Yup.string()
    .nullable()
    .optional()
    .matches(/^.{8,11}$/, IS_VAT),
  city: Yup.string().optional().nullable(),
  street: Yup.string().optional().nullable(),
  houseNumber: Yup.string()
    .optional()
    .nullable()
    .matches(/^[\d/]+$/, IS_HOUSENUMBER),
  zipCode: Yup.string()
    .optional()
    .nullable()
    .transform((value) => {
      return value ? value.replace(/\s/g, '') : value
    })
    .matches(/^\d{5}$/, IS_ZIP),
})
