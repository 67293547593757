import { useMutation } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useUpdateVehicle = () => {
  return useMutation(
    ({ updateCarData, id }: { updateCarData: any; id: string }) =>
      api.put(`${ApiRouteEnum.VEHICLE}/${id}`, updateCarData),
    {
      mutationKey: [ApiRouteEnum.VEHICLE],
    },
  )
}

export default useUpdateVehicle
