import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react'
import Button from './Button'
import { useEffect } from 'react'


const ConfirmModal = ({
  title,
  label,
  onSetRegisterStep,
}: {
  title: string
  label?: string
  onSetRegisterStep: React.Dispatch<React.SetStateAction<number>>
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    onOpen()
  }, [onOpen])
  return (
    <>
      <Modal
        variant={'confirm'}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>{label}</ModalBody>

          <ModalFooter>
            <Button variant={'blue'} mr={3} onClick={onClose}>
              ÁNO
            </Button>
            <Button
              variant={'red'}
              mr={3}
              onClick={() => {
                onClose()
                onSetRegisterStep((prev) => prev + 1)
              }}
            >
              NIE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ConfirmModal
