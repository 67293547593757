import useIsMobile from "../../../model/helpers/useIsMobile"
import WorkspaceOverview from "../WorkspaceOverview"
import WorkspaceMenu from "../mobile/WorkspaceMenu"

const WorkspaceOverviewWrapper = () => {
  const isMobile = useIsMobile()

  return isMobile ? <WorkspaceMenu /> : <WorkspaceOverview />
}
export default WorkspaceOverviewWrapper
