import * as Yup from 'yup'
import { IS_PASSWORD, IS_PASSWORD_CONFIRM, IS_REQUIRED } from '../constants/ValidationMessages'
export const passwordDataFormSchema = Yup.object({
  oldPassword: Yup.string().required(IS_REQUIRED),
  newPassword: Yup.string()
    .required(IS_REQUIRED)
    .min(8, IS_PASSWORD),
  confirmPassword: Yup.string()
    .required(IS_REQUIRED)
    .oneOf([Yup.ref('newPassword'), ''], IS_PASSWORD_CONFIRM),
})
