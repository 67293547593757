import { Flex, Grid } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useEffect } from 'react'
import { IS_REQUIRED } from '../../../model/constants/ValidationMessages'
import IRegister from '../../../model/interface/IRegister'
import { colors } from '../../../theme'
import { Box } from '../../../components/Box'
import ProgressBar from '../../../components/ProgressBar'
import { Input } from '../../../components/Input'
import getFieldStatus from '../../../model/helpers/StatusHelper'
import Menu from '../../../components/Menu'
import { RegisterStep1Schema } from '../../../model/schemas/RegisterStep1Schema'

const RegisterStep1Mobile: React.FC<IRegister> = ({
  registerData,
  onSetRegisterData,
  onSetRegisterStep,
}) => {
  useEffect(() => {
    if (registerData.email === '') {
      onSetRegisterData({
        ...registerData,
        email: registerData.user.email,
      })
    }
  }, [])

  const handleFormSubmit = (values: Yup.InferType<typeof RegisterStep1Schema>) => {
    onSetRegisterStep((prev) => prev + 1)
  }

  const handleFieldChange = (e: any) => {
    const { value, name } = e.target as HTMLInputElement
    if (name === 'city' || name === 'street' || name === 'houseNumber' || name === 'zipCode') {
      onSetRegisterData({
        ...registerData,
        address: {
          ...registerData.address,
          [name]: value,
          state: 'Slovensko',
        },
      })
    } else {
      onSetRegisterData({
        ...registerData,
        [name]: value,
      })
    }
  }

  const initialValues = {
    name: registerData.name || '',
    email: registerData.email !== '' ? registerData.email : registerData.user.email,
    companyIdentificationNumber: registerData.companyIdentificationNumber || '',
    vatId: registerData.vatId || '',
    city: registerData.address.city || '',
    street: registerData.address.street || '',
    houseNumber: registerData.address.houseNumber || '',
    zipCode: registerData.address.zipCode || '',
  }
  return (
    <>
      <Flex
        w={'100%'}
        h={'5rem'}
        bg={colors.gray[700]}
        flexFlow={'column nowrap'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Flex w={'25rem'} flexFlow={'column nowrap'} alignItems={'center'}>
          <ProgressBar />
        </Flex>
      </Flex>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={RegisterStep1Schema}
        reValidateMode={'onChange'}
      >
        {({ errors, touched, values, submitForm }) => {
          return (
            <>
              <Form style={{ height: '100%', overflow: 'auto', background: colors.blue[50] }}>
                <Grid
                  w={'85%'}
                  gap={'1rem'}
                  justifyItems={'center'}
                  onChange={handleFieldChange}
                  m={'2rem auto 8rem auto'}
                >
                  <Field
                    name="name"
                    label="názov servisu"
                    type="text"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'name')}
                  />
                  <Field
                    name="email"
                    label="e-mail"
                    type="email"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'email')}
                  />

                  <Box>Tento email bude použitý, pokiaľ zákazník odpíše na systémové emaily</Box>

                  <Field
                    name="companyIdentificationNumber"
                    label="IČO"
                    type="text"
                    component={Input}
                    status={getFieldStatus(
                      errors,
                      touched,
                      'companyIdentificationNumber',
                      true,
                      values,
                    )}
                  />
                  <Field
                    name="vatId"
                    label="DIČ"
                    type="text"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'vatId', true, values)}
                  />
                  <Field
                    name="city"
                    label="mesto"
                    type="text"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'city', true, values)}
                  />
                  <Field
                    name="street"
                    label="ulica"
                    type="text"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'street', true, values)}
                  />
                  <Field
                    name="houseNumber"
                    label="popisné číslo"
                    type="text"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'houseNumber', true, values)}
                  />
                  <Field
                    name="zipCode"
                    label="PSČ"
                    type="text"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'zipCode', true, values)}
                  />
                </Grid>
              </Form>
              <Menu
                type={'arrowsOnly'}
                back={() => onSetRegisterStep((prev) => prev - 1)}
                next={submitForm}
              />
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default RegisterStep1Mobile
