import useIsMobile from '../../../model/helpers/useIsMobile'
import VehicleFormMaster from '../VehicleFormMaster'
import VehicleFormMasterMobile from '../mobile/VehicleFormMasterMobile'

const VehicleFormMasterWrapper = () => {
  const isMobile = useIsMobile()

  return isMobile ? <VehicleFormMasterMobile /> : <VehicleFormMaster />
}
export default VehicleFormMasterWrapper
