import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react'
import { Box } from './Box'
import Button from './Button'
import Save from '../icons/Save'
import Cancel from '../icons/Cancel'

const ConfirmLeaveModal = ({
  isConfirmLeaveModalOpen,
  onConfirmLeaveModalClose,
  onGreenClickAction,
  onRedClickAction,
}: {
  isConfirmLeaveModalOpen: boolean
  onConfirmLeaveModalClose: () => void
  onGreenClickAction: () => void
  onRedClickAction: () => void
}) => {
  return (
    <Modal
      size={'xs'}
      isOpen={isConfirmLeaveModalOpen}
      onClose={onConfirmLeaveModalClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'}>Máte neuložené zmeny</ModalHeader>
        <ModalBody pb={'0'}>
          <Box textAlign={'center'} m={'2rem'}>
            Chcete uložiť zmeny?
          </Box>
        </ModalBody>

        <ModalFooter display={'flex'} justifyContent={'space-between'} gap={3} pt={'0'}>
          <Button variant={'green'} onClick={onGreenClickAction}>
            <Save />
          </Button>
          <Button variant={'blue'} onClick={onRedClickAction}>
            <Cancel />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmLeaveModal
