import { Flex } from '@chakra-ui/layout'
import { Box } from '../../components/Box'
import Button from '../../components/Button'
import { colors } from '../../theme'
import { Add } from '../../icons/Add'
import Settings from '../../icons/Settings'
import ServiceCard from '../../components/ServiceCard'
import useGetServices from '../../model/api/useGetServices'
import { useLocation, useNavigate } from 'react-router-dom'
import RouterEnum from '../../model/enums/RouterEnum'
import Spinner from '../../components/Spinner'
import { useMemo } from 'react'
import useGetAllCars from '../../model/api/useGetAllCars'
import { useCustomToast } from '../../components/Toast'

const ServicesOverview = () => {
  const location = useLocation()
  const { data, isLoading } = useGetServices(location.state && location.state.car)
  const { data: vehiclesData, isLoading: isVehiclesDataLoading } = useGetAllCars()
  const { showCustomToast, onCloseHandler, setIsHovering } = useCustomToast()
  const navigate = useNavigate()
  if (isLoading || isVehiclesDataLoading) {
    return <Spinner />
  }
  return (
    <Flex
      w={'100%'}
      h={'100%'}
      alignItems={'center'}
      flexFlow={'column'}
      overflow={'auto'}
      padding={'1%'}
    >
      <Box w={'75%'}>
        <Box
          fontSize={'1.8rem'}
          fontWeight={'semibold'}
          w={'100%'}
          textAlign={'center'}
          m={'1rem 0'}                                                
        >
          Prehľad aktívných servisov
        </Box>
        <Flex mt={'2rem'} flexFlow={'row wrap'} gap={'1rem'}>
          <Box
            w={'30%'}
            h={'unset'}
            onMouseEnter={
              vehiclesData?.length === 0
                ? () => {
                    setIsHovering(true)
                    showCustomToast({
                      status: 'warning',
                      title: 'Nie je možné pridať servis',
                      label: (
                        <div>Vo Vašom pracovisku je nutné najprv založiť aspoň jedno vozidlo.</div>
                      ),
                    })
                  }
                : undefined
            }
            onMouseLeave={() => onCloseHandler && onCloseHandler()}
          >
            <Button
              size="large"
              variant="basic"
              w={'100%'}
              h={'100%'}
              bg={colors.blue[100]}
              onClick={() => navigate(RouterEnum.VEHICLE_SERVIS_CREATE)}
              isDisabled={vehiclesData?.length === 0}
            >
              <Flex w={'8rem'} alignItems={'center'} justifyContent={'space-around'} mb={'1rem'}>
                <Add fontSize={'2rem'} />
                <Settings fontSize={'2rem'} />
              </Flex>
              pridať servis
            </Button>
          </Box>
          {data.map((item: any) => <ServiceCard item={item} key={item.id} />)}
        </Flex>
      </Box>
    </Flex>
  )
}

export default ServicesOverview
