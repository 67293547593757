import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Grid,
  Flex,
  Divider,
} from '@chakra-ui/react'
import { colors } from '../theme'
import { Field } from 'formik'
import getFieldStatus from '../model/helpers/StatusHelper'
import { Input } from '../components/Input'
import Button from '../components/Button'
import Delete from '../icons/Delete'
import { Add } from '../icons/Add'
import Textarea from '../components/Textarea'
import Switch from '../components/Switch'
import { DisabledInput } from './DisabledInput'
import Menu from './Menu'
import { useNavigate } from 'react-router-dom'
import BottomSideMenu from './BottomSideMenu '
import { useCustomToast } from './Toast'

const PriceModalMobile = ({
  isPriceModalOpen,
  onPriceModalClose,
  values,
  errors,
  touched,
  setFieldValue,
  isReadOnly,
}: {
  isPriceModalOpen: boolean
  onPriceModalClose: () => void
  values: any
  errors?: any
  touched?: any
  setFieldValue?: any
  isReadOnly?: boolean
}) => {
  const navigate = useNavigate()
  const { showCustomToast } = useCustomToast()
  if (isReadOnly) {
    return (
      <Modal size={'full'} isOpen={isPriceModalOpen} onClose={onPriceModalClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={'0'}>
            <Grid
              fontSize={'1.2rem'}
              fontWeight={'semibold'}
              w={'100%'}
              h={'4rem'}
              placeItems={'center'}
              bg={colors.gray[700]}
              color={colors.blue[100]}
              zIndex={100}
              // position={'sticky'}
              // top={'0px'}
            >
              Nastavenia ceny
            </Grid>
            <Grid
              gap={'1rem'}
              w={'85%'}
              m={'1rem auto 0 auto'}
              h={values.price !== null && values.price.priceItems.length > 0 ? '39rem' : 'initial'}
              overflow={'auto'}
              pb={'10rem'}
            >
              {values.price !== null &&
              values.price.priceItems &&
              values.price.priceItems.length > 0 ? (
                <>
                  {values.price?.priceItems &&
                    values.price?.priceItems.map((element: any, index: number) => {
                      return (
                        <Grid
                          textAlign={'center'}
                          fontWeight={'semibold'}
                          gap={'1rem'}
                          key={'PriceItems' + index}
                        >
                          <DisabledInput
                            bg={colors.blue[100]}
                            name={`price.priceItems[${index}].displayName`}
                            label={'názov'}
                            defaultValue={element.displayName}
                          />
                          <DisabledInput
                            bg={colors.blue[100]}
                            name={`price.priceItems[${index}].price`}
                            label={'cena'}
                            type={'number'}
                            defaultValue={element.price}
                          />
                          <Divider
                            borderWidth={'0.2rem 0'}
                            borderColor={colors.gray[700]}
                            bg={colors.gray[700]}
                          />
                        </Grid>
                      )
                    })}

                  <Grid textAlign={'center'} fontWeight={'semibold'} gap={'1rem'}>
                    <DisabledInput
                      bg={colors.blue[100]}
                      name="price.totalPrice"
                      label={'celková cena'}
                      defaultValue={values.price.totalPrice}
                    />
                  </Grid>
                </>
              ) : (
                <Grid textAlign={'center'} fontWeight={'semibold'} gap={'2rem'}>
                  <DisabledInput
                    bg={colors.blue[100]}
                    name="price.displayName"
                    defaultValue={values.price !== null ? values.price.displayName : ''}
                    label={'názov'}
                    component={Input}
                  />
                  <DisabledInput
                    bg={colors.blue[100]}
                    name="price.totalPrice"
                    defaultValue={values.price !== null ? values.price.totalPrice : ''}
                    label={'cena'}
                    type={'number'}
                    component={Input}
                  />
                </Grid>
              )}
              <Textarea
                label={'poznámky'}
                helperText={'Obsah sa zobrazí majiteľovi vozidla pri zdieľaní'}
                placeholder={
                  values.price !== null && values.price.description ? values.price.description : ''
                }
              />
              <Flex
                alignItems={'center'}
                justifyContent={'space-between'}
                fontSize={'0.8rem'}
                w={'100%'}
                fontWeight={'semibold'}
              >
                rozpoložkovať cenu
                <Switch
                  isChecked={
                    values.price !== null && values.price.priceItems.length > 0 ? true : false
                  }
                />
              </Flex>
            </Grid>
            <BottomSideMenu greenClickConfirm={onPriceModalClose} />
            <Menu back={() => navigate(-1)} type={'basic'} />
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
  return (
    <Modal size={'full'} isOpen={isPriceModalOpen} onClose={onPriceModalClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={'0'}>
          <Grid
            fontSize={'1.2rem'}
            fontWeight={'semibold'}
            w={'100%'}
            h={'4rem'}
            placeItems={'center'}
            bg={colors.gray[700]}
            color={colors.blue[100]}
            zIndex={100}
            // position={'sticky'}
            // top={'0px'}
          >
            Nastavenia ceny
          </Grid>
          <Grid
            gap={'1rem'}
            w={'85%'}
            m={'1rem auto 0 auto'}
            h={
              values.priceItemsChecked && values.price?.priceItems?.length > 0 ? '39rem' : 'initial'
            }
            overflow={'auto'}
            pb={'10rem'}
          >
            {values.priceItemsChecked ? (
              <>
                {values.price?.priceItems &&
                  values.price?.priceItems.map((item: any, index: number) => {
                    return (
                      <Grid
                        textAlign={'center'}
                        fontWeight={'semibold'}
                        gap={'1rem'}
                        key={'PriceItems' + index}
                      >
                        <Field
                          bg={colors.blue[100]}
                          name={`price.priceItems[${index}].displayName`}
                          label={'názov'}
                          component={Input}
                          readOnly={isReadOnly || false}
                          status={getFieldStatus(
                            errors,
                            touched,
                            `price.priceItems[${index}].displayName`,
                          )}
                        />
                        <Field
                          bg={colors.blue[100]}
                          name={`price.priceItems[${index}].price`}
                          label={'cena'}
                          type={'number'}
                          min={0}
                          component={Input}
                          status={getFieldStatus(
                            errors,
                            touched,
                            `price.priceItems[${index}].price`,
                          )}
                          onChange={(e: any) => {
                            const newPriceItems = [...(values.price?.priceItems || [])]
                            newPriceItems[index] = {
                              ...newPriceItems[index],
                              price: Number(Number(e.target.value).toFixed(2)) || undefined,
                            }
                            setFieldValue('price.priceItems', newPriceItems)
                            const totalPrice = newPriceItems.reduce(
                              (acc, item) => Number(acc) + Number(item.price || 0),
                              0,
                            )
                            setFieldValue('price.totalPrice', Number(Number(totalPrice).toFixed(2)))
                          }}
                        />
                        <Button
                          variant={'red'}
                          onClick={() => {
                            const newPriceItems = [...(values.price?.priceItems || [])]
                            newPriceItems.splice(index, 1)
                            setFieldValue('price.priceItems', newPriceItems)
                            const totalPrice = newPriceItems.reduce(
                              (acc, item) => acc + Number(item.price || 0),
                              0,
                            )
                            setFieldValue('price.totalPrice', Number(Number(totalPrice).toFixed(2)))
                          }}
                        >
                          <Delete />
                        </Button>
                        <Divider
                          borderWidth={'0.2rem 0'}
                          borderColor={colors.gray[700]}
                          bg={colors.gray[700]}
                        />
                      </Grid>
                    )
                  })}

                <Grid textAlign={'center'} fontWeight={'semibold'} gap={'1rem'}>
                  <Field
                    bg={colors.blue[100]}
                    name="price.totalPrice"
                    label={'celková cena'}
                    type={'number'}
                    component={Input}
                    status={getFieldStatus(errors, touched, 'price.totalPrice')}
                  />
                  <Button
                    variant={'blue'}
                    onClick={() =>
                      setFieldValue('price.priceItems', [
                        ...(values.price?.priceItems || []),
                        { displayName: '', price: 0 },
                      ])
                    }
                  >
                    <Add fontSize={'1rem'} />
                    pridať položku
                  </Button>
                </Grid>
              </>
            ) : (
              <Grid textAlign={'center'} fontWeight={'semibold'} gap={'1rem'}>
                <Field
                  bg={colors.blue[100]}
                  name="price.displayName"
                  label={'názov'}
                  component={Input}
                  status={getFieldStatus(errors, touched, 'displayName')}
                />
                <Field
                  bg={colors.blue[100]}
                  name="price.totalPrice"
                  label={'cena'}
                  type={'number'}
                  component={Input}
                  status={getFieldStatus(errors, touched, 'price.totalPrice')}
                  onChange={(e: any) => {
                    setFieldValue(
                      'price.totalPrice',
                      Number(Number(e.target.value).toFixed(2)) || undefined,
                    )
                  }}
                />
              </Grid>
            )}
            <Field
              name="price.description"
              label={'poznámky'}
              helperText={'Obsah sa zobrazí majiteľovi vozidla pri zdieľaní'}
              component={Textarea}
            />
            <Flex
              alignItems={'center'}
              justifyContent={'space-between'}
              fontSize={'0.8rem'}
              w={'100%'}
              fontWeight={'semibold'}
            >
              rozpoložkovať cenu{' '}
              <Field
                name="priceItemsChecked"
                isChecked={values.priceItemsChecked}
                component={Switch}
                onChange={(e: any) => {
                  setFieldValue('priceItemsChecked', e.target.checked)
                  if (e.target.checked === true) {
                    setFieldValue('price.displayName', null)
                  }
                  if (e.target.checked === false) {
                    setFieldValue('price.displayName', '')
                  }
                }}
              />
            </Flex>
          </Grid>
          <BottomSideMenu
            blueClickCancel={() => {
              setFieldValue('price', {
                totalPrice: 0,
                priceItems: [],
                displayName: null,
                description: null,
              })
              onPriceModalClose()
            }}
            greenClickConfirm={() => {
              if (values.priceItemsChecked === true && values.price.priceItems.length === 0) {
                showCustomToast({
                  status: 'error',
                  title: 'Cena neobsahuje položky',
                  label: (
                    <div>
                      Rozpoložkovaná cena musí obsahovať aspoň jednu položku. Ak nechcete, aby bola
                      cena rozpoložkovaná, odštránte tento výber vo formulári.
                    </div>
                  ),
                })
              } else if (
                values.priceItemsChecked === false &&
                values.price.totalPrice &&
                !values.price.displayName
              ) {
                showCustomToast({
                  status: 'error',
                  title: 'Cena neobsahuje názov',
                  label: (
                    <div>
                      Položka <b>Názov</b> vo formulári ceny nesmie byť prázdna.
                    </div>
                  ),
                })
              } else if (
                values.priceItemsChecked === true &&
                values.price.priceItems.length > 0 &&
                values.price.priceItems.some(
                  (item: any) => item.price === undefined || item.displayName === '',
                )
              ) {
                showCustomToast({
                  status: 'error',
                  title: 'Položky ceny sú neúplné',
                  label: <div>Názov aj cena každej položky musia byť vyplnené.</div>,
                })
              } else if (!values.price?.totalPrice) {
                setFieldValue('price', {
                  totalPrice: 0,
                  priceItems: [],
                  displayName: null,
                  description: null,
                })
                onPriceModalClose()
              } else {
                onPriceModalClose()
              }
            }}
          />
          <Menu back={() => navigate(-1)} type={'basic'} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PriceModalMobile
