import { Box } from './Box'
import { colors } from '../theme'
import { Flex, useDisclosure } from '@chakra-ui/react'
import IconButton from './IconButton'
import Edit from '../icons/Edit'
import Settings from '../icons/Settings'
import Delete from '../icons/Delete'
import useGetCar from '../model/api/useGetCar'
import { useDeleteService } from '../model/api/useDeleteService'
import Calendar from '../icons/Calendar'
import ShareIcon from '../icons/ShareIcon'
import { useNavigate } from 'react-router-dom'
import RouterEnum from '../model/enums/RouterEnum'
import { format } from 'date-fns'
import ShareModal from './ShareModal'
import DeleteModal from './DeleteModal'
import Spinner from './Spinner'
import { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import ApiRouteEnum from '../model/enums/ApiRouteEnum'
import { useCustomToast } from './Toast'
import useIsMobile from '../model/helpers/useIsMobile'

const ServiceCard = ({ item }: { item: any }) => {
  const mutateDelete = useDeleteService()
  const isMobile = useIsMobile()
  const query = useQueryClient()
  const { showCustomToast } = useCustomToast()
  const { data: carData, isLoading } = useGetCar(item?.vehicle?.id)
  const navigate = useNavigate()

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()

  const {
    isOpen: isShareModalOpen,
    onOpen: onShareModalOpen,
    onClose: onShareModalClose,
  } = useDisclosure()

  const content = useMemo(() => {
    if (isLoading || carData === null) {
      return <Spinner />
    }

    return (
      <>
        <Flex alignItems={'center'}>
          <Box w={'3rem'} h={'3rem'} bg={colors.gray[700]} display={'grid'} placeItems={'center'}>
            <Settings color={colors.blue[500]} />
          </Box>
          <Box fontWeight={'semibold'} w={'80%'} textAlign={'center'}>
            {item.displayName ? item.displayName : item.brand + ' ' + item.model}
          </Box>
        </Flex>
        <br />
        <Flex
          pl={'1rem'}
          flexFlow={'column'}
          gap={'0.8rem'}
          cursor={'pointer'}
          onClick={() =>
            navigate(RouterEnum.VEHICLE_SERVIS_DETAIL, {
              state: { item: item, car: carData },
            })
          }
        >
          <Box>
            <Box fontWeight={'semibold'} mb={'5px'}>
              Odhadovaný dátum dokončenia:
            </Box>
            <Box fontWeight={'semibold'} m={2}>
              <Calendar fontSize={'1rem'} color={colors.blue[500]} mr={2} />
              {item.estimatedReturnedAt
                ? format(new Date(item.estimatedReturnedAt), 'dd.MM.yyyy')
                : '-'}
            </Box>
          </Box>
          <Box>
            <Box fontWeight={'semibold'} mb={'5px'}>
              VIN:
            </Box>
            <Box>{carData && carData.vinCode}</Box>
          </Box>
          <Box>
            <Box fontWeight={'semibold'} mb={'5px'}>
              SPZ:
            </Box>
            <Box>{carData && carData.licensePlate ? carData.licensePlate : '-'}</Box>
          </Box>
          <Box>
            <Box fontWeight={'semibold'} mb={'5px'}>
              Majiteľ:
            </Box>
            <Box>
              {carData && carData.vehicleOwner && carData.vehicleOwner.firstname}{' '}
              {carData && carData.vehicleOwner && carData.vehicleOwner.lastname}
            </Box>
          </Box>
        </Flex>
        <Flex w={'100%'} justifyContent={'space-between'} mt={'0.8rem'} gap={'0.5rem'} p={'0 1rem'}>
          <IconButton
            variant={'blue'}
            icon={<Edit />}
            size={'third'}
            onClick={() =>
              navigate(RouterEnum.VEHICLE_SERVIS_EDIT, {
                state: {
                  item: item,
                  car: { label: carData.displayName, value: carData.id },
                  mode: 'edit',
                },
              })
            }
          />
          <IconButton
            variant={'dark'}
            icon={<ShareIcon />}
            size={'third'}
            onClick={
              !isMobile
                ? () => onShareModalOpen()
                : () =>
                    navigate(RouterEnum.SHARE_MENU, {
                      state: {
                        vehicleServiceId: item.id,
                        priceSharing: item.vehicleServiceSharingSettings,
                      },
                    })
            }
          />
          <IconButton
            variant={'red'}
            icon={<Delete />}
            size={'third'}
            onClick={() => onDeleteModalOpen()}
          />
        </Flex>
      </>
    )
  }, [carData, isLoading, item, navigate, onDeleteModalOpen, onShareModalOpen])

  return (
    <Box w={{ sm: '100%', md: '30%' }}>
      <Box w={'100%'} pb={5} bg={colors.blue[100]}>
        {content}

        <DeleteModal
          titleValue={item.displayName}
          isDeleteModalOpen={isDeleteModalOpen}
          onDeleteModalClose={onDeleteModalClose}
          onClickAction={() => {
            mutateDelete.mutate(item.id, {
              onSuccess: () => {
                query.invalidateQueries(ApiRouteEnum.VEHICLE_SERVIS)
                onDeleteModalClose()
                showCustomToast({
                  status: 'success',
                  title: 'Položka úspešne odstránená',
                  label: (
                    <div>
                      Servis <b>{item.displayName}</b> bol úspešne odstránený z evidencie
                    </div>
                  ),
                })
              },
              onError: () => {
                onDeleteModalClose()
                showCustomToast({
                  status: 'error',
                  title: 'Položku sa nepodarilo odstrániť',
                  label: (
                    <div>
                      Servis <b>{item.displayName}</b> sa nepodarilo odstrániť
                    </div>
                  ),
                })
              },
            })
          }}
        />

        <ShareModal
          isShareModalOpen={isShareModalOpen}
          onShareModalClose={onShareModalClose}
          vehicleServiceId={item.id}
          priceSharing={item.vehicleServiceSharingSettings}
        />
      </Box>
    </Box>
  )
}

export default ServiceCard
