import { Flex, Progress, ProgressLabel } from '@chakra-ui/react'
import React from 'react'
import { Box } from './Box'
import { colors } from '../theme'

const WorkspaceCard = ({
  icon,
  current,
  maximum,
  label,
}: {
  icon: any
  current: number
  maximum: number
  label: string
}) => {
  const progressColor = (() => {
    if ((current / maximum) * 100 < 50) {
      return 'green'
    }
    if ((current / maximum) * 100 < 85) {
      return 'orange'
    }
    return 'red'
  })()
  return (
    <Flex flexFlow={'column'} gap={'1rem'} alignItems={'center'} bg={colors.blue[100]} p={'1rem'}>
      {icon}
      <Box fontWeight={'semibold'} fontSize={{ sm: '0.8rem', md: '1rem' }}>
        {`${current} / ${maximum}`}
      </Box>
      <Progress
        color={'black'}
        w={'100%'}
        h={'1.4rem'}
        value={(current / maximum) * 100}
        variant={progressColor}
      >
        <ProgressLabel fontSize={'0.8rem'}>{`${parseFloat(
          ((current / maximum) * 100).toFixed(2),
        )}%`}</ProgressLabel>
      </Progress>
      <Box fontWeight={'semibold'} fontSize={'0.8rem'}>
        {label}
      </Box>
    </Flex>
  )
}

export default WorkspaceCard
