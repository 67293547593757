import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { IS_REQUIRED } from '../../model/constants/ValidationMessages'
import { Input } from '../../components/Input'
import getFieldStatus from '../../model/helpers/StatusHelper'
import Button from '../../components/Button'
import { useAddUser } from '../../model/api/useAddUser'
import { colors } from '../../theme'
import { Flex, Grid } from '@chakra-ui/react'
import { Box } from '../../components/Box'
import EmailIcon from '../../icons/EmailIcon'
import Cancel from '../../icons/Cancel'
import Save from '../../icons/Save'
import useUpdateUser from '../../model/api/useUpdateUser'
import { useCustomToast } from '../../components/Toast'
import { InviteEmployeeFormLabels } from '../../model/constants/FormLabels'
import { useQueryClient } from 'react-query'
import ApiRouteEnum from '../../model/enums/ApiRouteEnum'
import { useRegisterEmailUnique } from '../../model/api/useRegisterEmailUnique'
import { InviteEmployeeSchema } from '../../model/schemas/InviteEmployeeSchema'
import { ToastHelper } from '../../model/helpers/ToastHelper'

const InviteEmployeeForm = ({
  closeModal,
  employee,
}: {
  closeModal: () => void
  employee?: any
}) => {
  const mutateEmailUnique = useRegisterEmailUnique(
    () => {},
    () => {},
  )
  const successCallbackUpdate = () => {
    query.invalidateQueries(ApiRouteEnum.USER)
    query.invalidateQueries('workspaceByQuotas')
    showCustomToast({
      status: 'success',
      title: 'Údaje úspešne zmenené',
      label: (
        <div>
          Údaje zamestnanca {employee.firstname + ' ' + employee.lastname} boli úspešne zmenené
        </div>
      ),
    })
  }
  const errorCallbackUpdate = () => {
    showCustomToast({
      status: 'error',
      title: 'Údaje sa nepodarilo zmeniť',
      label: (
        <div>
          Pri zmene údajov zamestnanca {employee.firstname + ' ' + employee.lastname} nastala chyba
        </div>
      ),
    })
  }
  const successCallbackCreate = () => {
    query.invalidateQueries(ApiRouteEnum.USER)
    query.invalidateQueries('workspaceByQuotas')
    showCustomToast({
      status: 'success',
      title: 'Zamestnanec bol pridaný',
      label: <div>Zamestnanec bol úspešne pridaný</div>,
    })
  }
  const errorCallbackCreate = () => {
    showCustomToast({
      status: 'error',
      title: 'Zamestnanca sa nepodarilo pridať',
      label: <div>Pri pridávaní zamestnanca nastala chyba</div>,
    })
  }

  const createUser = useAddUser(successCallbackCreate, errorCallbackCreate)
  const updateUser = useUpdateUser(successCallbackUpdate, errorCallbackUpdate)
  const query = useQueryClient()
  const { showCustomToast } = useCustomToast()
  const handleFormSubmit = (values: any) => {
    mutateEmailUnique.mutate(values.email, {
      onSuccess: () => {
        const isEmailAvailable = localStorage.getItem('emailAvailable')
        if (isEmailAvailable === 'true') {
          if (employee) {
            updateUser.mutate({ updateUserData: values, id: employee.id })
          } else {
            createUser.mutate(values)
          }

          closeModal()
        } else {
          showCustomToast({
            status: 'error',
            title: 'Email už je zabraný',
            label: 'Email už je zabraný. Zvoľte prosím iný email.',
          })
        }
      },
    })
  }

  const initialValues = {
    firstname: employee?.firstname || '',
    lastname: employee?.lastname || '',
    email: employee?.email || '',
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={InviteEmployeeSchema}
      onSubmit={handleFormSubmit}
    >
      {({ errors, touched, submitForm, setFieldTouched }) => {
        return (
          <Form>
            <Grid gap={'1rem'} mt={'1rem'}>
              <Field
                bg={colors.blue[100]}
                name="firstname"
                label={'krstné meno'}
                component={Input}
                status={getFieldStatus(errors, touched, 'firstname')}
              />
              <Field
                bg={colors.blue[100]}
                name="lastname"
                label={'priezvisko'}
                component={Input}
                status={getFieldStatus(errors, touched, 'lastname')}
              />
              <Field
                bg={colors.blue[100]}
                name="email"
                label={'e-mail'}
                component={Input}
                status={getFieldStatus(errors, touched, 'email')}
              />
              {!employee ? (
                <>
                  <Box m={'1rem 0'}>Pozvánka na registráciu príde na mail</Box>
                  <Flex gap={'1rem'}>
                    <Button
                      onClick={() =>
                        ToastHelper({
                          submitForm,
                          errors,
                          setFieldTouched,
                          labels: InviteEmployeeFormLabels,
                          showCustomToast,
                        })
                      }
                      variant={'green'}
                      size={'full'}
                    >
                      <EmailIcon />
                    </Button>
                    <Button variant={'blue'} size={'full'} onClick={() => closeModal()}>
                      <Cancel />
                    </Button>
                  </Flex>
                </>
              ) : (
                <Flex gap={'1rem'}>
                  <Button variant={'blue'} size={'full'} onClick={() => closeModal()}>
                    <Cancel />
                  </Button>
                  <Button
                    onClick={() =>
                      ToastHelper({
                        submitForm,
                        errors,
                        setFieldTouched,
                        labels: InviteEmployeeFormLabels,
                        showCustomToast,
                      })
                    }
                    variant={'green'}
                    size={'full'}
                  >
                    <Save />
                  </Button>
                </Flex>
              )}
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default InviteEmployeeForm
