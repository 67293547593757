import { TagCloseButton, TagLabel, Tag, TagLeftIcon } from '@chakra-ui/react'
import { Add } from '../icons/Add'

const FilterTag = ({
  title,
  type = 'default',
  onClick,
}: {
  title: string
  type?: string
  onClick?: any
}) => {
  const isMobile = window.innerWidth < 768
  if (type === 'add') {
    return (
      <Tag
        size={'sm'}
        borderRadius="full"
        variant="add"
        p={'0.5rem'}
        ml={{md:"10px"}}
        cursor={'pointer'}
        fontWeight={'semibold'}
        onClick={() => onClick()}
      >
        <TagLeftIcon boxSize="1rem" as={Add} />
        <TagLabel>{title}</TagLabel>
      </Tag>
    )
  }
  return (
    <Tag
      size={'sm'}
      borderRadius="full"
      variant="dark"
      fontWeight={'semibold'}
      ml={!isMobile ? '10px' : 'initial'}
      mr={!isMobile ? '10px' : 'initial'}
      p={'0.5rem'}
    >
      <TagLabel>{title}</TagLabel>
      <TagCloseButton fontSize={isMobile ? '0.7rem' : '16px'} onClick={onClick} />
    </Tag>
  )
}

export default FilterTag
