import { Flex } from '@chakra-ui/layout'
import { useLocation, useNavigate } from 'react-router'
import { colors } from '../theme'
import Button from './Button'
import Settings from '../icons/Settings'
import MapIcon from '../icons/MapIcon'
import CarIcon from '../icons/CarIcon'
import PeopleIcon from '../icons/PeopleIcon'
import PersonIcon from '../icons/PersonIcon'
import { Box } from './Box'
import { ReactComponent as LogoVivem } from '../assets/logoVivemInverted.svg'
import RouterEnum from '../model/enums/RouterEnum'
import useIsMobile from '../model/helpers/useIsMobile'
import Menu from './Menu'
import { useEffect } from 'react'

const MainMenu = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useIsMobile()

  useEffect(() => {
    if (!isMobile && location.pathname === RouterEnum.MENU) {
      navigate(RouterEnum.HOMEPAGE)
    }
  }, [isMobile])
  return (
    <Flex
      w={{ sm: '100%', md: '20%' }}
      h={{ sm: '90%', md: '100%' }}
      bg={colors.gray[700]}
      flexFlow={'column nowrap'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={{ sm: '1.5rem', md: '0.8rem' }}
    >
      {!isMobile && <LogoVivem width={'6rem'} style={{ marginBottom: '1rem' }} />}
      <Button
        h={{ sm: '6rem', md: 'initial' }}
        variant={location.pathname.includes(RouterEnum.VEHICLE_SERVIS_OVERVIEW) ? 'blue' : 'basic'}
        size="medium"
        onClick={() => navigate(RouterEnum.HOMEPAGE + RouterEnum.VEHICLE_SERVIS_OVERVIEW)}
      >
        <Settings />
        <Box w={'70%'} whiteSpace={'pre-wrap'}>
          Prehľad aktívnych servisov
        </Box>
      </Button>
      <Button
        h={{ sm: '6rem', md: 'initial' }}
        variant={location.pathname.includes(RouterEnum.VEHICLE_MANAGEMENT) ? 'blue' : 'basic'}
        size="medium"
        onClick={() => navigate(RouterEnum.HOMEPAGE + RouterEnum.VEHICLE_MANAGEMENT)}
      >
        <CarIcon />
        <Box w={'70%'} whiteSpace={'pre-wrap'}>
          Evidencia a spracovanie vozidiel
        </Box>
      </Button>
      <Button
        h={{ sm: '6rem', md: 'initial' }}
        variant={location.pathname.includes(RouterEnum.CUSTOMER_MANAGEMENT) ? 'blue' : 'basic'}
        size="medium"
        onClick={() => navigate(RouterEnum.HOMEPAGE + RouterEnum.CUSTOMER_MANAGEMENT)}
      >
        <PeopleIcon />
        <Box w={'70%'} whiteSpace={'pre-wrap'}>
          Evidencia a spracovanie zákazníkov
        </Box>
      </Button>
      <Button
        h={{ sm: '6rem', md: 'initial' }}
        variant={location.pathname.includes(RouterEnum.ACCOUNT_MANAGEMENT) ? 'blue' : 'basic'}
        size="medium"
        onClick={() => navigate(RouterEnum.HOMEPAGE + RouterEnum.ACCOUNT_MANAGEMENT)}
      >
        <PersonIcon />
        <Box w={'70%'} whiteSpace={'pre-wrap'}>
          Nastavenia účtu
        </Box>
      </Button>
      <Button
        h={{ sm: '6rem', md: 'initial' }}
        variant={location.pathname.includes(RouterEnum.WORKSPACE_MANAGEMENT) ? 'blue' : 'basic'}
        size="medium"
        mb={'2vw'}
        onClick={() => navigate(RouterEnum.HOMEPAGE + RouterEnum.WORKSPACE_MANAGEMENT)}
      >
        <MapIcon />
        <Box w={'70%'} whiteSpace={'pre-wrap'}>
          Nastavenia pracoviska
        </Box>
      </Button>
      {isMobile && <Menu h={"10%"} back={undefined} type={'basic'} />}
    </Flex>
  )
}

export default MainMenu
