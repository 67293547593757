import { Flex, Grid, filter } from '@chakra-ui/react'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { Input } from '../../components/Input'
import { colors } from '../../theme'
import { Select } from '../../components/Select'
import IconButton from '../../components/IconButton'
import { Search } from '../../icons/Search'
import CancelFilter from '../../icons/CancelFilter'
import useGetAllVehicleOwners from '../../model/api/useGetAllVehicleOwners'
import { useEffect, useState } from 'react'
import removeEmpty from '../../model/helpers/RemoveEmptyKeys'
import { FilterFormSchema } from '../../model/schemas/FilterFormSchema'

const FilterForm = ({
  closeModal,
  setFilters,
  filters,
  onResetFilters,
}: {
  closeModal: any
  setFilters: any
  filters: any
  onResetFilters: () => void
}) => {
  const handleFormSubmit = (values: Yup.InferType<typeof FilterFormSchema>) => {
    const cleanObj = removeEmpty(values)

    const updatedFilters = Object.keys(cleanObj).reduce((prev, key) => {
      return { ...prev, [key]: cleanObj[key] }
    }, filters)

    setFilters(updatedFilters)
    closeModal()
  }
  const [optionsOwners, setOptionsOwners] = useState([])
  const { data: usersData } = useGetAllVehicleOwners()
  const initialValues = {
    ownerId: filters.ownerId || '',
    model: filters.model || '',
    licensePlate: filters.licensePlate || '',
    ownerEmail: filters.ownerEmail || '',
    brand: filters.brand || '',
    vinCode: filters.vinCode || '',
  }

  useEffect(() => {
    const options: any = []
    usersData &&
      usersData.map((item: any) => {
        options.push({ label: `${item.firstname} ${item.lastname}`, value: item.id })
        return null
      })
    setOptionsOwners(options)
  }, [usersData])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={FilterFormSchema}
    >
      {({ setFieldValue, values }) => {
        return (
          <Form>
            <Grid
              w={'100%'}
              templateColumns={{ sm: undefined, md: 'repeat(2, 1fr)' }}
              templateRows={{ sm: undefined, md: 'repeat(3, 4rem)' }}
              autoFlow={{ sm: undefined, md: 'column' }}
              columnGap={'2rem'}
              rowGap={{ sm: '1rem', md: undefined }}
              alignItems={'end'}
              m={'1rem 0'}
            >
              <Field
                bg={colors.blue[100]}
                name={'ownerId'}
                label={'Majiteľ'}
                options={optionsOwners}
                component={Select}
                setFieldValue={setFieldValue}
                setFieldTouched={() => {}}
              />
              <Field
                bg={colors.blue[100]}
                name={'model'}
                label={'model'}
                type="text"
                component={Input}
                status={'default'}
              />
              <Field
                bg={colors.blue[100]}
                name={'licensePlate'}
                label={'ŠPZ vozidla'}
                type="text"
                component={Input}
                status={'default'}
              />
              <Field
                bg={colors.blue[100]}
                name={'ownerEmail'}
                label={'e-mail majiteľa'}
                type="text"
                component={Input}
                status={'default'}
              />
              <Field
                bg={colors.blue[100]}
                name={'brand'}
                label={'značka'}
                type="text"
                component={Input}
                status={'default'}
              />
              <Field
                bg={colors.blue[100]}
                name={'vinCode'}
                label={'vinCode vozidla'}
                type="text"
                component={Input}
                status={'default'}
              />
            </Grid>
            <Flex justifyContent={'space-evenly'}>
              <IconButton size={'half'} variant={'blue'} icon={<Search />} type={'submit'} />
              <IconButton
                size={'half'}
                variant={'red'}
                icon={<CancelFilter />}
                onClick={() => {
                  closeModal()
                  onResetFilters()
                }}
              />
            </Flex>
          </Form>
        )
      }}
    </Formik>
  )
}

export default FilterForm
