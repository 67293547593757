import { useMutation } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'
import { useCustomToast } from '../../components/Toast'

export const useRequestCode = () => {
  const { showCustomToast } = useCustomToast()
  return useMutation(
    (vehicleServiceId: string) =>
      api.post(`${ApiRouteEnum.CODE}`, { vehicleServiceId: vehicleServiceId }),
    {
      mutationKey: [ApiRouteEnum.CODE],
      onSuccess: (data) => {
        navigator.clipboard.writeText(data.data.url)
        showCustomToast({
          status: 'success',
          title: 'Odkaz bol skopírovaný do schránky',
          label: <div>Odkaz bol úspešne skopírovaný do schránky.</div>,
        })
        return
      },
    },
  )
}
