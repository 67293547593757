import { Divider, Flex, Grid, useDisclosure } from '@chakra-ui/react'
import DownChevronIcon from '../icons/DownChevronIcon'
import { colors } from '../theme'
import Button from './Button'
import { Add } from '../icons/Add'
import React, { useState } from 'react'
import { Field } from 'formik'
import { Input } from './Input'
import Camera from '../icons/Camera'
import ImagePlaceholder from './ImagePlaceholder'
import { useCustomToast } from './Toast'
import Delete from '../icons/Delete'

const PhotoDropdown = ({
  photoIds,
  mode,
  setFieldValue,
  type,
  values,
}: {
  photoIds?: any
  mode: string
  setFieldValue?: any
  type: string
  values?: any
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [count, setCount] = useState<any[]>([])
  const { showCustomToast } = useCustomToast()
  const handleOpenAndClose = () => {
    if (isOpen) {
      onClose()
    }
    if (!isOpen) {
      onOpen()
    }
  }

  const handleFileUpload = (e: any, name: string, identifier?: string) => {
    const file = e.target.files[0]
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        showCustomToast({
          status: 'error',
          title: 'Nahrávanie zlyhalo',
          label: <div>Fotografia musí byť menšia ako 5MB</div>,
        })
        return
      }
      const reader = new FileReader()

      reader.onload = () => {
        const binaryData = reader.result as ArrayBuffer
        const base64Data = btoa(
          new Uint8Array(binaryData).reduce((data, byte) => data + String.fromCharCode(byte), ''),
        )
        if (identifier) {
          setFieldValue(name, { data: { mimeType: file.type, data: base64Data }, id: identifier })
        } else {
          setFieldValue(name, { mimeType: file.type, data: base64Data })
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }

  return (
    <>
      <Flex
        w={'100%'}
        h={'3rem'}
        bg={colors.gray[700]}
        color={colors.blue[100]}
        justifyContent={'space-between'}
        onClick={handleOpenAndClose}
        cursor={'pointer'}
      >
        <Grid
          placeItems={'center'}
          w={'80%'}
          fontSize={'0.8rem'}
          fontWeight={'semibold'}
          textAlign={'center'}
        >
          {type === 'Service'
            ? 'Fotografie pri odovzdaní vozidla autoservisu'
            : 'Fotografie pri odovzdaní vozidla späť zákazníkovi'}
        </Grid>
        <Grid placeItems={'center'} w={'20%'}>
          <DownChevronIcon fontSize={'1rem'} transform={isOpen && 'rotate(180deg)'} />
        </Grid>
      </Flex>
      {isOpen && (
        <Flex flexFlow={'column'} gap={'0.8rem'}>
          {mode === 'edit' &&
            photoIds &&
            photoIds.map((item: any, index: number) => {
              return (
                <React.Fragment key={'fragmentImage' + index}>
                  <ImagePlaceholder image={item.id} />
                  <Button
                    variant={'red'}
                    onClick={() => {
                      const newPhotoIds = photoIds.filter((photo: any) => photo.id !== item.id)
                      if (type === 'Service') {
                        setFieldValue(`photosAtReceiveIds`, newPhotoIds)
                      }
                      if (type === 'Customer') {
                        setFieldValue(`photosAtReturnIds`, newPhotoIds)
                      }
                    }}
                  >
                    <Delete />
                  </Button>
                  <Divider
                    borderWidth={'0.2rem 0'}
                    borderColor={colors.gray[700]}
                    bg={colors.gray[700]}
                  />
                </React.Fragment>
              )
            })}

          {count.map((item: any, index) => {
            return (
              <>
                <Field
                  bg={colors.blue[100]}
                  name={`image${type}[${index}]`}
                  component={Input}
                  type={'file'}
                  onChange={(e: any) => handleFileUpload(e, `imageObject${type}[${index}]`)}
                  hide={true}
                />
                <label htmlFor={`image${type}[${index}]`}>
                  <ImagePlaceholder
                    src={
                      values[`imageObject${type}`]?.[`${index}`]
                        ? `data:${values[`imageObject${type}`][`${index}`].mimeType};base64,${
                            values[`imageObject${type}`][`${index}`].data
                          }`
                        : undefined
                    }
                  />
                </label>
                <Button
                  variant={'red'}
                  onClick={() => {
                    setCount((prev) => prev.filter((item, i) => i !== index))
                    const updatedImageObject = [...values[`imageObject${type}`]]
                    updatedImageObject.splice(index, 1)
                    setFieldValue(`imageObject${type}`, updatedImageObject)
                  }}
                >
                  <Delete />
                </Button>
              </>
            )
          })}

          {mode === 'viewOnly' &&
            photoIds &&
            photoIds.map((item: any, index: number) => {
              return (
                <>
                  <ImagePlaceholder image={item.id} />

                  {photoIds.length - 1 !== index && (
                    <Divider
                      borderWidth={'0.2rem 0'}
                      borderColor={colors.gray[700]}
                      bg={colors.gray[700]}
                    />
                  )}
                </>
              )
            })}

          {mode !== 'viewOnly' && (
            <Button
              size={'large'}
              variant={'blue'}
              w={'100%'}
              h={'5rem'}
              p={'unset'}
              onClick={() => setCount((prev) => [...prev, 'new'])}
            >
              <Flex w={'8rem'} alignItems={'center'} justifyContent={'space-around'}>
                <Add fontSize={'2rem'} />
                <Camera fontSize={'2rem'} />
              </Flex>
              pridať fotografiu
            </Button>
          )}
        </Flex>
      )}
    </>
  )
}

export default PhotoDropdown
