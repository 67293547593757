import { Box } from './Box'
import { Flex } from '@chakra-ui/react'
import { ArrowBack, ArrowForward } from '../icons/Arrows'
import { Add } from '../icons/Add'
import { Search } from '../icons/Search'
import { Hamburger } from '../icons/Hamburger'
import { colors } from '../theme'
import { useNavigate } from 'react-router-dom'
import RouterEnum from '../model/enums/RouterEnum'

const Menu = (props: any) => {
  const { type = 'basic', h = '5rem' } = props
  const navigate = useNavigate()
  if (type === 'arrowsOnly') {
    return (
      <Box
        h={h}
        w={'100%'}
        bg={colors.gray[700]}
        color="white"
        position={'fixed'}
        bottom={0}
        left={0}
        zIndex={100}
      >
        <Flex h={'100%'} justifyContent={'space-between'} alignItems={'center'}>
          <ArrowBack fontSize={'xx-large'} ml={'15%'} onClick={() => props.back()} />
          <ArrowForward fontSize={'xx-large'} mr={'15%'} onClick={() => props.next()} />
        </Flex>
      </Box>
    )
  }
  if (type === 'full') {
    return (
      <Box
        h={h}
        w={'100%'}
        bg={colors.gray[700]}
        color="white"
        position={'fixed'}
        bottom={0}
        left={0}
        zIndex={100}
      >
        <Flex h={'100%'} p={5} justifyContent={'space-between'} alignItems={'center'}>
          <ArrowBack onClick={() => props.back()} />
          <Search onClick={() => props.search()} />
          <Hamburger onClick={() => navigate(RouterEnum.MENU)} />
          <Add onClick={() => navigate(RouterEnum.ADD_MENU)} />
        </Flex>
      </Box>
    )
  }
  return (
    <Box
      h={h}
      w={'100%'}
      bg={colors.gray[700]}
      color="white"
      position={'fixed'}
      bottom={0}
      left={0}
      zIndex={100}
    >
      <Flex h={'100%'} p={5} justifyContent={'space-between'} alignItems={'center'}>
        {props?.back !== undefined ? <ArrowBack onClick={props?.back ? () => props.back() : () => navigate(-1)} /> : <Box w={"2rem"}></Box>}
        <Hamburger onClick={() => navigate(RouterEnum.MENU)} />
        <Add onClick={() => navigate(RouterEnum.ADD_MENU)} />
      </Flex>
    </Box>
  )
}

export default Menu
