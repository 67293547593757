import { Grid } from "@chakra-ui/react"
import {Spinner as ChakraSpinner} from '@chakra-ui/react'
import { colors } from "../theme"


const Spinner = (props: any) => {
  return (
    <Grid placeItems={'center'} w={"100%"} h={"100%"} bg={colors.blue[50]}>
        <ChakraSpinner thickness={props.thickness || "0.5rem"} speed={"1s"} emptyColor={colors.gray[100]} color={colors.blue[500]} h={props.h || '5rem'} w={props.w || '5rem'} />
    </Grid>
  )
}

export default Spinner