import { Button, Grid, useDisclosure } from '@chakra-ui/react'
import { Field, Form, Formik, validateYupSchema } from 'formik'
import * as Yup from 'yup'
import { IS_REQUIRED } from '../../model/constants/ValidationMessages'
import { Input } from '../../components/Input'
import getFieldStatus from '../../model/helpers/StatusHelper'
import { useState } from 'react'
import Textarea from '../../components/Textarea'
import { Add } from '../../icons/Add'
import Cancel from '../../icons/Cancel'
import Save from '../../icons/Save'
import { useAddVehicleOwner } from '../../model/api/useAddVehicleOwner'
import useUpdateVehicleOwner from '../../model/api/useUpdateVehicleOwner'
import useUpdateVehicleOwnerNotes from '../../model/api/useUpdateVehicleOwnerNotes'
import useAddVehicleOwnerNotes from '../../model/api/useAddVehicleOwnerNotes'
import { Box } from '../../components/Box'
import DeleteModal from '../../components/DeleteModal'
import { useDeleteVehicleOwner } from '../../model/api/useDeleteVehicleOwner'
import Delete from '../../icons/Delete'
import ConfirmLeaveModal from '../../components/ConfirmLeaveModal'
import ApiRouteEnum from '../../model/enums/ApiRouteEnum'
import { useQueryClient } from 'react-query'
import { useCustomToast } from '../../components/Toast'
import { CustomerManagementFormLabels } from '../../model/constants/FormLabels'
import useIsMobile from '../../model/helpers/useIsMobile'
import { useDeleteVehicleOwnerNote } from '../../model/api/useDeleteVehicleOwnerNote'
import { customerFormSchema } from '../../model/schemas/CustomerFormSchema'
import { ToastHelper } from '../../model/helpers/ToastHelper'
import { unstable_usePrompt } from 'react-router-dom'

const CustomerForm = ({
  customer,
  onCreateCustomerModalClose,
  setIsOpenByDefault,
}: {
  customer?: any
  onCreateCustomerModalClose: () => void
  setIsOpenByDefault?: any
}) => {
  const [deletedNotes, setDeletedNotes] = useState<any[]>([])
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()
  const {
    isOpen: isConfirmLeaveModalOpen,
    onOpen: onConfirmLeaveModalOpen,
    onClose: onConfirmLeaveModalClose,
  } = useDisclosure()
  const mutateDelete = useDeleteVehicleOwner()

  const initialValues = {
    firstname: customer?.firstname || '',
    lastname: customer?.lastname || '',
    email: customer?.contactEmail || null,
    phone: customer?.phoneNumber?.replace(/\s/g, '') || null,
    birthdate: customer?.birthdate || null,
    notes: customer?.notes || [],
  }

  const successCallback = () => {
    query.invalidateQueries(ApiRouteEnum.VEHICLE_OWNER)
    onCreateCustomerModalClose()
    showCustomToast({
      status: 'success',
      title: 'Položka bola úspešne vytvorená',
      label: <div>Záklazník bol úspešne vytvorený</div>,
    })
  }

  const errorCallback = () => {
    onCreateCustomerModalClose()
    showCustomToast({
      status: 'error',
      title: 'Položku sa nepodarilo vytvoriť',
      label: <div>Záklazníka sa nepodarilo vytvoriť</div>,
    })
  }

  const mutateAddCustomer = useAddVehicleOwner(successCallback, errorCallback)
  const mutateUpdateCustomer = useUpdateVehicleOwner(
    () => {
      query.invalidateQueries(ApiRouteEnum.VEHICLE_OWNER_NOTE)
      query.invalidateQueries(ApiRouteEnum.VEHICLE_OWNER)
    },
    () => {
      showCustomToast({
        status: 'error',
        title: 'Vytvorenie poznámky zlyhalo',
        label: <div>Poznámku sa nepodarilo vytvoriť</div>,
      })
    },
  )
  const mutateCustomerNotes = useUpdateVehicleOwnerNotes()
  const mutateCreateCustomerNotes = useAddVehicleOwnerNotes()
  const { mutate: mutateDeleteNote } = useDeleteVehicleOwnerNote()
  const query = useQueryClient()
  const isMobile = useIsMobile()
  const { showCustomToast } = useCustomToast()

  const handleFormSubmit = (values: Yup.InferType<typeof customerFormSchema>) => {
    if (!customer) {
      mutateAddCustomer.mutate({
        ...values,
        email: values.email !== '' ? values.email : null,
        phone: values.phone && values.phone !== '' ? values.phone.replace(/\s/g, '') : null,
      })
    }
    if (customer) {
      const { notes, ...updateVehicleOwnwerData } = values
      notes?.map((note: any) => {
        if (note.id) {
          if (note.note !== '') {
            mutateCustomerNotes.mutate({ noteContent: { content: note.note }, id: note.id })
          } else {
            mutateDeleteNote(note.id)
          }
        } else {
          if (note.note !== '') {
            mutateCreateCustomerNotes.mutate(
              {
                noteData: { content: note.note, vehicleOwnerId: customer.id },
              },
              {
                onSuccess: () => {
                  query.invalidateQueries(ApiRouteEnum.VEHICLE_OWNER_NOTE)
                  query.invalidateQueries(ApiRouteEnum.VEHICLE_OWNER)
                },
                onError: () => {
                  showCustomToast({
                    status: 'error',
                    title: 'Zmena poznámky zlyhala',
                    label: <div>Poznámku sa nepodarilo upraviť</div>,
                  })
                },
              },
            )
          }
        }
        return null
      })
      mutateUpdateCustomer.mutate(
        {
          updateVehicleOwnwerData: {
            ...updateVehicleOwnwerData,
            email: updateVehicleOwnwerData.email !== '' ? updateVehicleOwnwerData.email : null,
            phone:
              updateVehicleOwnwerData.phone && updateVehicleOwnwerData.phone !== ''
                ? updateVehicleOwnwerData.phone.replace(/\s/g, '')
                : null,
          },
          id: customer.id,
        },
        {
          onSuccess: () => {
            query.invalidateQueries(ApiRouteEnum.VEHICLE_OWNER)
            onCreateCustomerModalClose()
            showCustomToast({
              status: 'success',
              title: 'Zmeny boli uložené',
              label: (
                <div>
                  Záklazník <b>{`${values.firstname} ${values.lastname}`}</b> bol úspešne upravený
                </div>
              ),
            })
          },
          onError: () => {
            showCustomToast({
              status: 'error',
              title: 'Položku sa nepodarilo upraviť',
              label: (
                <div>
                  Záklazníka <b>{`${values.firstname} ${values.lastname}`}</b> sa nepodarilo upraviť
                </div>
              ),
            })
          },
        },
      )
    }
    if (deletedNotes.length > 0) {
      deletedNotes.map((item) => {
        mutateDeleteNote(item)
      })
    }
    if (isMobile && setIsOpenByDefault) {
      setIsOpenByDefault(false)
    }
    onCreateCustomerModalClose()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={customerFormSchema}
      onSubmit={handleFormSubmit}
      enableReinitialize={true}
    >
      {({ values, errors, touched, setFieldValue, submitForm, setFieldTouched, dirty }) => {
        unstable_usePrompt({
          when: dirty,
          message: 'Naozaj chcete opustiť stránku? Zmeny nebudú uložené.',
        })
        return (
          <Form style={{ overflow: 'auto', height: isMobile ? '41rem' : 'initial' }}>
            <Grid
              w={{ sm: '90%', md: 'initial' }}
              gridTemplateColumns={{ sm: 'initial', md: 'repeat(2, 1fr)' }}
              gap={'1rem 3rem'}
              m={{ sm: '2rem auto 0 auto', md: 'initial' }}
              h={{ sm: 'initial', md: '100%' }}
            >
              <Field
                name="firstname"
                label="meno"
                component={Input}
                status={getFieldStatus(errors, touched, 'firstname')}
              />
              <Field
                name="lastname"
                label="priezvisko"
                component={Input}
                status={getFieldStatus(errors, touched, 'lastname')}
              />
              <Field
                name="email"
                label="e-mail"
                component={Input}
                status={getFieldStatus(errors, touched, 'email')}
              />
              <Field
                name="birthdate"
                label="dátum narodenia"
                type={'date'}
                component={Input}
                setFieldValue={setFieldValue}
                status={getFieldStatus(errors, touched, 'birthdate')}
              />
              <Field
                name="phone"
                type={'tel'}
                label="telefónne číslo"
                component={Input}
                status={getFieldStatus(errors, touched, 'phone')}
              />
            </Grid>

            {isMobile ? (
              <Grid
                m={'1rem auto 1rem auto'}
                w={{ sm: '90%', md: 'initial' }}
                overflow={'auto'}
                gap={'1rem'}
              >
                {Array.from(Array(values?.notes?.length)).map((note: any, index: number) => {
                  if (customer) {
                    return (
                      <Field
                        name={`notes[${index}].note`}
                        label={`poznámky`}
                        m={'0'}
                        component={Textarea}
                        key={customer.notes[index]?.id || 'edit' + index}
                        onDeleteClick={
                          customer.notes[index]?.id
                            ? () => {
                                setFieldValue(
                                  'notes',
                                  values?.notes?.filter((item: any, i: number) => {
                                    return i !== index
                                  }),
                                )
                                setDeletedNotes([...deletedNotes, customer.notes[index]?.id])
                              }
                            : () => {
                                setFieldValue(
                                  'notes',
                                  values?.notes?.filter((item: any, i: number) => {
                                    return i !== index
                                  }),
                                )
                              }
                        }
                      />
                    )
                  }
                  return (
                    <Field
                      name={`notes[${index}].content`}
                      label={`poznámky`}
                      m={'0'}
                      component={Textarea}
                      key={'create' + index}
                      onDeleteClick={() => {
                        setFieldValue(
                          'notes',
                          values?.notes?.filter((item: any, i: number) => {
                            return i !== index
                          }),
                        )
                      }}
                    />
                  )
                })}
              </Grid>
            ) : (
              <Grid
                maxH={{ sm: '11rem', md: '27rem' }}
                m={'1rem auto 1rem auto'}
                w={{ sm: '90%', md: 'initial' }}
                overflow={'auto'}
                gap={'1rem'}
              >
                {Array.from(Array(values?.notes?.length)).map((note: any, index: number) => {
                  const reversedIndex = (values?.notes || []).length - index - 1
                  if (customer) {
                    return (
                      <Field
                        name={`notes[${reversedIndex}].note`}
                        label={`poznámky`}
                        m={'0'}
                        component={Textarea}
                        key={customer.notes[reversedIndex]?.id || 'edit' + reversedIndex}
                        onDeleteClick={
                          customer.notes[reversedIndex]?.id
                            ? () => {
                                setFieldValue(
                                  'notes',
                                  values?.notes?.filter((item: any, i: number) => {
                                    return i !== reversedIndex
                                  }),
                                )
                                setDeletedNotes([
                                  ...deletedNotes,
                                  customer.notes[reversedIndex]?.id,
                                ])
                              }
                            : () => {
                                setFieldValue(
                                  'notes',
                                  values?.notes?.filter((item: any, i: number) => {
                                    return i !== reversedIndex
                                  }),
                                )
                              }
                        }
                      />
                    )
                  }
                  return (
                    <Field
                      name={`notes[${reversedIndex}].content`}
                      label={`poznámky`}
                      m={'0'}
                      component={Textarea}
                      key={'create' + reversedIndex}
                      onDeleteClick={() => {
                        setFieldValue(
                          'notes',
                          values?.notes?.filter((item: any, i: number) => {
                            return i !== reversedIndex
                          }),
                        )
                      }}
                    />
                  )
                })}
              </Grid>
            )}
            <Grid
              w={{ sm: '90%', md: 'initial' }}
              gridTemplateColumns={{ sm: 'initial', md: 'repeat(2, 1fr)' }}
              gap={'1rem 3rem'}
              m={{ sm: '0 auto 12rem auto', md: '0 auto' }}
            >
              <Button
                variant={'blue'}
                onClick={() => setFieldValue('notes', [...(values.notes || []), { content: '' }])}
              >
                <Add fontSize={'1rem'} />
                pridať poznámku
              </Button>
              {customer ? (
                <Grid gridTemplateColumns={'repeat(3, 1fr)'} gap={'1rem'}>
                  <Button
                    variant={'blue'}
                    onClick={
                      dirty
                        ? onConfirmLeaveModalOpen
                        : () => {
                            if (isMobile && setIsOpenByDefault) {
                              setIsOpenByDefault(false)
                            }
                            onCreateCustomerModalClose()
                          }
                    }
                  >
                    <Cancel />
                  </Button>
                  <Button variant={'green'} type={'submit'}>
                    <Save />
                  </Button>
                  <Button variant={'red'} onClick={() => onDeleteModalOpen()}>
                    <Delete />
                  </Button>
                </Grid>
              ) : (
                <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap={'1rem'}>
                  <Button
                    variant={'green'}
                    onClick={() =>
                      ToastHelper({
                        submitForm,
                        errors,
                        setFieldTouched,
                        labels: CustomerManagementFormLabels,
                        showCustomToast,
                      })
                    }
                  >
                    <Save />
                  </Button>
                  <Button
                    variant={'blue'}
                    onClick={() => {
                      if (isMobile && setIsOpenByDefault) {
                        setIsOpenByDefault(false)
                      }
                      onCreateCustomerModalClose()
                    }}
                  >
                    <Cancel />
                  </Button>
                </Grid>
              )}
            </Grid>
            {customer && (
              <DeleteModal
                isDeleteModalOpen={isDeleteModalOpen}
                onDeleteModalClose={onDeleteModalClose}
                titleValue={customer.firstname + ' ' + customer.lastname}
                onClickAction={() => {
                  mutateDelete.mutate(customer.id, {
                    onSuccess: () => {
                      query.invalidateQueries(ApiRouteEnum.VEHICLE_OWNER)
                      query.invalidateQueries('workspaceByQuotas')
                      showCustomToast({
                        status: 'success',
                        title: 'Položka úspešne odstránená',
                        label: (
                          <div>
                            Zákazník <b>{customer.firstname + ' ' + customer.lastname}</b> bol
                            úspešne odstránený z evidencie
                          </div>
                        ),
                      })
                    },
                    onError: () => {
                      showCustomToast({
                        status: 'error',
                        title: 'Položku sa nepodarilo odstrániť',
                        label: (
                          <div>
                            Zákazníka <b>{customer.firstname + ' ' + customer.lastname}</b> sa
                            nepodarilo odstrániť
                          </div>
                        ),
                      })
                    },
                  })
                }}
              />
            )}
            <ConfirmLeaveModal
              isConfirmLeaveModalOpen={isConfirmLeaveModalOpen}
              onConfirmLeaveModalClose={onConfirmLeaveModalClose}
              onGreenClickAction={() => submitForm()}
              onRedClickAction={() => {
                onConfirmLeaveModalClose()
                onCreateCustomerModalClose()
              }}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default CustomerForm
