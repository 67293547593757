import { Center, Flex, Grid, Image } from '@chakra-ui/react'
import Menu from './Menu'
import { colors } from '../theme'
import Button from './Button'
import RouterEnum from '../model/enums/RouterEnum'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box } from './Box'
import ShareIcon from '../icons/ShareIcon'
import GlobeIcon from '../icons/GlobeIcon'
import QRIcon from '../icons/QRIcon'
import EmailIcon from '../icons/EmailIcon'
import useIsMobile from '../model/helpers/useIsMobile'
import { useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { Input } from './Input'
import getFieldStatus from '../model/helpers/StatusHelper'
import * as Yup from 'yup'
import { IS_REQUIRED } from '../model/constants/ValidationMessages'
import { useSendNotification } from '../model/api/useSendNotification'
import useGetCode from '../model/api/useGetCar copy'
import { useRequestCode } from '../model/api/useRequestCode'
import Switch from './Switch'
import Save from '../icons/Save'
import useUpdateShareSettings from '../model/api/useUpdateShareSettings'
import ApiRouteEnum from '../model/enums/ApiRouteEnum'
import { useQueryClient } from 'react-query'
import { useCustomToast } from './Toast'
import Spinner from './Spinner'
import { EmailFormSchema } from '../model/schemas/EmailFormSchema'

const ShareMenu = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  const { showCustomToast } = useCustomToast()
  const location = useLocation()
  const vehicleServiceId = location?.state?.vehicleServiceId || ''
  const priceSharing = location?.state?.priceSharing || { totalPrice: false, priceItems: false }
  const { mutate: sendEmail } = useSendNotification()
  const { data: codePrefillData } = useGetCode(vehicleServiceId)
  const { mutate: mutateGetCode, data: codeData } = useRequestCode()
  const isMobile = useIsMobile()
  const [shareOption, setShareOption] = useState<string>('')
  const [content, setContent] = useState<any>()

  const handleEmailSend = (values: any) => {
    sendEmail({ notificationData: values, vehicleServiceId: vehicleServiceId })
  }

  const mutateShareSettings = useUpdateShareSettings()
  const handlePriceFormSubmit = (values: any) => {
    mutateShareSettings.mutate(
      { id: vehicleServiceId, updateShareSettings: values },
      {
        onSuccess: () => {
          query.invalidateQueries(ApiRouteEnum.VEHICLE_SERVIS)
          showCustomToast({
            status: 'success',
            title: 'Zmeny boli uložené',
            label: <div>Nastavenia zdieľania boli zmenené.</div>,
          })
        },
        onError: () => {
          showCustomToast({
            status: 'error',
            title: 'Zmeny sa nepodarilo uložiť',
            label: <div>Nastavenia zdieľania sa nepodarilo zmeniť.</div>,
          })
        },
      },
    )
  }

  useEffect(() => {
    if (!isMobile) {
      navigate(RouterEnum.HOMEPAGE)
    }
  }, [isMobile])

  useEffect(() => {
    if (shareOption === 'email') {
      setContent(
        <Formik
          initialValues={{ email: '' }}
          onSubmit={handleEmailSend}
          validationSchema={EmailFormSchema}
        >
          {({ errors, touched }) => {
            return (
              <Form style={{ height: '80%', width: '100%' }}>
                <Flex
                  flexFlow={'column'}
                  justifyContent={'space-between'}
                  h={'100%'}
                  w={'85%'}
                  m={'0 auto'}
                  p={'1rem 0'}
                >
                  <Box></Box>
                  <Field
                    name="email"
                    label={'e-mail'}
                    type="email"
                    status={getFieldStatus(errors, touched, 'email')}
                    component={Input}
                  />
                  <Button variant={'blue'} type="submit">
                    Odoslať
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>,
      )
    }

    if (shareOption === 'qrCode') {
      setContent(
        <Flex
          flexFlow={'column'}
          justifyContent={'space-between'}
          h={'80%'}
          w={'85%'}
          m={'0 auto'}
          p={'1rem 0'}
        >
          <Box></Box>
          <Flex h={'20rem'} w={'20rem'} m={'0 auto'}>
            {codeData?.data?.url || codePrefillData?.url ? (
              <Image
                src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${
                  codeData?.data?.url || codePrefillData?.url
                }&choe=UTF-8`}
                aria-label="QR code"
                w={'100%'}
                h={'100%'}
                fallback={<Spinner />}
              />
            ) : (
              <QRIcon color={'#71819B'} fontSize={'20rem'} />
            )}
          </Flex>
          <Button variant={'blue'} onClick={() => mutateGetCode(vehicleServiceId)}>
            Generovať
          </Button>
        </Flex>,
      )
    }

    if (shareOption === 'shareSettings') {
      setContent(
        <Formik
          initialValues={{
            totalPrice: priceSharing.totalPrice,
            priceItems: priceSharing.priceItems,
          }}
          onSubmit={handlePriceFormSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form style={{ height: '100%' }}>
                <Flex
                  flexFlow={'column'}
                  justifyContent={'space-between'}
                  h={'90%'}
                  w={'85%'}
                  m={'0 auto'}
                  p={'1rem 0'}
                >
                  <Box></Box>
                  <Box>
                    <Flex
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      fontSize={'0.8rem'}
                      fontWeight={'semibold'}
                    >
                      Zdieľať cenu{' '}
                      <Field
                        name="totalPrice"
                        isChecked={values.totalPrice}
                        component={Switch}
                        onChange={(e: any) => {
                          const value = values.totalPrice
                          setFieldValue('totalPrice', !value)
                          if (!value === false) {
                            setFieldValue('priceItems', false)
                          }
                        }}
                      />
                    </Flex>
                    <Flex
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      fontSize={'0.8rem'}
                      fontWeight={'semibold'}
                      mt={'1rem'}
                    >
                      Zdieľať položky ceny{' '}
                      <Field
                        name="priceItems"
                        isChecked={values.priceItems}
                        isDisabled={!values.totalPrice}
                        component={Switch}
                      />
                    </Flex>
                  </Box>
                  <Button variant={'green'} type={'submit'} mt={'1rem'}>
                    <Save />
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>,
      )
    }

    if (shareOption === '') {
      setContent(
        <Flex flexFlow={'column'} alignItems={'center'} gap={'1.5rem'}>
          <Button
            variant={'basic'}
            size="medium"
            onClick={() => setShareOption('qrCode')}
            h={'6rem'}
          >
            <QRIcon />
            <Box w={'70%'} whiteSpace={'pre-wrap'}>
              zdieľať QR
            </Box>
          </Button>
          <Button
            variant={'basic'}
            size="medium"
            onClick={
              codePrefillData.id
                ? () => {
                    navigator.clipboard.writeText(codePrefillData.url)
                    showCustomToast({
                      status: 'success',
                      title: 'Odkaz bol skopírovaný do schránky',
                      label: <div>Odkaz bol úspešne skopírovaný do schránky.</div>,
                    })
                  }
                : () => mutateGetCode(vehicleServiceId)
            }
            h={'6rem'}
          >
            <GlobeIcon />
            <Box w={'70%'} whiteSpace={'pre-wrap'}>
              skopírovať link
            </Box>
          </Button>
          <Button
            variant={'basic'}
            size="medium"
            onClick={() => setShareOption('shareSettings')}
            h={'6rem'}
          >
            <ShareIcon />
            <Box w={'70%'} whiteSpace={'pre-wrap'}>
              nastavenia zdieľania
            </Box>
          </Button>
        </Flex>,
      )
    }
  }, [shareOption, codeData, codePrefillData])

  return (
    <Flex flexFlow={'column'} h={'100%'} justifyContent={'center'} w={'100%'} bg={colors.blue[50]}>
      <Grid
        fontSize={'1.2rem'}
        fontWeight={'semibold'}
        w={'100%'}
        h={'4rem'}
        placeItems={'center'}
        bg={colors.gray[700]}
        color={colors.blue[100]}
        zIndex={100}
        position={'absolute'}
        top={'0px'}
      >
        Zdieľať s klientom
      </Grid>
      {content}
      <Menu
        back={shareOption !== '' ? () => setShareOption('') : () => navigate(-1)}
        type={'basic'}
      />
    </Flex>
  )
}

export default ShareMenu
