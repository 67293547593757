import { useMutation } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'
import { AxiosResponse } from 'axios'

const useUpdateServiceStatus = (successCallback?: () => void, errorCallback?: () => void) => {
  return useMutation(
    ({ status, id }: { status?: string; id: string }): Promise<AxiosResponse<any, any> | void> => {
      if (status) {
        return api.put(`${ApiRouteEnum.VEHICLE_SERVIS}/${id}/${ApiRouteEnum.STATUS}`, {
          transition: status,
        })
      }
      return Promise.resolve()
    },
    {
      mutationKey: [ApiRouteEnum.VEHICLE_SERVIS],
      onSuccess: () => {
        successCallback && successCallback()
      },
      onError: () => {
        errorCallback && errorCallback()
      },
    },
  )
}

export default useUpdateServiceStatus
