export const VehicleFormCreateLabels = {
  licensePlate: 'ŠPZ',
  model: 'Model vozidla',
  engineModel: 'Model motoru vozidla',
  brand: 'Značka / výrobca vozidla',
  vinCode: 'VIN',
  color: 'Farba vozidla',
  ownerId: 'Majiteľ vozidla',
  displayName: 'Meno',
  newVehicleMOT: 'STK',
  vehicleInsurance: 'Poistenie',
  newInsurance: 'Poistenie',
  manufacturedAt: 'Rok výroby',
  highwaySigns: 'Diaľničné známky',
}

export const VehicleServiceFormCreateLabels = {
  displayName: 'Meno',
  receivedAt: 'Dátum prevzatia',
  vehicle: 'Vozidlo',
}

export const RegisterFormLabels = {
  firstname: 'Krstné meno',
  lastname: 'Priezvisko',
  email: 'Email',
  password: 'Heslo',
  confirmPassword: 'Heslo',
  name: 'Názov servisu',
  companyIdentificationNumber: 'IČO',
  vatID: 'DIČ',
  city: 'Mesto',
  street: 'Ulica',
  houseNumber: 'Popisné číslo',
  zipCode: 'PSČ',
  title: 'Webovy názov',
  phone: 'Telefónne číslo',
  url: 'URL webstránky',
  description: 'Text na webe',
  showOnMap: 'Zverejniť na stránke ViVeM',
}

export const EmailModalFormLabels = {
  email: 'Email',
  deliveryTime: 'Dátum pre prevzatie vozidla',
  address: 'Adresa',
}

export const CustomerManagementFormLabels = {
  firstname: 'Meno',
  lastname: 'Priezvisko',
  email: 'Email',
  phone: 'Telefónne číslo',
  birthdate: 'Dátum narodenia',
  notes: 'Poznámky',
}

export const AccountDataFormLabels = {
  firstname: 'Meno',
  lastname: 'Priezvisko',
  email: 'Email',
}

export const InviteEmployeeFormLabels = {
  firstname: 'Meno',
  lastname: 'Priezvisko',
  email: 'Email',
}

export const AccountPasswordFormLabels = {
  oldPassword: 'Staré heslo',
  newPassword: 'Nové heslo',
  confirmPassword: 'Zopakovať heslo',
}

export const WorkspaceMarketingDataFormLabels = {
  title: 'Webovy názov',
  phone: 'Telefónne číslo',
  url: 'URL webstránky',
  description: 'Text na webe',
  showOnWeb: 'Zverejniť na stránke ViVeM',
}

export const WorkspaceDataFormLabels = {
  name: 'Názov servisu',
  email: 'Email',
  companyIdentificationNumber: 'IČO',
  vatID: 'DIČ',
  address: 'Všetky adresné údaje',
}
