import { Outlet } from 'react-router-dom'
import MainMenu from './components/MainMenu'
import { Flex, Grid } from '@chakra-ui/layout'
import Header from './components/Header'
import { colors } from './theme'
import useGetCurrentUser from './model/api/useGetCurrentUser'
import useIsMobile from './model/helpers/useIsMobile'

function App() {
  const isMobile = useIsMobile()
  const { data } = useGetCurrentUser()
  if (!data) {
    return null
  }
  if (isMobile) {
    return (
      <Flex flexFlow={'column'} w={'100%'} h={'100%'} bg={colors.blue[50]}>
        <Outlet context={data} />
      </Flex>
    )
  }
  return (
    <Flex w={'100%'} h={'100%'} bg={colors.blue[50]}>
      <MainMenu />
      <Flex flexFlow={'column nowrap'} w={'80%'}>
        <Header data={data} />
        <Outlet context={data} />
      </Flex>
    </Flex>
  )
}

export default App
