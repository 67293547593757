import { useMutation } from 'react-query'
import ApiRouteEnum from '../enums/ApiRouteEnum'
import api from './api'

export const useLoginUser = (successCallback?: () => void, errorCallback?: () => void) => {
  return useMutation(
    (loginData: { username: string; password: string }) =>
      api.post(`${ApiRouteEnum.LOGIN}`, loginData),
    {
      mutationKey: [ApiRouteEnum.LOGIN],
      onSuccess: (data) => {
        localStorage.setItem('token', data.data.token)
        successCallback && successCallback()
      },
      onError: () => {
        errorCallback && errorCallback()
      },
    },
  )
}
