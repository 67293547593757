import { useMutation } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useUpdateVehicleOwner = (successCallback?: () => void, errorCallback?: () => void) => {
  return useMutation(
    ({ updateVehicleOwnwerData, id }: { updateVehicleOwnwerData: any; id: string }) =>
      api.put(`${ApiRouteEnum.VEHICLE_OWNER}/${id}`, updateVehicleOwnwerData),
    {
      mutationKey: [ApiRouteEnum.VEHICLE_OWNER],
      onSuccess: () => {
        successCallback && successCallback()
      },
      onError: () => {
        errorCallback && errorCallback()
      },
    },
  )
}

export default useUpdateVehicleOwner
