import { useQuery } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useGetCurrentUser = () => {
  const { data, isLoading } = useQuery(ApiRouteEnum.ME, async () => {
    const response = await api.get(ApiRouteEnum.ME)
    return response.data
  })
  return { data, isLoading }
}

export default useGetCurrentUser
