import { useMutation } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

export const useUpdateService = () => {
  return useMutation(
    ({ serviceData, id }: { serviceData: any; id: string }) =>
      api.put(`${ApiRouteEnum.VEHICLE_SERVIS}/${id}`, serviceData),
    {
      mutationKey: [ApiRouteEnum.VEHICLE_SERVIS],
    },
  )
}
