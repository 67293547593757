import useIsMobile from '../../model/helpers/useIsMobile'
import ForgottenPasswordChange from './ForgottenPasswordChange'
import ForgottenPasswordChangeMobile from './ForgottenPasswordChangeMobile'

const ForgottenPasswordChangeWrapper = () => {
  const isMobile = useIsMobile()

  return isMobile ? <ForgottenPasswordChangeMobile /> : <ForgottenPasswordChange />
}
export default ForgottenPasswordChangeWrapper
