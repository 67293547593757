import React from 'react'

const useInitialValuesVehicleForm = ({car}: {car: any}) => {
    const initialValues = {
      licensePlate: car ? car.licensePlate : null,
      model: car ? car.model : '',
      engineModel: car ? car.engineModel : null,
      brand: car ? car.brand : '',
      vinCode: car ? car.vinCode : '',
      color: car ? car.color : null,
      ownerId:
        car && car.vehicleOwner
          ? {
              label: `${car.vehicleOwner.firstname} ${car.vehicleOwner.lastname}`,
              value: car.vehicleOwner.id,
            }
          : { label: '', value: '' },
      displayName: car ? car.displayName : '',
      vehicleMOT: car ? car.vehicleMOT : [],
      newVehicleMOT: [],
      vehicleInsurance: car ? car.insurance : [],
      newInsurance: [],
      manufacturedAt: car ? car.manufacturedAt : '',
      description: car ? car.description : null,
      highwaySigns: car ? car.highwaySigns : [],
      imageObject: [],
      imageObjectWithIds: [],
    }
  return {initialValues}
}

export default useInitialValuesVehicleForm