import * as Yup from 'yup';

export const FilterFormSchema = Yup.object({
  ownerId: Yup.object({
    label: Yup.string(),
    value: Yup.string(),
  }),
  model: Yup.string(),
  licensePlate: Yup.string(),
  ownerEmail: Yup.string(),
  brand: Yup.string(),
  vinCode: Yup.string(),
})