import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react'
import { DisabledInput } from './DisabledInput'
import { Box } from './Box'
import Button from './Button'
import Cancel from '../icons/Cancel'
import Delete from '../icons/Delete'

const DeleteModal = ({isDeleteModalOpen, onDeleteModalClose, titleValue, onClickAction}: {isDeleteModalOpen: boolean, onDeleteModalClose: () => void, titleValue: string, onClickAction: () => void}) => {
  return (
    <Modal size={'xs'} isOpen={isDeleteModalOpen} onClose={onDeleteModalClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'}>Naozaj chcete odstrániť?</ModalHeader>
        <ModalBody pb={'0'}>
          <DisabledInput label={'názov položky'} defaultValue={titleValue} />
          <Box textAlign={'center'} m={'2rem'}>
            Táto akcia je nevratná.
          </Box>
        </ModalBody>

        <ModalFooter display={'flex'} justifyContent={'space-between'} gap={3} pt={'0'}>
          <Button variant={'blue'} onClick={onDeleteModalClose}>
            <Cancel />
          </Button>
          <Button variant={'red'} onClick={onClickAction}>
            <Delete />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeleteModal
