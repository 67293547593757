import { useQuery } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useGetCode = (id: string) => {
  const { data } = useQuery([ApiRouteEnum.CODE, id], async () => {
    if(id === '') return
    const response = await api.get(`${ApiRouteEnum.CODE}/${id}`)
    return response.data
  })
  return { data }
}

export default useGetCode
