export interface RegisterFormData {
  tierName: string
  name: string
  email: string
  companyIdentificationNumber: string | null
  vatId: string | null
  phone: string
  address: {
    city: string | null
    zipCode: string | null
    street: string | null
    houseNumber: string | null
    state: string | null
  }
  workspaceMarketingData: {
    url: string | null
    title: string | null
    description: string | null
    showOnWeb: boolean
  }
  user: {
    email: string
    password: string
    confirmPassword: string
    firstname: string
    lastname: string
  }
}

const RegisterInitialValues: RegisterFormData = {
  tierName: 'FREE',
  name: '',
  email: '',
  phone: '',
  companyIdentificationNumber: null,
  vatId: null,
  address: {
    city: null,
    zipCode: null,
    street: null,
    houseNumber: null,
    state: null,
  },
  workspaceMarketingData: {
    url: null,
    title: null,
    description: null,
    showOnWeb: false,
  },
  user: {
    email: '',
    password: '',
    confirmPassword: '',
    firstname: '',
    lastname: '',
  },
}

export default RegisterInitialValues
