import { IS_REQUIRED } from '../constants/ValidationMessages'

export const ToastHelper = ({
  submitForm,
  errors,
  setFieldTouched,
  labels,
  showCustomToast,
}: {
  submitForm: () => void
  errors: any
  setFieldTouched: (item: any, value: boolean) => void
  labels: any
  showCustomToast: any
}) => {
  console.log(errors)
  if (Object.keys(errors).length === 0) {
    submitForm()
  } else {
    let errorItems = ''
    Object.keys(errors).map((item, index) => {
      setFieldTouched(item, true)
      if (item === 'newVehicleMOT') {
        showCustomToast({
          status: 'error',
          title: 'Formulár nemožno odoslať',
          label: <div>Údaje o STK musia byť vyplnené všetky</div>,
        })
        return
      }
      if (item === 'highwaySigns') {
        showCustomToast({
          status: 'error',
          title: 'Formulár nemožno odoslať',
          label: <div>Údaje o diaľničnej známke musia byť vyplnené všetky</div>,
        })
        return
      }
      if (item === 'newInsurance') {
        showCustomToast({
          status: 'error',
          title: 'Formulár nemožno odoslať',
          label: <div>Údaje o poistení musia byť vyplnené všetky</div>,
        })
        return
      }
      if (errors[item as keyof typeof errors] === IS_REQUIRED || item === 'vehicle' || item === 'ownerId') {
        if (errorItems === '') {
          return (errorItems += `${labels[item as keyof typeof labels]}`)
        }
        return (errorItems += `, ${labels[item as keyof typeof labels]}`)
      } else {
        showCustomToast({
          status: 'error',
          title: 'Formulár nemožno odoslať',
          label: `${errors[item as keyof typeof errors]}`,
        })
      }
    })
    if (errorItems !== '') {
      showCustomToast({
        status: 'error',
        title: 'Formulár nemožno odoslať',
        label: (
          <div>
            {errorItems.split(',').length > 1 ? 'Položky ' : 'Položka '}
            <b>{errorItems}</b>
            {errorItems.split(',').length > 1 ? ' sú povinné' : ' je povinná'}
          </div>
        ),
      })
    }
  }
}
