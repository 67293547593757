import * as Yup from 'yup'
import { IS_EMAIL, IS_PASSWORD, IS_PASSWORD_CONFIRM, IS_REQUIRED } from '../constants/ValidationMessages'

export const RegisterFormSchema = Yup.object({
  firstname: Yup.string().required(IS_REQUIRED),
  lastname: Yup.string().required(IS_REQUIRED),
  email: Yup.string()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, IS_EMAIL)
    .required(IS_REQUIRED),
  password: Yup.string().required(IS_REQUIRED).min(8, IS_PASSWORD),
  confirmPassword: Yup.string()
    .required(IS_REQUIRED)
    .oneOf([Yup.ref('password'), ''], IS_PASSWORD_CONFIRM),
})
