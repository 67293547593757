import * as Yup from 'yup'
import { IS_REQUIRED } from '../constants/ValidationMessages'
export const vehicleFormSchema = Yup.object({
  displayName: Yup.string().optional(),
  licensePlate: Yup.string().nullable(),
  model: Yup.string().required(IS_REQUIRED),
  engineModel: Yup.string().nullable(),
  brand: Yup.string().required(IS_REQUIRED),
  vinCode: Yup.string().required(IS_REQUIRED),
  color: Yup.string().nullable(),
  ownerId: Yup.object({
    label: Yup.string().required(IS_REQUIRED),
    value: Yup.string().required(IS_REQUIRED),
  }).required(IS_REQUIRED),
  manufacturedAt: Yup.string().required(IS_REQUIRED),
  vehicleMOT: Yup.array(
    Yup.object({
      validFrom: Yup.string().required(IS_REQUIRED),
      validTo: Yup.string().required(IS_REQUIRED),
    }),
  ).optional(),
  newVehicleMOT: Yup.array(
    Yup.object({
      validFrom: Yup.string().required(IS_REQUIRED),
      validTo: Yup.string().required(IS_REQUIRED),
    }),
  ).optional(),
  vehicleInsurance: Yup.array(
    Yup.object({
      validFrom: Yup.string().required(IS_REQUIRED),
      validTo: Yup.string().required(IS_REQUIRED),
      insuranceCompany: Yup.string().required(IS_REQUIRED),
      contractNumber: Yup.string().required(IS_REQUIRED),
      greenCard: Yup.object({
        id: Yup.string(),
      }).nullable(),
    }),
  ),
  newInsurance: Yup.array(
    Yup.object({
      validFrom: Yup.string().required(IS_REQUIRED),
      validTo: Yup.string().required(IS_REQUIRED),
      insuranceCompany: Yup.string().required(IS_REQUIRED),
      contractNumber: Yup.string().required(IS_REQUIRED),
      greenCard: Yup.object({
        id: Yup.string(),
      }).nullable(),
    }),
  ).optional(),
  description: Yup.string().nullable(),
  highwaySigns: Yup.array(
    Yup.object({
      name: Yup.string().required(IS_REQUIRED),
      validFrom: Yup.string().required(IS_REQUIRED),
      validTo: Yup.string().required(IS_REQUIRED),
    }),
  ).optional(),
  imageObject: Yup.array(Yup.object()).nullable(),
  imageObjectWithIds: Yup.array(Yup.object()).nullable(),
})
