import {
  Box,
  Input as ChakraInput,
  Flex,
  FormLabel,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import StatusIndicator from './StatusIndicator'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../css/datePicker.css'
import sk from 'date-fns/locale/sk'
import Calendar from '../icons/Calendar'
import { format } from 'date-fns'

interface InputProps {
  type?: string
  id: string
  label?: string
  status: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void // Adjust the event type if needed
  field: {
    value: any
    onChange: (value: any) => void
    onBlur: () => void
    name: string
  }
  boxWidth?: string
  setFieldValue?: any
  innerRef?: any
  hide?: boolean
  placeholder?: string
}

export const Input = ({
  field,
  type,
  id,
  label,
  status,
  boxWidth,
  setFieldValue,
  innerRef,
  hide,
  placeholder,
  ...otherProps
}: InputProps) => {
  const handleDateSelect = (date: Date) => {
    setFieldValue(field.name, new Date(date).toISOString())
  }

  const placeholderDeterminer = (name: string) => {
    switch (name) {
      case 'firstname':
        return 'Jozef'
      case 'lastname':
        return 'Mrkvička'
      case 'email':
        return 'priklad@email.sk'
      case 'password':
        return '********'
      case 'confirmPassword':
        return '********'
      case 'phoneNumber':
        return '+421 123 456 789'
      case 'city':
        return 'Košice'
      case 'street':
        return 'Hlavná'
      case 'houseNumber':
        return '1'
      case 'zipCode':
        return '040 01'
      case 'displayName':
        return 'Servis s.r.o.'
      case 'licensePlate':
        return 'KE123AB'
      case 'model':
        return 'Fabia'
      case 'engineModel':
        return '1.4 TDI'
      case 'brand':
        return 'Škoda'
      case 'vinCode':
        return '1G1JC124017141396'
      case 'color':
        return 'červená'
      case 'description':
        return 'Poznámky k vozidlu'
      case 'manufacturedAt':
        return '1999'
      case 'serviceType':
        return 'Výmena oleja'
      case 'serviceDescription':
        return 'Výmena oleja a filtrov'

      default:
        return label ? label : ''
    }
  }

  if (type === 'checkListItem') {
    return (
      <ChakraInput
        {...field}
        textAlign={'left'}
        w={'100%'}
        bg={'transparent !important'}
        {...otherProps}
        fontSize={'1rem'}
        value={field.value}
        focusBorderColor={'transparent'}
        placeholder={placeholder ? placeholder : undefined}
      />
    )
  }
  if (type === 'time') {
    return (
      <Box w={boxWidth ? boxWidth : '100%'} display={hide ? 'none' : 'initial'}>
        <Flex h={'3rem'} flexFlow={'row nowrap'} alignItems={'center'}>
          <DatePicker
            selected={field.value ? new Date(field.value) : ''}
            onChange={handleDateSelect}
            dateFormat="hh:mm"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            locale={sk}
            customInput={
              <InputGroup h={'100%'}>
                <FormLabel w={'30%'}>{label}</FormLabel>
                <ChakraInput
                  isReadOnly
                  autoComplete={'off'}
                  w={'70%'}
                  {...field}
                  {...otherProps}
                  fontSize={'1rem'}
                  value={field.value ? format(new Date(field.value), 'HH:mm') : ''}
                  placeholder={'10:00'}
                  focusBorderColor={'transparent'}
                />
                <InputRightElement>
                  <Calendar fontSize={'1rem'} color="blue.500" pt={'0.5rem'} />
                </InputRightElement>
              </InputGroup>
            }
          />
          <StatusIndicator status={status} />
        </Flex>
      </Box>
    )
  }
  return (
    <Box w={boxWidth ? boxWidth : '100%'} display={hide ? 'none' : 'initial'}>
      <Flex h={'3rem'} flexFlow={'row nowrap'} alignItems={'center'}>
        {type === 'date' ? (
          <DatePicker
            selected={field.value ? new Date(field.value) : ''}
            onChange={handleDateSelect}
            dateFormat="yyyy-MM-dd"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            locale={sk}
            popperModifiers={{
              preventOverflow: {
                enabled: true,
              },
            }}
            customInput={
              <InputGroup h={'100%'}>
                <FormLabel w={'30%'}>{label}</FormLabel>
                <ChakraInput
                  isReadOnly
                  autoComplete={'off'}
                  w={'70%'}
                  {...field}
                  {...otherProps}
                  fontSize={'1rem'}
                  value={field.value ? format(new Date(field.value), 'dd.MM.yyyy') : ''}
                  placeholder={'DD.MM.RRRR'}
                  focusBorderColor={'transparent'}
                />
                <InputRightElement>
                  <Calendar fontSize={'1rem'} color="blue.500" pt={'0.5rem'} />
                </InputRightElement>
              </InputGroup>
            }
          />
        ) : (
          <>
            <FormLabel w={'30%'}>{label}</FormLabel>
            <ChakraInput
              type={type}
              {...field}
              w={'70%'}
              {...otherProps}
              fontSize={'1rem'}
              value={field.value || undefined}
              ref={innerRef}
              p={5}
              id={field.name}
              placeholder={placeholder ? placeholder : placeholderDeterminer(field.name)}
              focusBorderColor={'transparent'}
            />
          </>
        )}
        <StatusIndicator status={status} />
      </Flex>
    </Box>
  )
}
