import useIsMobile from '../../../model/helpers/useIsMobile'
import CustomersOverview from '../CustomerOverview'
import CustomersOverviewMobile from '../mobile/CustomerOverviewMobile'

const CustomersOverviewWrapper = () => {
  const isMobile = useIsMobile()

  return isMobile ? <CustomersOverviewMobile /> : <CustomersOverview />
}
export default CustomersOverviewWrapper
