import { useMutation } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useUpdateVehicleMOT = () => {
  return useMutation(
    ({ updateMOTData, id }: { updateMOTData: any; id: string }) =>
      api.put(`${ApiRouteEnum.VEHICLE_MOT}/${id}`, updateMOTData),
    {
      mutationKey: [ApiRouteEnum.VEHICLE_MOT],
    },
  )
}

export default useUpdateVehicleMOT
