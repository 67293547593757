import { Divider, Flex, Grid, useDisclosure } from '@chakra-ui/react'
import DownChevronIcon from '../icons/DownChevronIcon'
import { colors } from '../theme'
import { DisabledInput } from './DisabledInput'
import Calendar from '../icons/Calendar'
import Button from './Button'
import { Add } from '../icons/Add'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { Field } from 'formik'
import { Input } from './Input'
import getFieldStatus from '../model/helpers/StatusHelper'
import ImagePlaceholder from './ImagePlaceholder'
import { useCustomToast } from './Toast'
import Delete from '../icons/Delete'
import { useDeleteInsurance } from '../model/api/useDeleteInsurance'

const InsuranceDropdown = ({
  vehicleInsurance,
  mode,
  setFieldValue,
  errors,
  touched,
  values,
}: {
  vehicleInsurance?: any
  mode: string
  setFieldValue?: any
  errors?: any
  touched?: any
  values?: any
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: mutateDelete } = useDeleteInsurance()
  const { showCustomToast } = useCustomToast()
  const handleOpenAndClose = () => {
    if (isOpen) {
      onClose()
    }
    if (!isOpen) {
      onOpen()
    }
  }

  const handleFileUpload = (e: any, name: string, identifier?: string) => {
    const file = e.target.files[0]
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        showCustomToast({
          status: 'error',
          title: 'Nahrávanie zlyhalo',
          label: <div>Fotografia musí byť menšia ako 5MB</div>,
        })
        return
      }
      const reader = new FileReader()

      reader.onload = () => {
        const binaryData = reader.result as ArrayBuffer
        const base64Data = btoa(
          new Uint8Array(binaryData).reduce((data, byte) => data + String.fromCharCode(byte), ''),
        )

        setFieldValue(name, { mimeType: file.type, data: base64Data })
      }
      reader.readAsArrayBuffer(file)
    }
  }

  return (
    <>
      <Flex
        w={'100%'}
        h={'3rem'}
        bg={colors.gray[700]}
        color={colors.blue[100]}
        justifyContent={'space-between'}
        onClick={handleOpenAndClose}
        cursor={'pointer'}
      >
        <Grid placeItems={'center'} w={'80%'} fontSize={'0.8rem'} fontWeight={'semibold'}>
          Poistenie
        </Grid>
        <Grid placeItems={'center'} w={'20%'}>
          <DownChevronIcon fontSize={'1rem'} transform={isOpen && 'rotate(180deg)'} />
        </Grid>
      </Flex>
      {isOpen && (
        <Flex flexFlow={'column'} gap={'0.8rem'} w={{ sm: '100%', md: 'initial' }}>
          {mode === 'edit' &&
            vehicleInsurance &&
            vehicleInsurance.map((item: any, index: number) => {
              return (
                <>
                  <Field
                    bg={colors.blue[100]}
                    name={`vehicleInsurance[${index}].insuranceCompany`}
                    label={'spoločnosť'}
                    setFieldValue={setFieldValue}
                    component={Input}
                    status={getFieldStatus(
                      errors,
                      touched,
                      `vehicleInsurance[${index}].insuranceCompany`,
                      false,
                    )}
                  />
                  <Field
                    bg={colors.blue[100]}
                    name={`vehicleInsurance[${index}].contractNumber`}
                    label={'číslo zmluvy'}
                    setFieldValue={setFieldValue}
                    component={Input}
                    status={getFieldStatus(
                      errors,
                      touched,
                      `vehicleInsurance[${index}].contractNumber`,
                      false,
                    )}
                  />
                  <Field
                    bg={colors.blue[100]}
                    name={`vehicleInsurance[${index}].validFrom`}
                    label={'platnosť od'}
                    type="date"
                    setFieldValue={setFieldValue}
                    component={Input}
                    status={getFieldStatus(
                      errors,
                      touched,
                      `vehicleInsurance[${index}].validFrom`,
                      false,
                    )}
                  />
                  <Field
                    bg={colors.blue[100]}
                    name={`vehicleInsurance[${index}].validTo`}
                    label={'platnosť do'}
                    type="date"
                    setFieldValue={setFieldValue}
                    component={Input}
                    status={getFieldStatus(
                      errors,
                      touched,
                      `vehicleInsurance[${index}].validTo`,
                      false,
                    )}
                  />

                  {item.greenCard ? (
                    <ImagePlaceholder image={item.greenCard.id} />
                  ) : (
                    <ImagePlaceholder />
                  )}

                  <Button variant={'red'} onClick={() => mutateDelete(item.id)}>
                    <Delete />
                  </Button>
                  <Divider
                    borderWidth={'0.2rem 0'}
                    borderColor={colors.gray[700]}
                    bg={colors.gray[700]}
                  />
                </>
              )
            })}

          {values?.newInsurance.map((item: any, index: number) => {
            return (
              <React.Fragment key={`newInsurance${item.randomId}`}>
                <Field
                  bg={colors.blue[100]}
                  name={`newInsurance[${index}].insuranceCompany`}
                  label={'spoločnosť'}
                  component={Input}
                  status={getFieldStatus(
                    errors,
                    touched,
                    `newInsurance[${index}].insuranceCompany`,
                  )}
                />
                <Field
                  bg={colors.blue[100]}
                  name={`newInsurance[${index}].contractNumber`}
                  label={'číslo zmluvy'}
                  component={Input}
                  status={getFieldStatus(errors, touched, `newInsurance[${index}].contractNumber`)}
                />
                <Field
                  bg={colors.blue[100]}
                  name={`newInsurance[${index}].validFrom`}
                  label={'platnosť od'}
                  type="date"
                  setFieldValue={setFieldValue}
                  component={Input}
                  status={getFieldStatus(errors, touched, `newInsurance[${index}].validFrom`)}
                />
                <Field
                  bg={colors.blue[100]}
                  name={`newInsurance[${index}].validTo`}
                  label={'platnosť do'}
                  type="date"
                  setFieldValue={setFieldValue}
                  component={Input}
                  status={getFieldStatus(errors, touched, `newInsurance[${index}].validTo`)}
                />
                <Field
                  bg={colors.blue[100]}
                  name={`image[${index}]`}
                  component={Input}
                  type={'file'}
                  onChange={(e: any) => handleFileUpload(e, `imageObject[${index}]`)}
                  hide={true}
                />
                <label htmlFor={`image[${index}]`}>
                  <ImagePlaceholder
                    src={
                      values[`imageObject`] && values[`imageObject`]?.[`${index}`]
                        ? `data:${values[`imageObject`][`${index}`].mimeType};base64,${
                            values[`imageObject`][`${index}`].data
                          }`
                        : undefined
                    }
                  />
                </label>
                <Button
                  variant={'red'}
                  onClick={() => {
                    setFieldValue(`newInsurance[${index}].insuranceCompany`, '')
                    const updatedNewInsurance = [...values[`newInsurance`]]
                    updatedNewInsurance.splice(index, 1)
                    setFieldValue(`newInsurance`, updatedNewInsurance)
                    const updatedImageObject = [...values[`imageObject`]]
                    updatedImageObject.splice(index, 1)
                    setFieldValue(`imageObject`, updatedImageObject)
                  }}
                >
                  <Delete />
                </Button>
                {index !== values.newInsurance.length - 1 && (
                  <Divider
                    borderWidth={'0.2rem 0'}
                    borderColor={colors.gray[700]}
                    bg={colors.gray[700]}
                  />
                )}
              </React.Fragment>
            )
          })}

          {mode === 'viewOnly' &&
            vehicleInsurance &&
            vehicleInsurance.map((item: any, index: number) => {
              return (
                <>
                  <DisabledInput label={'názov'} defaultValue={item.insuranceCompany} />
                  <DisabledInput label={'číslo zmluvy'} defaultValue={item.contractNumber} />
                  <Grid templateColumns={'repeat(2, 1fr)'} gap={3}>
                    <Flex
                      h={'3rem'}
                      bg={colors.blue[100]}
                      justifyContent={'space-around'}
                      alignItems={'center'}
                      fontSize={'0.8rem'}
                      fontWeight={'semibold'}
                    >
                      {item.validFrom && (
                        <>
                          <Calendar fontSize={'1rem'} color={colors.blue[500]} />
                          {format(new Date(item.validFrom), 'dd.MM.yyyy')}
                        </>
                      )}
                    </Flex>
                    <Flex
                      h={'3rem'}
                      bg={colors.blue[100]}
                      justifyContent={'space-around'}
                      alignItems={'center'}
                      fontSize={'0.8rem'}
                      fontWeight={'semibold'}
                    >
                      {item.validTo && (
                        <>
                          <Calendar fontSize={'1rem'} color={colors.blue[500]} />
                          {format(new Date(item.validTo), 'dd.MM.yyyy')}
                        </>
                      )}
                    </Flex>
                  </Grid>
                  {item.greenCard && <ImagePlaceholder image={item.greenCard.id} />}

                  {index !== vehicleInsurance.length - 1 && (
                    <Divider
                      borderWidth={'0.2rem 0'}
                      borderColor={colors.gray[700]}
                      bg={colors.gray[700]}
                    />
                  )}
                </>
              )
            })}

          {mode !== 'viewOnly' && (
            <Button
              size={'full'}
              variant={'blue'}
              onClick={() =>
                setFieldValue('newInsurance', [
                  ...values.newInsurance,
                  {
                    insuranceCompany: '',
                    contractNumber: '',
                    validFrom: '',
                    validTo: '',
                    randomId: Math.floor(Math.random() * 100),
                  },
                ])
              }
            >
              <Add fontSize={'1rem'} /> pridať poistenie
            </Button>
          )}
        </Flex>
      )}
    </>
  )
}

export default InsuranceDropdown
