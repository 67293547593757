import { Button, Flex, Grid } from '@chakra-ui/react'
import { Box } from '../../components/Box'
import { colors } from '../../theme'
// import { ReactComponent as LogoVivem } from '../../assets/logoVivem.svg'
import LogoVivem from '../../assets/logoVivem'

const RegisterAfterInviteWelcome = ({ onSetRegisterStep }: { onSetRegisterStep: () => void }) => {
  const queryParams = new URLSearchParams(window.location.search)
  const serviceName = queryParams.get('workspace_name')
  if (!serviceName) {
    return null
  }

  return (
    <Grid h={'100%'} w={'100%'} placeItems={'center'} className="background">
      <Flex
        w={'20rem'}
        p={5}
        bg={colors.blue[50]}
        alignItems={'center'}
        flexFlow={'column nowrap'}
        justifyContent={'space-between'}
        zIndex={10}
      >
        <Flex
          alignItems={'center'}
          flexFlow={'column nowrap'}
          justifyContent={'space-between'}
          gap={'0.8rem'}
        >
          <LogoVivem width={'6rem'} />

          <Box fontSize={'0.8rem'} textAlign={'center'} m={'1rem 0'}>
            Boli ste pozvaní do pracoviska autoservisu:
          </Box>
          <Box fontSize={'0.8rem'} fontWeight={'semibold'} textAlign={'center'} m={'1rem 0'}>
            {serviceName}
          </Box>
          <Box fontSize={'0.8rem'} textAlign={'center'} m={'1rem 0'}>
            Pokiaľ sa chcete stať užívateľom aplikácie, prosím pokračujte.
          </Box>
          <Button variant={'blue'} size={'full'} onClick={onSetRegisterStep}>
            registrovať sa
          </Button>
        </Flex>
      </Flex>
    </Grid>
  )
}

export default RegisterAfterInviteWelcome
