import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { Box } from '../../components/Box'
import Button from '../../components/Button'
import { Add } from '../../icons/Add'
import { colors } from '../../theme'
import InviteEmployeeForm from './InviteEmployeeForm'
import useGetAllUsers from '../../model/api/useGetAllUsers'
import EmployeeCard from '../../components/EmployeeCard'
import PeopleGroupIcon from '../../icons/PeopleGroupIcon'
import Spinner from '../../components/Spinner'
import { useOutletContext } from 'react-router-dom'
import useGetWorkspaceQuotas from '../../model/api/useGetWorkspaceQuotas'
import { useCustomToast } from '../../components/Toast'

const EmployeesOverview = () => {
  const { data, isLoading } = useGetAllUsers()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dataMe = useOutletContext() as any
  const { showCustomToast, onCloseHandler, setIsHovering } = useCustomToast()
  const { data: workspaceDataQuotas, isLoading: workspaceLoading } = useGetWorkspaceQuotas(
    dataMe.workspace.id,
  )

  if (isLoading || workspaceLoading) {
    return <Spinner />
  }

  return (
    <Flex
      w={'100%'}
      h={'100%'}
      alignItems={'center'}
      flexFlow={'column'}
      overflow={'auto'}
      padding={'2% 5%'}
    >
      <Box w={'80%'} mt={'1rem'}>
        <Box fontSize={'2rem'} fontWeight={'semibold'} w={'100%'} textAlign={'center'} m={'1rem 0'}>
          Správa zamestnancov
        </Box>
        <Flex mt={'2rem'} flexFlow={'row wrap'} gap={'1rem'}>
          {dataMe.roles.includes('ROLE_WORKSPACE_OWNER') && (
            <Box
              w={'30%'}
              h={'unset'}
              onMouseEnter={
                workspaceDataQuotas.users.current >= workspaceDataQuotas.users.maximum
                  ? () => {
                      setIsHovering(true)
                      showCustomToast({
                        status: 'warning',
                        title: 'Dosiahli ste maximálny počet užívateľov',
                        label: (
                          <div>
                            Vo Vašom pracovisku bol dosiahnutý maximálny možný počet užívateľov. Ak
                            chcete pridať ďalšieho užívateľa, odstráňte nejakého z existujúcich
                            alebo navýšte limit v nastaveniach pracoviska.
                          </div>
                        ),
                      })
                    }
                  : undefined
              }
              onMouseLeave={() => onCloseHandler && onCloseHandler()}
            >
              <Button
                size="large"
                variant="basic"
                w={'100%'}
                h={'100%'}
                bg={colors.blue[100]}
                onClick={onOpen}
                isDisabled={workspaceDataQuotas.users.current >= workspaceDataQuotas.users.maximum}
              >
                <Flex w={'8rem'} alignItems={'center'} justifyContent={'space-around'} mb={'1rem'}>
                  <Add fontSize={'2rem'} />
                  <PeopleGroupIcon fontSize={'2rem'} />
                </Flex>
                pridať zamestnanca
              </Button>
            </Box>
          )}
          {data &&
            data.map((item: any) => {
              return <EmployeeCard item={item} key={item.id} />
            })}
        </Flex>
      </Box>

      <Modal size={'xs'} isOpen={isOpen} onClose={onClose} isCentered={true} variant={'confirm'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg={colors.gray[700]} color={colors.blue[100]}>
            Pozvať zamestnanca?
          </ModalHeader>
          <ModalBody>
            <InviteEmployeeForm closeModal={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default EmployeesOverview
