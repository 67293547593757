import { useMutation } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'
import { RegisterFormData } from '../constants/RegisterInitialValues'

export const useRegisterUser = (successCallback: () => void, errorCallback: () => void) => {
  return useMutation(
    (registerData: RegisterFormData) =>
      api.post(`${ApiRouteEnum.WORKSPACE}`, {
        ...registerData,
        address:
          !registerData.address.city && !registerData.address.street
            ? null
            : { ...registerData.address },
        workspaceMarketingData: {
          ...registerData.workspaceMarketingData,
          phone: registerData.phone !== '' ? registerData.phone : null,
        },
      }),
    {
      mutationKey: [ApiRouteEnum.WORKSPACE],
      onSuccess: () => {
        successCallback()
      },
      onError: () => {
        errorCallback()
      },
    },
  )
}
