import { Flex, Grid } from '@chakra-ui/react'
import Menu from './Menu'
import { colors } from '../theme'
import Button from './Button'
import RouterEnum from '../model/enums/RouterEnum'
import { useNavigate } from 'react-router-dom'
import useIsMobile from '../model/helpers/useIsMobile'
import { useEffect } from 'react'
import { Add } from '../icons/Add'
import CarIcon from '../icons/CarIcon'
import PersonIcon from '../icons/PersonIcon'
import Settings from '../icons/Settings'
import useGetWorkspaceQuotas from '../model/api/useGetWorkspaceQuotas'
import useGetCurrentUser from '../model/api/useGetCurrentUser'
import { useCustomToast } from './Toast'
import useGetAllVehicleOwners from '../model/api/useGetAllVehicleOwners'
import Spinner from './Spinner'
import useGetAllCars from '../model/api/useGetAllCars'

const AddMenu = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { showCustomToast } = useCustomToast()
  const { data: vehicleOwnersData, isLoading: isVehicleOwnersLoading } = useGetAllVehicleOwners()
  const { data, isLoading: isLoadingUser } = useGetCurrentUser()
  const { data: workspaceDataQuotas, isLoading } = useGetWorkspaceQuotas(data?.workspace.id)
  const { data: vehiclesData, isLoading: isVehiclesDataLoading } = useGetAllCars()

  useEffect(() => {
    if (!isMobile) {
      navigate(RouterEnum.HOMEPAGE)
    }
  }, [isMobile])

  if (isLoadingUser || isLoading || isVehicleOwnersLoading || isVehiclesDataLoading) {
    return (
      <>
        <Grid h={'45rem'} w={'100%'}>
          <Spinner />
        </Grid>
        <Menu back={() => navigate(-1)} type={'basic'} />
      </>
    )
  }

  return (
    <Flex
      flexFlow={'column'}
      h={'45rem'}
      justifyContent={'center'}
      w={'100%'}
      gap={'1rem'}
      bg={colors.blue[50]}
      alignItems={'center'}
    >
      <Button
        size="large"
        variant="basic"
        w={'90%'}
        bg={colors.blue[100]}
        onClick={() =>
          navigate('/' + RouterEnum.CUSTOMER_MANAGEMENT, {
            state: { openByDefault: true },
          })
        }
      >
        <Flex w={'8rem'} alignItems={'center'} justifyContent={'space-around'} mb={'1rem'}>
          <Add fontSize={'2rem'} />
          <PersonIcon fontSize={'2rem'} />
        </Flex>
        pridať zákazníka
      </Button>

      <Button
        size="large"
        variant="basic"
        w={'90%'}
        bg={colors.blue[100]}
        onClick={
          workspaceDataQuotas?.vehicle.current >= workspaceDataQuotas?.vehicle.maximum
            ? () => {
                showCustomToast({
                  status: 'warning',
                  title: 'Dosiahli ste maximálny počet vozidiel',
                  label: (
                    <div>
                      Vo Vašom pracovisku bol dosiahnutý maximálny možný počet vozidiel. Ak chcete
                      pridať ďalšie vozidlo, odstráňte nejaké z existujúcich alebo navýšte limit v
                      nastaveniach pracoviska.
                    </div>
                  ),
                })
              }
            : vehicleOwnersData?.length === 0
            ? () => {
                showCustomToast({
                  status: 'warning',
                  title: 'Nie je možné pridať vozidlo',
                  label: (
                    <div>Vo Vašom pracovisku je nutné najprv založiť aspoň jedného zákazníka.</div>
                  ),
                })
              }
            : () =>
                navigate(
                  '/' + RouterEnum.VEHICLE_MANAGEMENT + '/' + RouterEnum.VEHICLE_FORM_CREATE,
                  {
                    state: { mode: 'create' },
                  },
                )
        }
      >
        <Flex w={'8rem'} alignItems={'center'} justifyContent={'space-around'} mb={'1rem'}>
          <Add fontSize={'2rem'} />
          <CarIcon fontSize={'2rem'} />
        </Flex>
        pridať vozidlo
      </Button>

      <Button
        size="large"
        variant="basic"
        w={'90%'}
        bg={colors.blue[100]}
        onClick={
          vehiclesData?.length === 0
            ? () => {
                showCustomToast({
                  status: 'warning',
                  title: 'Nie je možné pridať servis',
                  label: (
                    <div>Vo Vašom pracovisku je nutné najprv založiť aspoň jedno vozidlo.</div>
                  ),
                })
              }
            : () =>
                navigate(
                  '/' + RouterEnum.VEHICLE_SERVIS_OVERVIEW + '/' + RouterEnum.VEHICLE_SERVIS_CREATE,
                )
        }
      >
        <Flex w={'8rem'} alignItems={'center'} justifyContent={'space-around'} mb={'1rem'}>
          <Add fontSize={'2rem'} />
          <Settings fontSize={'2rem'} />
        </Flex>
        pridať servis
      </Button>

      <Menu back={() => navigate(-1)} type={'basic'} />
    </Flex>
  )
}

export default AddMenu
