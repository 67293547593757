import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Grid } from '@chakra-ui/react'
import Filter from '../icons/Filter'
import { colors } from '../theme'
import Close from '../icons/Close'
import FilterForm from '../pages/vehicleManagement/FilterForm'
import { Box } from './Box'
import useIsMobile from '../model/helpers/useIsMobile'
import Menu from './Menu'
import { useNavigate } from 'react-router-dom'

const FilterModal = ({
  isAddFilterOpen,
  onAddFilterClose,
  setFilters,
  filters,
  handleResetFilters,
}: {
  isAddFilterOpen: boolean
  onAddFilterClose: () => void
  setFilters: any
  filters: any
  handleResetFilters: () => void
}) => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  return (
    <Modal
      size={{ sm: 'full', md: 'xl' }}
      isOpen={isAddFilterOpen}
      onClose={onAddFilterClose}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display={'flex'} alignItems={'center'} p={0}>
          {isMobile ? (
            <Grid w={'100%'} placeItems={'center'} h={'4rem'}>
              Filtrovanie
            </Grid>
          ) : (
            <>
              <Filter fontSize={'1.6rem'} color={colors.blue[500]} m={'0 6rem 0 2rem'} /> filtre
              <Close
                fontSize={'1.6rem'}
                bg={colors.red[500]}
                ml={'auto'}
                cursor={'pointer'}
                onClick={() => onAddFilterClose()}
                p={4}
              />
            </>
          )}
        </ModalHeader>

        <ModalBody>
          <FilterForm
            setFilters={setFilters}
            filters={filters}
            closeModal={() => onAddFilterClose()}
            onResetFilters={handleResetFilters}
          />
          {isMobile && <Menu back={() => onAddFilterClose()} type={'basic'} />}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default FilterModal
