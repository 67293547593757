import useIsMobile from '../../../model/helpers/useIsMobile'
import EmployeesOverview from '../EmployeesOverview'
import EmployeesOverviewMobile from '../mobile/EmployeesOverviewMobile'

const EmployeeOverviewWrapper = () => {
  const isMobile = useIsMobile()

  return isMobile ? <EmployeesOverviewMobile /> : <EmployeesOverview />
}
export default EmployeeOverviewWrapper
