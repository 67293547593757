import { Flex, Grid } from '@chakra-ui/react'
import { colors } from '../../theme'
import { Input } from '../../components/Input'
import '../login/login.css'
import Button from '../../components/Button'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { IS_REQUIRED } from '../../model/constants/ValidationMessages'
import getFieldStatus from '../../model/helpers/StatusHelper'
import LogoVivem from '../../assets/logoVivem'
import { Box } from '../../components/Box'
import { useForgottenPassword } from '../../model/api/useForgottenPassword'
import { useState } from 'react'
import { EmailFormSchema } from '../../model/schemas/EmailFormSchema'

const ForgottenPassword = () => {
  const { mutate } = useForgottenPassword()
  const [isSuccess, setIsSuccess] = useState(false)
 

  const initialValues = {
    email: '',
  }

  const handleFormSubmit = (values: Yup.InferType<typeof EmailFormSchema>) => {
    mutate(values.email)
    setIsSuccess(true)
  }

  return (
    <Grid h={'100%'} w={'100%'} placeItems={'center'} className="background">
      <Flex
        w={'20rem'}
        p={5}
        bg={colors.blue[50]}
        alignItems={'center'}
        flexFlow={'column nowrap'}
        justifyContent={'space-between'}
        zIndex={10}
      >
        {!isSuccess ? (
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={EmailFormSchema}
          >
            {({ errors, touched }) => {
              return (
                <Form style={{ width: '90%' }}>
                  <Flex
                    alignItems={'center'}
                    flexFlow={'column nowrap'}
                    justifyContent={'space-between'}
                    gap={'0.8rem'}
                  >
                    <LogoVivem width={'6rem'} />
                    <Field
                      name="email"
                      label="e-mail"
                      type="email"
                      component={Input}
                      status={getFieldStatus(errors, touched, 'email')}
                    />
                    <Box
                      fontSize={'0.8rem'}
                      fontWeight={'semibold'}
                      textAlign={'center'}
                      m={'1rem 0'}
                    >
                      obnovenie hesla Vám príde na zvolenú mailovú adresu
                    </Box>
                    <Button variant={'blue'} size={'full'} type="submit">
                      požiadať o obnovenie hesla
                    </Button>
                  </Flex>
                </Form>
              )
            }}
          </Formik>
        ) : (
          <Box fontSize={'0.8rem'} fontWeight={'semibold'} textAlign={'center'} m={'1rem 0'}>
            žiadosť o obnovenie hesla bola odoslaná, skontrolujte si Vašu e-mailovú schránku
          </Box>
        )}
      </Flex>
    </Grid>
  )
}

export default ForgottenPassword
