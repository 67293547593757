import { useQuery } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useGetAllCars = () => {
  const getAllCars = async (filters = {}) => {
    const response = await api.get(ApiRouteEnum.VEHICLE, { params: filters })
    return response.data
  }

  const { data, isLoading } = useQuery(ApiRouteEnum.VEHICLE, getAllCars)

  return { data, getAllCars, isLoading }
}

export default useGetAllCars
