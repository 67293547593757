import { Flex } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import LogoVivem from '../../../assets/logoVivem'
import { IS_REQUIRED } from '../../../model/constants/ValidationMessages'
import IRegister from '../../../model/interface/IRegister'
import { colors } from '../../../theme'
import { Input } from '../../../components/Input'
import getFieldStatus from '../../../model/helpers/StatusHelper'
import Button from '../../../components/Button'
import { RegisterFormSchema } from '../../../model/schemas/RegisterFormSchema'
import { useCustomToast } from '../../../components/Toast'
import { useRegisterEmailUnique } from '../../../model/api/useRegisterEmailUnique'

const RegisterPageMobile: React.FC<IRegister> = ({
  registerData,
  onSetRegisterData,
  onSetRegisterStep,
}) => {
  const navigate = useNavigate()
  const { showCustomToast } = useCustomToast()
  const initialValues = {
    firstname: registerData.user.firstname,
    lastname: registerData.user.lastname,
    email: registerData.user.email,
    password: registerData.user.password,
    confirmPassword: registerData.user.confirmPassword,
  }
  const successCallback = () => {
    const isEmailAvailable = localStorage.getItem('emailAvailable')
    if (isEmailAvailable === 'true') {
      onSetRegisterStep((prev) => prev + 1)
    } else {
      showCustomToast({
        status: 'error',
        title: 'Email už je zabraný',
        label: 'Email ' + registerData.user.email + ' už je zabraný. Zvoľte prosím iný email.',
      })
    }
  }
  const { mutate: mutateEmailUnique } = useRegisterEmailUnique(successCallback, () => {})

  const handleFormSubmit = (values: Yup.InferType<typeof RegisterFormSchema>) => {
    mutateEmailUnique(values.email)
  }

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target as HTMLInputElement
    onSetRegisterData({
      ...registerData,
      user: {
        ...registerData.user,
        [name]: value,
      },
    })
  }
  return (
    <Flex
      className=""
      w={'100%'}
      h={'100%'}
      p={'5rem'}
      bg={colors.blue[50]}
      alignItems={'center'}
      flexFlow={'column nowrap'}
      justifyContent={'center'}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={RegisterFormSchema}
      >
        {({ errors, touched }) => {
          return (
            <Form style={{ width: '20rem' }}>
              <Flex
                alignItems={'center'}
                flexFlow={'column nowrap'}
                justifyContent={'space-between'}
                gap={'0.8rem'}
                onChange={handleFieldChange}
              >
                <LogoVivem width={'6rem'} />
                <br />
                <Field
                  name="firstname"
                  label="krstné meno"
                  type="text"
                  component={Input}
                  status={getFieldStatus(errors, touched, 'firstname')}
                />
                <Field
                  name="lastname"
                  label="priezvisko"
                  type="text"
                  component={Input}
                  status={getFieldStatus(errors, touched, 'lastname')}
                />
                <Field
                  name="email"
                  label="e-mail"
                  type="email"
                  component={Input}
                  status={getFieldStatus(errors, touched, 'email')}
                />
                <br />

                <Field
                  name="password"
                  label="heslo"
                  type="password"
                  component={Input}
                  status={getFieldStatus(errors, touched, 'password')}
                />
                <Field
                  name="confirmPassword"
                  label="heslo"
                  type="password"
                  component={Input}
                  status={getFieldStatus(errors, touched, 'confirmPassword')}
                />
                <Button variant={'blue'} size={'full'} type="submit" mt={'4rem'}>
                  registrovať
                </Button>
                <Button
                  variant={'transparent'}
                  size={'full'}
                  type="submit"
                  onClick={() => navigate('/login')}
                >
                  Už máte účet? Prihláste sa.
                </Button>
              </Flex>
            </Form>
          )
        }}
      </Formik>
    </Flex>
  )
}

export default RegisterPageMobile
