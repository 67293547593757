import { useMutation } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

export const useAddUser = (successCallback: () => void, errorCallback: () => void) => {
  return useMutation((userData: any) => api.post(`${ApiRouteEnum.USER}`, userData), {
    mutationKey: [ApiRouteEnum.USER],
    onSuccess: () => {
      successCallback()
    },
    onError: () => {
      errorCallback()
    },
  })
}
