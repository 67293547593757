import { useState } from 'react'
import RegisterInitialValues, {
  RegisterFormData,
} from '../../model/constants/RegisterInitialValues'
import RegisterPage from './RegisterPage'
import RegisterStep1 from './RegisterStep1'
import RegisterStep2 from './RegisterStep2'
import RegisterComplete from './RegisterComplete'
import useIsMobile from '../../model/helpers/useIsMobile'
import RegisterPageMobile from './mobile/RegisterPageMobile'
import RegisterStep1Mobile from './mobile/RegisterStep1Mobile'
import RegisterStep2Mobile from './mobile/RegisterStep2Mobile'

const RegisterParent = () => {
  const [registerStep, setRegisterStep] = useState<number>(0)
  const [registerData, setRegisterData] = useState<RegisterFormData>(RegisterInitialValues)
  const isMobile = useIsMobile()
  if (registerStep === 1) {
    if (isMobile) {
      return (
        <RegisterStep1Mobile
          registerData={registerData}
          onSetRegisterData={setRegisterData}
          onSetRegisterStep={setRegisterStep}
        />
      )
    }

    return (
      <RegisterStep1
        registerData={registerData}
        onSetRegisterData={setRegisterData}
        onSetRegisterStep={setRegisterStep}
      />
    )
  }
  if (registerStep === 2) {
    if (isMobile) {
      return (
        <RegisterStep2Mobile
          registerData={registerData}
          onSetRegisterData={setRegisterData}
          onSetRegisterStep={setRegisterStep}
        />
      )
    }
    return (
      <RegisterStep2
        registerData={registerData}
        onSetRegisterData={setRegisterData}
        onSetRegisterStep={setRegisterStep}
      />
    )
  }
  if (registerStep === 3) {
    return (
      <RegisterComplete
        registerData={registerData}
        onSetRegisterData={setRegisterData}
        onSetRegisterStep={setRegisterStep}
      />
    )
  }
  if (isMobile) {
    return (
      <RegisterPageMobile
        registerData={registerData}
        onSetRegisterData={setRegisterData}
        onSetRegisterStep={setRegisterStep}
      />
    )
  }
  return (
    <RegisterPage
      registerData={registerData}
      onSetRegisterData={setRegisterData}
      onSetRegisterStep={setRegisterStep}
    />
  )
}

export default RegisterParent
