import useIsMobile from '../../../model/helpers/useIsMobile'
import ServiceFormMaster from '../ServiceFormMaster'
import ServiceFormMasterMobile from '../mobile/ServiceFormMasterMobile'

const ServiceFormMasterWrapper = () => {
  const isMobile = useIsMobile()

  return isMobile ? <ServiceFormMasterMobile /> : <ServiceFormMaster />
}
export default ServiceFormMasterWrapper
