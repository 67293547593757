import { StatusEnum } from '../enums/StatusEnum'

const getFieldStatus = (
  errors: any,
  touched: any,
  fieldName: string,
  optional = false,
  values?: any,
): StatusEnum => {
  // Split the field name into parts using dots and square brackets
  const fieldParts = fieldName.split(/[.[\]]/).filter(Boolean)

  // Traverse the errors and touched objects using fieldParts
  let currentErrors = errors
  let currentTouched = touched
  for (const part of fieldParts) {
    currentErrors = currentErrors && currentErrors[part]
    currentTouched = currentTouched && currentTouched[part]
  }

  // Check if the field has a value
  const fieldValue = values && fieldParts.reduce((obj, part) => obj && obj[part], values)

  if (currentErrors && currentTouched) {
    return StatusEnum.Error
  } else if (optional) {
    if (currentTouched && fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
      return StatusEnum.Success
    } else {
      return StatusEnum.Default
    }
  } else if (!optional) {
    if (currentTouched) {
      return StatusEnum.Success
    }
    return StatusEnum.Info
  } else {
    return StatusEnum.Info
  }
}

export default getFieldStatus
