import { colors } from '../theme'
const StatusIndicator = ({ status, icon, h }: { status: string; icon?: any; h?: string }) => {
  const statusColor = (status: string) => {
    switch (status) {
      default:
        return colors.gray[700]
      case 'info':
        return colors.blue[500]
      case 'error':
        return colors.red[500]
      case 'success':
        return colors.green[500]
      case 'warning':
        return colors.orange[500]
    }
  }

  return (
    <div
      style={{
        height: h ? h : '100%',
        minWidth: '0.4rem',
        background: statusColor(status),
        marginLeft: '3px',
        marginRight: '3px',
        boxSizing: 'border-box',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      {icon && icon}
    </div>
  )
}

export default StatusIndicator
