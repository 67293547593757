import * as Yup from 'yup'
import { IS_EMAIL, IS_PHONE, IS_REQUIRED } from '../constants/ValidationMessages'
export const customerFormSchema = Yup.object({
  firstname: Yup.string().required(IS_REQUIRED),
  lastname: Yup.string().required(IS_REQUIRED),
  email: Yup.string()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, IS_EMAIL)
    .nullable(),
  phone: Yup.string()
    .transform((value) => {
      return value ? value.replace(/\s/g, '') : value
    })
    .matches(/^((00|\+)42[0-1])?\d{9}$/, IS_PHONE)
    .nullable(),
  birthdate: Yup.date().nullable(),
  notes: Yup.array(Yup.object({ content: Yup.string() })),
})
