import { Button, Flex, Grid } from '@chakra-ui/react'
import { Box } from '../../components/Box'
import { colors } from '../../theme'
import { useNavigate } from 'react-router-dom'
import RouterEnum from '../../model/enums/RouterEnum'
import LogoVivem from '../../assets/logoVivem'

const RegisterAfterInviteComplete = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const serviceName = queryParams.get('workspace_name')
  const navigate = useNavigate()
  return (
    <Grid h={'100%'} w={'100%'} placeItems={'center'} className="background">
      <Flex
        w={'20rem'}
        p={5}
        bg={colors.blue[50]}
        alignItems={'center'}
        flexFlow={'column nowrap'}
        justifyContent={'space-between'}
        zIndex={10}
      >
        <Flex
          alignItems={'center'}
          flexFlow={'column nowrap'}
          justifyContent={'space-between'}
          gap={'0.5rem'}
        >
          <LogoVivem width={'6rem'} />

          <Box fontSize={'0.8rem'} fontWeight={'semibold'} textAlign={'center'} m={'1rem 0'}>
            Vitajte v service {serviceName}
          </Box>
          <Box fontSize={'0.8rem'} fontWeight={'semibold'} textAlign={'center'} m={'1rem 0'}>
            ďakujeme za Vašu registráciu
            <br />
            <br />
            Team ViVeM
          </Box>
          <Button size="full" variant={'blue'} onClick={() => navigate(RouterEnum.LOGIN)}>
            dokončiť
          </Button>
        </Flex>
      </Flex>
    </Grid>
  )
}

export default RegisterAfterInviteComplete
