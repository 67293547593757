import { Flex } from '@chakra-ui/react'
import { colors } from '../../theme'
import { Input } from '../../components/Input'
import Button from '../../components/Button'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { IS_REQUIRED } from '../../model/constants/ValidationMessages'
import getFieldStatus from '../../model/helpers/StatusHelper'
import LogoVivem from '../../assets/logoVivem'
import { useState } from 'react'
import { useForgottenPassword } from '../../model/api/useForgottenPassword'
import { Box } from '../../components/Box'
import { EmailFormSchema } from '../../model/schemas/EmailFormSchema'

const ForgottenPasswordMobile = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const { mutate } = useForgottenPassword()

  const initialValues = {
    email: '',
  }

  const handleFormSubmit = (values: Yup.InferType<typeof EmailFormSchema>) => {
    mutate(values.email)
    setIsSuccess(true)
  }

  return (
    <Flex
      w={'100%'}
      h={'100%'}
      bg={colors.blue[50]}
      alignItems={'center'}
      flexFlow={'column nowrap'}
      justifyContent={'space-between'}
      zIndex={10}
      p={'1rem'}
    >
      <LogoVivem width={'6rem'} style={{ marginTop: '4rem' }} />
      {!isSuccess ? (
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={EmailFormSchema}
        >
          {({ errors, touched, values }) => {
            return (
              <Form style={{ width: '90%' }}>
                <Flex
                  alignItems={'center'}
                  flexFlow={'column nowrap'}
                  justifyContent={'space-between'}
                  gap={'1rem'}
                >
                  <Field
                    name="email"
                    label="e-mail"
                    type="email"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'email')}
                  />
                  <Box w={'80%'} textAlign={'center'} fontWeight={'semibold'}>
                    obnovenie hesla Vám príde na zvolenú emailovú adresu
                  </Box>
                  <Button variant={'blue'} size={'full'} type="submit" m={'10rem 0 3rem 0'}>
                    požiadať o obnovenie hesla
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>
      ) : (
        <Box w={'80%'} fontWeight={'semibold'} textAlign={'center'} m={'auto'}>
          žiadosť o obnovenie hesla bola odoslaná, skontrolujte si Vašu e-mailovú schránku
        </Box>
      )}
    </Flex>
  )
}

export default ForgottenPasswordMobile
