import { Flex, Grid } from '@chakra-ui/react'
import { colors } from '../../theme'
import { Input } from '../../components/Input'
import './login.css'
import Button from '../../components/Button'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { IS_REQUIRED } from '../../model/constants/ValidationMessages'
import { useNavigate } from 'react-router-dom'
import getFieldStatus from '../../model/helpers/StatusHelper'
import { useLoginUser } from '../../model/api/useLoginUser'
import LogoVivem from '../../assets/logoVivem'
import { useRef } from 'react'
import RouterEnum from '../../model/enums/RouterEnum'
import { useCustomToast } from '../../components/Toast'
import { LoginFormSchema } from '../../model/schemas/LoginFormSchema'

const LoginPage = () => {
  const navigate = useNavigate()
  const successCallback = () => {
    navigate(RouterEnum.HOMEPAGE)
  }
  const errorCallback = () => {
    showCustomToast({
      status: 'error',
      title: 'Nesprávne prihlasovacie údaje',
      label: 'Skontrolujte prihlasovacie údaje a skúste to znova',
    })
  }
  const { mutate: loginMutation } = useLoginUser(() => successCallback(), errorCallback)
  const passwordRef = useRef<HTMLInputElement | null>(null)
  const { showCustomToast } = useCustomToast()

  const initialValues = {
    email: '',
    password: '',
  }

  const handleFormSubmit = (values: Yup.InferType<typeof LoginFormSchema>) => {
    loginMutation({ username: values.email, password: values.password })
  }

  const handleEnterPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    values: Yup.InferType<typeof LoginFormSchema>,
  ) => {
    if (e.key === 'Enter') {
      if (e.currentTarget.name === 'email') {
        passwordRef.current?.focus()
      } else {
        handleFormSubmit(values)
      }
    }
  }

  return (
    <Grid h={'100%'} w={'100%'} placeItems={'center'} className="background">
      <Flex
        w={'20rem'}
        p={5}
        bg={colors.blue[50]}
        alignItems={'center'}
        flexFlow={'column nowrap'}
        justifyContent={'space-between'}
        zIndex={10}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={LoginFormSchema}
        >
          {({ errors, touched, values }) => {
            return (
              <Form style={{ width: '90%' }}>
                <Flex
                  alignItems={'center'}
                  flexFlow={'column nowrap'}
                  justifyContent={'space-between'}
                  gap={'0.8rem'}
                >
                  <LogoVivem width={'6rem'} />
                  <Field
                    name="email"
                    label="e-mail"
                    type="email"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'email')}
                    onKeyPress={(e: any) => handleEnterPress(e, values)}
                  />

                  <Field
                    name="password"
                    label="heslo"
                    type="password"
                    innerRef={passwordRef}
                    component={Input}
                    status={getFieldStatus(errors, touched, 'password')}
                    onKeyPress={(e: any) => handleEnterPress(e, values)}
                  />

                  <Button variant={'blue'} size={'full'} type="submit">
                    prihlásiť sa
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>
        <Button variant={'transparent'} size={'full'} onClick={() => navigate(RouterEnum.REGISTER)}>
          registrovať sa
        </Button>
        <Button
          variant={'transparent'}
          size={'full'}
          onClick={() => navigate(RouterEnum.FORGOTTEN_PASSWORD)}
        >
          zabudol som heslo
        </Button>
      </Flex>
    </Grid>
  )
}

export default LoginPage
