import { Flex, Grid } from '@chakra-ui/react'
import { colors } from '../../theme'
import { Input } from '../../components/Input'
import '../login/login.css'
import Button from '../../components/Button'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { IS_REQUIRED } from '../../model/constants/ValidationMessages'
import getFieldStatus from '../../model/helpers/StatusHelper'
import LogoVivem from '../../assets/logoVivem'
import { Box } from '../../components/Box'
import { useState } from 'react'
import Save from '../../icons/Save'
import { useForgottenPasswordChange } from '../../model/api/useForgottenPasswordChange'
import { useNavigate } from 'react-router-dom'
import RouterEnum from '../../model/enums/RouterEnum'
import { ForgottenPasswordSchema } from '../../model/schemas/ForgottenPasswordSchema'

const ForgottenPasswordChange = () => {
  const navigate = useNavigate()
  const [isSuccess, setIsSuccess] = useState(false)
  const { mutate: changePassword } = useForgottenPasswordChange()
  const queryParams = new URLSearchParams(window.location.search)
  const userLink = queryParams.get('user_link')

  if (!userLink) {
    return null
  }

  const initialValues = {
    password: '',
    confirmPassword: '',
  }

  const handleFormSubmit = (values: Yup.InferType<typeof ForgottenPasswordSchema>) => {
    changePassword({
      userLink: userLink,
      password: values.password,
    })
    setIsSuccess(true)
    setTimeout(() => {
      navigate(RouterEnum.LOGIN)
    }, 5000)
  }

  return (
    <Grid h={'100%'} w={'100%'} placeItems={'center'} className="background">
      <Flex
        w={'20rem'}
        p={5}
        bg={colors.blue[50]}
        alignItems={'center'}
        flexFlow={'column nowrap'}
        justifyContent={'space-between'}
        zIndex={10}
      >
        {!isSuccess ? (
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={ForgottenPasswordSchema}
          >
            {({ errors, touched }) => {
              return (
                <Form style={{ width: '90%' }}>
                  <Flex
                    alignItems={'center'}
                    flexFlow={'column nowrap'}
                    justifyContent={'space-between'}
                    gap={'0.8rem'}
                  >
                    <LogoVivem width={'6rem'} />
                    <Field
                      name="password"
                      label="nové heslo"
                      type="password"
                      component={Input}
                      status={getFieldStatus(errors, touched, 'password')}
                    />
                    <Field
                      name="confirmPassword"
                      label="zopakovať heslo"
                      type="password"
                      component={Input}
                      status={getFieldStatus(errors, touched, 'confirmPassword')}
                    />

                    <Button variant={'green'} size={'full'} type="submit">
                      <Save />
                    </Button>
                  </Flex>
                </Form>
              )
            }}
          </Formik>
        ) : (
          <Box fontSize={'0.8rem'} fontWeight={'semibold'} textAlign={'center'} m={'1rem 0'}>
            vaše heslo bolo úspešne zmenené, budete presmerovaný na prihlásenie do 5 sekúnd
          </Box>
        )}
      </Flex>
    </Grid>
  )
}

export default ForgottenPasswordChange
