import { Divider, Flex, Grid, useDisclosure } from '@chakra-ui/react'
import DownChevronIcon from '../icons/DownChevronIcon'
import { colors } from '../theme'
import { DisabledInput } from './DisabledInput'
import Calendar from '../icons/Calendar'
import Button from './Button'
import { Add } from '../icons/Add'
import { format, set } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Field } from 'formik'
import { Input } from './Input'
import getFieldStatus from '../model/helpers/StatusHelper'
import Delete from '../icons/Delete'

const HighwaySignsDropdown = ({
  highwaySigns,
  mode,
  setFieldValue,
  errors,
  touched,
  values,
}: {
  highwaySigns?: any
  mode: string
  setFieldValue?: any
  errors?: any
  touched?: any
  values?: any
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isHistoryOpen, onOpen: onHistoryOpen, onClose: onHistoryClose } = useDisclosure()
  const [history, setHistory] = useState<boolean>(false)
  const [count, setCount] = useState<string[]>([])
  const handleOpenAndClose = () => {
    if (isOpen) {
      onClose()
    }
    if (!isOpen) {
      onOpen()
    }
  }
  const handleOpenAndCloseHistory = () => {
    if (isHistoryOpen) {
      onHistoryClose()
    }
    if (!isHistoryOpen) {
      onHistoryOpen()
    }
  }
  useEffect(() => {
    if (highwaySigns) {
      for (let i = 0; i < highwaySigns.length; i++) {
        if (highwaySigns[i].isValid === false) {
          setHistory(true)
        }
      }
    }
  }, [highwaySigns])

  return (
    <>
      <Flex
        w={'100%'}
        h={'3rem'}
        bg={colors.gray[700]}
        color={colors.blue[100]}
        justifyContent={'space-between'}
        onClick={handleOpenAndClose}
        cursor={'pointer'}
      >
        <Grid placeItems={'center'} w={'80%'} fontSize={'0.8rem'} fontWeight={'semibold'}>
          diaľničné známky
        </Grid>
        <Grid placeItems={'center'} w={'20%'}>
          <DownChevronIcon fontSize={'1rem'} transform={isOpen && 'rotate(180deg)'} />
        </Grid>
      </Flex>
      {isOpen && (
        <Flex flexFlow={'column'} gap={'0.8rem'} w={{ sm: '100%', md: 'initial' }}>
          {mode === 'edit' &&
            highwaySigns &&
            highwaySigns.map((item: any, index: number) => {
              if (item.isValid) {
                return (
                  <React.Fragment key={`highwaySigns${index}`}>
                    <Field
                      bg={colors.blue[100]}
                      name={`highwaySigns[${index}].name`}
                      label={'názov'}
                      component={Input}
                      status={getFieldStatus(errors, touched, `highwaySigns[${index}].name`)}
                    />
                    <Field
                      bg={colors.blue[100]}
                      name={`highwaySigns[${index}].validFrom`}
                      label={'platnosť od'}
                      type="date"
                      setFieldValue={setFieldValue}
                      component={Input}
                      status={getFieldStatus(errors, touched, `highwaySigns[${index}].validFrom`)}
                    />
                    <Field
                      bg={colors.blue[100]}
                      name={`highwaySigns[${index}].validTo`}
                      label={'platnosť do'}
                      type="date"
                      setFieldValue={setFieldValue}
                      component={Input}
                      status={getFieldStatus(errors, touched, `highwaySigns[${index}].validTo`)}
                    />
                    <Divider
                      borderWidth={'0.2rem 0'}
                      borderColor={colors.gray[700]}
                      bg={colors.gray[700]}
                    />
                  </React.Fragment>
                )
              }
            })}
          {mode === 'edit' && history && (
            <>
              <Flex
                w={'100%'}
                h={'3rem'}
                bg={colors.gray[400]}
                color={colors.blue[100]}
                justifyContent={'space-between'}
                onClick={handleOpenAndCloseHistory}
                cursor={'pointer'}
              >
                <Grid
                  textAlign={'center'}
                  placeItems={'center'}
                  w={'80%'}
                  fontSize={'0.8rem'}
                  fontWeight={'semibold'}
                >
                  historia diaľničných známkok
                </Grid>
                <Grid placeItems={'center'} w={'20%'}>
                  <DownChevronIcon
                    fontSize={'1rem'}
                    transform={isHistoryOpen && 'rotate(180deg)'}
                  />
                </Grid>
              </Flex>
              {highwaySigns &&
                isHistoryOpen &&
                highwaySigns.map((item: any, index: number) => {
                  if (!item.isValid) {
                    return (
                      <React.Fragment key={`highwaySigns${index}`}>
                        <Field
                          bg={colors.blue[100]}
                          name={`highwaySigns[${index}].name`}
                          label={'názov'}
                          component={Input}
                          status={getFieldStatus(errors, touched, `highwaySigns[${index}].name`)}
                        />
                        <Field
                          bg={colors.blue[100]}
                          name={`highwaySigns[${index}].validFrom`}
                          label={'platnosť od'}
                          type="date"
                          setFieldValue={setFieldValue}
                          component={Input}
                          status={getFieldStatus(
                            errors,
                            touched,
                            `highwaySigns[${index}].validFrom`,
                          )}
                        />
                        <Field
                          bg={colors.blue[100]}
                          name={`highwaySigns[${index}].validTo`}
                          label={'platnosť do'}
                          type="date"
                          setFieldValue={setFieldValue}
                          component={Input}
                          status={getFieldStatus(errors, touched, `highwaySigns[${index}].validTo`)}
                        />
                        <Divider
                          borderWidth={'0.2rem 0'}
                          borderColor={colors.gray[700]}
                          bg={colors.gray[700]}
                        />
                      </React.Fragment>
                    )
                  }
                })}
            </>
          )}

          {count.map((item: any, index) => {
            return (
              <React.Fragment key={`newHighwaySigns${item.randomId}`}>
                <Field
                  bg={colors.blue[100]}
                  name={`highwaySigns[${index + highwaySigns.length}].name`}
                  label={'názov'}
                  component={Input}
                  status={getFieldStatus(
                    errors,
                    touched,
                    `highwaySigns[${index + highwaySigns.length}].name`,
                  )}
                />
                <Field
                  bg={colors.blue[100]}
                  name={`highwaySigns[${index + highwaySigns.length}].validFrom`}
                  label={'platnosť od'}
                  type="date"
                  setFieldValue={setFieldValue}
                  component={Input}
                  status={getFieldStatus(
                    errors,
                    touched,
                    `highwaySigns[${index + highwaySigns.length}].validFrom`,
                  )}
                />
                <Field
                  bg={colors.blue[100]}
                  name={`highwaySigns[${index + highwaySigns.length}].validTo`}
                  label={'platnosť do'}
                  type="date"
                  setFieldValue={setFieldValue}
                  component={Input}
                  status={getFieldStatus(
                    errors,
                    touched,
                    `highwaySigns[${index + highwaySigns.length}].validTo`,
                  )}
                />
                <Button
                  variant={'red'}
                  size={'full'}
                  onClick={() => {
                    setCount((prev) => prev.filter((item, i) => i !== index))
                    const updatedHighwaySigns = [...values[`highwaySigns`]]
                    updatedHighwaySigns.splice(index + highwaySigns.length, 1)
                    setFieldValue(`highwaySigns`, updatedHighwaySigns)
                  }}
                >
                  <Delete />
                </Button>
                {index !== count.length - 1 && (
                  <Divider
                    borderWidth={'0.2rem 0'}
                    borderColor={colors.gray[700]}
                    bg={colors.gray[700]}
                  />
                )}
              </React.Fragment>
            )
          })}

          {mode === 'viewOnly' &&
            highwaySigns &&
            highwaySigns.map((item: any) => {
              if (item.isValid) {
                return (
                  <>
                    <DisabledInput label={'názov'} defaultValue={item.name} />
                    <Grid templateColumns={'repeat(2, 1fr)'} gap={3}>
                      <Flex
                        h={'3rem'}
                        bg={colors.blue[100]}
                        justifyContent={'space-around'}
                        alignItems={'center'}
                        fontSize={'0.8rem'}
                        fontWeight={'semibold'}
                      >
                        {item.validFrom && (
                          <>
                            <Calendar fontSize={'1rem'} color={colors.blue[500]} />
                            {format(new Date(item.validFrom), 'dd.MM.yyyy')}
                          </>
                        )}
                      </Flex>
                      <Flex
                        h={'3rem'}
                        bg={colors.blue[100]}
                        justifyContent={'space-around'}
                        alignItems={'center'}
                        fontSize={'0.8rem'}
                        fontWeight={'semibold'}
                      >
                        {item.validTo && (
                          <>
                            <Calendar fontSize={'1rem'} color={colors.blue[500]} />
                            {format(new Date(item.validTo), 'dd.MM.yyyy')}
                          </>
                        )}
                      </Flex>
                    </Grid>
                    <Divider
                      borderWidth={'0.2rem 0'}
                      borderColor={colors.gray[700]}
                      bg={colors.gray[700]}
                    />
                  </>
                )
              }
            })}
          {mode === 'viewOnly' && history && (
            <>
              <Flex
                w={'100%'}
                h={'3rem'}
                bg={colors.gray[400]}
                color={colors.blue[100]}
                justifyContent={'space-between'}
                onClick={handleOpenAndCloseHistory}
                cursor={'pointer'}
              >
                <Grid
                  textAlign={'center'}
                  placeItems={'center'}
                  w={'80%'}
                  fontSize={'0.8rem'}
                  fontWeight={'semibold'}
                >
                  historia diaľničných známkok
                </Grid>
                <Grid placeItems={'center'} w={'20%'}>
                  <DownChevronIcon
                    fontSize={'1rem'}
                    transform={isHistoryOpen && 'rotate(180deg)'}
                  />
                </Grid>
              </Flex>
              {isHistoryOpen &&
                highwaySigns &&
                highwaySigns.map((item: any) => {
                  if (!item.isValid) {
                    return (
                      <>
                        <DisabledInput label={'názov'} defaultValue={item.name} />
                        <Grid templateColumns={'repeat(2, 1fr)'} gap={3}>
                          <Flex
                            h={'3rem'}
                            bg={colors.blue[100]}
                            justifyContent={'space-around'}
                            alignItems={'center'}
                            fontSize={'0.8rem'}
                            fontWeight={'semibold'}
                          >
                            {item.validFrom && (
                              <>
                                <Calendar fontSize={'1rem'} color={colors.blue[500]} />
                                {format(new Date(item.validFrom), 'dd.MM.yyyy')}
                              </>
                            )}
                          </Flex>
                          <Flex
                            h={'3rem'}
                            bg={colors.blue[100]}
                            justifyContent={'space-around'}
                            alignItems={'center'}
                            fontSize={'0.8rem'}
                            fontWeight={'semibold'}
                          >
                            {item.validTo && (
                              <>
                                <Calendar fontSize={'1rem'} color={colors.blue[500]} />
                                {format(new Date(item.validTo), 'dd.MM.yyyy')}
                              </>
                            )}
                          </Flex>
                        </Grid>
                        <Divider
                          borderWidth={'0.2rem 0'}
                          borderColor={colors.gray[700]}
                          bg={colors.gray[700]}
                        />
                      </>
                    )
                  }
                })}
            </>
          )}

          {mode !== 'viewOnly' && (
            <Button
              size={'full'}
              variant={'blue'}
              // onClick={() => setCount((prev) => [...prev, 'new'])}
              onClick={() => {
                setCount((prev) => [...prev, 'new'])
                setFieldValue(highwaySigns, [
                  ...highwaySigns,
                  {
                    name: '',
                    validFrom: '',
                    validTo: '',
                    randomId: Math.floor(Math.random() * 100),
                  },
                ])
              }}
            >
              <Add fontSize={'1rem'} /> pridať diaľničnú známku
            </Button>
          )}
        </Flex>
      )}
    </>
  )
}

export default HighwaySignsDropdown
