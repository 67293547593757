import { useEffect, useState } from 'react'

const useIsMobile = () => {
  const [isMobileBool, setIsMobileBool] = useState<boolean>(window.innerWidth <= 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobileBool(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isMobileBool
}

export default useIsMobile
