import { useMutation } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'
import { RegisterFormData } from '../constants/RegisterInitialValues'

export const useRegisterEmailUnique = (successCallback: () => void, errorCallback: () => void) => {
    return useMutation(
        (email: string) =>
            api.post(`${ApiRouteEnum.EMAIL_AVAILABLE}`, {
                email: email,
            }),
        {
            mutationKey: [ApiRouteEnum.EMAIL_AVAILABLE],
            onSuccess: (data) => {
                localStorage.setItem('emailAvailable', data.data.available)
                successCallback()
            },
            onError: () => {
                errorCallback()
            },
        },
    )
}
