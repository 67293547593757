import { useMutation, useQueryClient } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useUpdateVehicleInsurance = () => {
  const query = useQueryClient()
  return useMutation(
    ({ updateInsuranceData, id }: { updateInsuranceData: any; id: string }) =>
      api.put(`${ApiRouteEnum.VEHICLE_INSURANCE}/${id}`, updateInsuranceData),
    {
      mutationKey: [ApiRouteEnum.VEHICLE_INSURANCE],
      onSuccess: () => {
        query.invalidateQueries(ApiRouteEnum.VEHICLE_INSURANCE)
      },
    },
  )
}

export default useUpdateVehicleInsurance
