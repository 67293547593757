import { useMutation, useQueryClient } from 'react-query'
import ApiRouteEnum from '../enums/ApiRouteEnum'
import api from './api'

export const useDeleteMOT = () => {
  const query = useQueryClient()
  return useMutation((id) => api.delete(`${ApiRouteEnum.VEHICLE_MOT}/${id}`), {
    onSuccess: (data) => {
      query.invalidateQueries(ApiRouteEnum.VEHICLE_MOT)
      query.invalidateQueries(ApiRouteEnum.VEHICLE)
    },
  })
}
