import { Box, Input as ChakraInput, Flex, FormLabel } from '@chakra-ui/react'

export const DisabledInput = ({ defaultValue, label, type, ...rest }: any) => {
  return (
    <Box w={'100%'} {...rest}>
      <Flex h={'3rem'} flexFlow={'row nowrap'} alignItems={'center'}>
        <FormLabel w={"30%"}>{label}</FormLabel>
        <ChakraInput
          fontSize={'0.8rem'}
          w={"70%"}
          value={defaultValue}
          p={2}
          isReadOnly
        />
      </Flex>
    </Box>
  )
}
