import useIsMobile from '../../model/helpers/useIsMobile'
import ForgottenPassword from './ForgottenPassword'
import ForgottenPasswordMobile from './ForgottenPasswordMobile'

const ForgottenPasswordWrapper = () => {
  const isMobile = useIsMobile()

  return isMobile ? <ForgottenPasswordMobile /> : <ForgottenPassword />
}
export default ForgottenPasswordWrapper
