import { useMutation, useQueryClient } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

export const useAddInsurance = () => {
  const query = useQueryClient()
  return useMutation((insuranceData: any) => api.post(`${ApiRouteEnum.VEHICLE_INSURANCE}`, insuranceData), {
    mutationKey: [ApiRouteEnum.VEHICLE_INSURANCE],
    onSuccess: () => {
      query.invalidateQueries(ApiRouteEnum.VEHICLE_INSURANCE)
    }
  })
}
