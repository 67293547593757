import { extendTheme } from '@chakra-ui/react'

export const colors = {
  green: {
    '50': '#E9FBEC',
    '100': '#C2F4CA',
    '200': '#9BEDA8',
    '300': '#75E685',
    '400': '#4EDF63',
    '500': '#3BDC53',
    '600': '#1FAD34',
    '700': '#178227',
    '800': '#0F571A',
    '900': '#082B0D',
  },
  orange: {
    '50': '#FFF8E5',
    '100': '#FFEAB8',
    '200': '#FFDD8A',
    '300': '#FFCF5C',
    '400': '#FFDC22',
    '500': '#FFBE22',
    '600': '#CC9000',
    '700': '#996C00',
    '800': '#664800',
    '900': '#332400',
  },
  red: {
    '50': '#FBE9E9',
    '100': '#F4C2C2',
    '200': '#ED9B9B',
    '300': '#E67575',
    '400': '#DF4E4E',
    '500': '#DC3B3B',
    '600': '#AD1F1F',
    '700': '#821717',
    '800': '#570F0F',
    '900': '#2B0808',
  },
  gray: {
    '50': '#EFF1F6',
    '100': '#D2D9E5',
    '200': '#B5C1D4',
    '300': '#98A8C3',
    '400': '#214B57',
    '500': '#5E77A1',
    '600': '#4B5F81',
    '700': '#242D3D',
    '800': '#263040',
    '900': '#131820',
  },
  blue: {
    '50': '#DDF2F8',
    '100': '#F9FBFD',
    '200': '#9EDAEB',
    '300': '#78CBE3',
    '400': '#52BDDB',
    '500': '#3BBDDC',
    '600': '#238BA9',
    '700': '#1A687F',
    '800': '#114655',
    '900': '#09232A',
  },
}

const theme = extendTheme({
  colors,
  breakpoints: {
    sm: '0',
    md: '900px',
  },
  styles: {
    global: {
      'html, body': {
        fontSize: ['1vw', '2vh'],
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: '0',
        color: 'gray.50',
        p: '20px',
        display: 'flex',
        justifyContent: 'space-around',
        h: '3rem',
        fontSize: '0.8rem',
      },

      sizes: {
        full: {
          width: '100%',
          fontWeight: 'semibold',
        },
        half: {
          width: '40%',
        },
        third: {
          width: '5rem',
        },
        medium: {
          fontSize: '1rem',
          width: '85%',
          height: '5rem',
          fontWeight: 'semibold',
        },
        large: {
          fontSize: '1rem',
          width: '100%',
          flexFlow: 'column wrap',
          justifyContent: 'center',
          h: '10rem',
          m: '0',
        },
      },

      variants: {
        dark: {
          bg: 'gray.700',
        },
        green: {
          bg: 'green.500',
        },
        blue: {
          bg: 'blue.500',
        },
        red: {
          bg: 'red.500',
        },
        transparent: {
          color: 'blue.500',
          textDecoration: 'underline',
        },
        basic: {
          bg: 'blue.100',
          color: 'gray.700',
        },
      },

      defaultProps: {
        size: 'full',
        variant: 'solid',
        bg: 'gray.700',
      },
    },
    IconButton: {
      baseStyle: {
        borderRadius: '0',
        color: 'gray.50',
        m: '10px 0',
      },

      sizes: {
        full: {
          width: '100%',
          height: '5rem',
        },
        half: {
          width: '1rem',
          height: '3rem',
        },
        third: {
          width: '6rem',
          height: '5rem',
        },
      },

      variants: {
        basic: {
          bg: 'gray.700',
        },
        green: {
          bg: 'green.500',
        },
        blue: {
          bg: 'blue.500',
        },
        red: {
          bg: 'red.500',
        },
      },

      defaultProps: {
        size: 'full',
        variant: 'solid',
        bg: 'gray.700',
      },
    },
    Switch: {
      baseStyle: {
        label: {
          display: 'block',
          width: '0',
        },
        container: {
          borderRadius: '0',
        },
        thumb: {
          borderRadius: '0',
        },
        track: {
          borderRadius: '0',
          bg: 'gray.700',
          _checked: {
            bg: 'blue.500',
          },
        },
      },
      variants: {
        alwaysOn: {
          thumb: {
            w: '100%',
            m: '0',
            transform: 'translateX(0) !important',
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        bg: 'gray.700',
        color: 'gray.50',
        m: '0',
        h: '100%',
        display: 'grid',
        placeItems: 'center',
        textAlign: 'center',
        fontSize: '0.6rem',
        fontWeight: 'semibold',
        p: '0.2rem',
      },
      variants: {
        textarea: {
          fontSize: '0.8rem',
          w: '100%',
          p: '5px',
          textAlign: 'center',
          h: '3rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          borderRadius: '0',
          h: '100%',
          textAlign: 'center',
          fontSize: '0.8rem',
          fontWeight: 'semibold',
        },
      },
    },
    Textarea: {
      baseStyle: {
        minHeight: '8rem',
        borderRadius: '0',
        fontSize: '0.8rem',
      },
    },
    Form: {
      baseStyle: {
        helperText: {
          bg: 'gray.700',
          color: 'gray.50',
          p: '14px',
          m: '0',
          h: '3rem',
          fontSize: '0.6rem',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        },
      },
    },
    Stepper: {
      baseStyle: {
        indicator: {
          borderRadius: '0',
          h: '1rem',
          w: '1rem',
          border: '1px solid blue.100',
          zIndex: '1',
        },
        separator: {
          position: 'absolute',
          m: "0",
          zIndex: '0',
          h: '7px !important',
        }
      },
    },
    Slider: {
      baseStyle: {
        thumb: {
          borderRadius: '0',
          boxShadow: '0 0 0 1px gray',
          h: '30px',
          w: '30px',
        },
        filledTrack: {
          bg: 'blue.500',
        },
        track: {
          h: '15px',
        },
      },
    },
    Modal: {
      baseStyle: {
        header: {
          bg: 'gray.700',
          color: 'gray.50',
          fontSize: '1rem',
        },
        dialog: {
          bg: 'blue.50',
        },
        closeButton: {
          color: 'gray.50',
        },
        body: {
          textAlign: 'center',
        },
      },
      variants: {
        confirm: {
          header: {
            bg: 'transparent',
            color: 'gray.700',
            textAlign: 'center',
          },
        },
      },
    },
    Tag: {
      variants: {
        dark: {
          container: {
            bg: 'gray.700',
            color: 'gray.50',
          },
          closeButton: {
            fill: 'gray.50',
          },
        },
        add: {
          container: {
            bg: 'blue.500',
            color: 'gray.50',
          },
        },
      },
    },
    Icon: {
      baseStyle: {
        fontSize: '2em',
      },
    },
    Progress: {
      baseStyle: {
        track: {
          borderRadius: '15px',
          bg: 'gray.700',
        },
        filledTrack: {
          borderRadius: '15px',
        },
      },
      variants: {
        green: {
          filledTrack: {
            bg: 'green.500',
          },
        },
        orange: {
          filledTrack: {
            bg: 'orange.400',
          },
        },
        red: {
          filledTrack: {
            bg: 'red.500',
          },
        },
      },
    },
  },
})

export default theme
