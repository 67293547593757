import * as Yup from 'yup'
import { IS_REQUIRED } from '../../../model/constants/ValidationMessages'
import { useQueryClient } from 'react-query'
import { useEffect, useRef, useState } from 'react'
import useGetAllCars from '../../../model/api/useGetAllCars'
import { useAddService } from '../../../model/api/useAddService'
import { useUpdateService } from '../../../model/api/useUpdateService'
import { useDeleteService } from '../../../model/api/useDeleteService'
import { unstable_usePrompt, useLocation, useNavigate } from 'react-router-dom'
import { useCustomToast } from '../../../components/Toast'
import { Flex, Grid, useDisclosure } from '@chakra-ui/react'
import RouterEnum from '../../../model/enums/RouterEnum'
import ApiRouteEnum from '../../../model/enums/ApiRouteEnum'
import { Field, Form, Formik } from 'formik'
import Settings from '../../../icons/Settings'
import { colors } from '../../../theme'
import { Box } from '../../../components/Box'
import { Input } from '../../../components/Input'
import getFieldStatus from '../../../model/helpers/StatusHelper'
import { Select } from '../../../components/Select'
import Textarea from '../../../components/Textarea'
import Button from '../../../components/Button'
import EuroIcon from '../../../icons/EuroIcon'
import Switch from '../../../components/Switch'
import Checkbox from '../../../components/Checkbox'
import Close from '../../../icons/Close'
import AddBoxIcon from '../../../icons/AddBoxIcon'
import PhotoDropdown from '../../../components/PhotoDropdown'
import { VehicleServiceFormCreateLabels } from '../../../model/constants/FormLabels'
import PriceModal from '../../../components/PriceModal'
import ConfirmLeaveModal from '../../../components/ConfirmLeaveModal'
import DeleteModal from '../../../components/DeleteModal'
import { AddIcon } from '@chakra-ui/icons'
import Menu from '../../../components/Menu'
import BottomSideMenu from '../../../components/BottomSideMenu '
import PriceModalMobile from '../../../components/PriceModalMobile'
import useIsMobile from '../../../model/helpers/useIsMobile'
import useUpdateServiceStatus from '../../../model/api/useUpdateServiceStatus'
import { useUploadImage } from '../../../model/api/useUploadImage'
import Spinner from '../../../components/Spinner'
import { serviceFormSchema } from '../../../model/schemas/ServiceFormSchema'
import { ToastHelper } from '../../../model/helpers/ToastHelper'

const ServiceFormMasterMobile = (props: any) => {
  const query = useQueryClient()
  const [optionsCars, setOptionsCars] = useState([])
  const [statusOptions, setStatusOptions] = useState([])
  const { data: carData, isLoading } = useGetAllCars()
  const addService = useAddService()
  const updateService = useUpdateService()
  const mutateDelete = useDeleteService()
  const navigate = useNavigate()
  const { showCustomToast } = useCustomToast()
  const location = useLocation()
  const { mutate: updateStatus } = useUpdateServiceStatus()
  const imageMutation = useUploadImage()
  const item = location.state ? location.state.item : undefined
  const car = location.state ? location.state.car : undefined
  const mode = location.state ? location.state.mode : 'create'
  const isMobile = useIsMobile()
  const statusDisplayNames = {
    received: 'Prijaté do servisu',
    in_progress: 'Započaté práce',
    ready_to_return: 'Pripravené na vrátenie majiteľovi',
    returned: 'Vrátené majiteľovi',
  }
  const {
    isOpen: isPriceModalOpen,
    onOpen: onPriceModalOpen,
    onClose: onPriceModalClose,
  } = useDisclosure()
  const {
    isOpen: isConfirmLeaveModalOpen,
    onOpen: onConfirmLeaveModalOpen,
    onClose: onConfirmLeaveModalClose,
  } = useDisclosure()
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()

  useEffect(() => {
    const options: any = []
    carData &&
      carData.length > 0 &&
      carData.map((item: any) => {
        options.push({ label: `${item.brand} ${item.model}`, value: item.id })
        return null
      })
    setOptionsCars(options)
  }, [carData])

  useEffect(() => {
    const options: any = []
    if (item && item.status.statusName === 'received') {
      options.push({ label: `Započaté práce`, value: 'start_work' })
    }
    if (item && item.status.statusName === 'in_progress') {
      options.push({ label: `Prijatie do servisu`, value: 'stop_work' })
      options.push({ label: `Pripravené na vrátenie majiteľovi`, value: 'finish_work' })
    }
    if (item && item.status.statusName === 'ready_to_return') {
      options.push({ label: `Započaté práce`, value: 'back_to_work' })
      options.push({ label: `Vrátené majiteľovi`, value: 'return_to_customer' })
    }
    setStatusOptions(options)
  }, [item?.status])

  const handleFormSubmit = async (values: Yup.InferType<typeof serviceFormSchema>) => {
    const serviceData = {
      displayName: values.displayName,
      mileageAtReceive: values.mileageAtReceive,
      mileageAtReturn: values.mileageAtReturn,
      receivedAt: new Date(values.receivedAt).toISOString(),
      returnedAt: values.returnedAt ? new Date(values.returnedAt).toISOString() : null,
      estimatedServiceTime: values.estimatedServiceTime || null,
      estimatedReturnedAt: values.estimatedReturnedAt
        ? new Date(values.estimatedReturnedAt).toISOString()
        : null,
      vehicle: { id: values.vehicle.value },
      photosAtReceiveIds: values.photosAtReceiveIds,
      photosAtReturnIds: values.photosAtReturnIds,
      vehicleOwnerDescription: values.vehicleOwnerDescription,
      vehicleMaterialDescription: values.vehicleMaterialDescription,
      vehicleWorkDescription: values.vehicleWorkDescription,
      price: values.price,
    }
    if (mode === 'create') {
      const imageIdsAtReceive: any[] = []
      if (values.imageObjectService) {
        const imageMutationPromises: Promise<any>[] = []

        for (let index = 0; index < values.imageObjectService.length; index++) {
          const promise = imageMutation.mutateAsync(values.imageObjectService[index], {
            onError: () => {
              showCustomToast({
                status: 'error',
                title: 'Nahrávanie zlyhalo',
                label: <div>Fotografiu sa nepodarilo nahrať</div>,
              })
            },
          })
          imageMutationPromises.push(promise)
        }
        const imageMutationResults = await Promise.all(imageMutationPromises)
        imageMutationResults.forEach((result, index) => {
          if (result.data) {
            imageIdsAtReceive[index] = { id: result.data.id }
          }
        })
      }

      const imageIdsAtReturn: any[] = []
      if (values.imageObjectCustomer) {
        const imageMutationPromises: Promise<any>[] = []

        for (let index = 0; index < values.imageObjectCustomer.length; index++) {
          const promise = imageMutation.mutateAsync(values.imageObjectCustomer[index], {
            onError: () => {
              showCustomToast({
                status: 'error',
                title: 'Nahrávanie zlyhalo',
                label: <div>Fotografiu sa nepodarilo nahrať</div>,
              })
            },
          })
          imageMutationPromises.push(promise)
        }
        const imageMutationResults = await Promise.all(imageMutationPromises)
        imageMutationResults.forEach((result, index) => {
          if (result.data) {
            imageIdsAtReturn[index] = { id: result.data.id }
          }
        })
      }

      addService.mutate(
        {
          ...serviceData,
          photosAtReceiveIds: imageIdsAtReceive.length > 0 ? imageIdsAtReceive : null,
          photosAtReturnIds: imageIdsAtReturn.length > 0 ? imageIdsAtReturn : null,
          vehicleOwnerDescription: {
            ...values.vehicleOwnerDescription,
            description:
              values.vehicleOwnerDescription && values.vehicleOwnerDescription.description !== ''
                ? values.vehicleOwnerDescription.description
                : null,
          },
          vehicleMaterialDescription: {
            ...values.vehicleMaterialDescription,
            description:
              values.vehicleMaterialDescription &&
              values.vehicleMaterialDescription.description !== ''
                ? values.vehicleMaterialDescription.description
                : null,
          },
          vehicleWorkDescription: {
            ...values.vehicleWorkDescription,
            description:
              values.vehicleWorkDescription && values.vehicleWorkDescription.description !== ''
                ? values.vehicleWorkDescription.description
                : null,
          },
        },
        {
          onSuccess: () => {
            navigate('/' + RouterEnum.VEHICLE_SERVIS_OVERVIEW)
            query.invalidateQueries(ApiRouteEnum.VEHICLE_SERVIS)
            showCustomToast({
              status: 'success',
              title: 'Položka bola úspešne vytvorená',
              label: (
                <div>
                  Servis <b>{serviceData.displayName}</b> bol úspešne vytvorený
                </div>
              ),
            })
          },
          onError: () => {
            showCustomToast({
              status: 'error',
              title: 'Položku sa nepodarilo vytvoriť',
              label: (
                <div>
                  Servis <b>{serviceData.displayName}</b> sa nepodarilo vytvoriť
                </div>
              ),
            })
          },
        },
      )
    }
    if (mode === 'edit') {
      const imageIdsAtReceive: any[] = []
      if (values.imageObjectService) {
        const imageMutationPromises: Promise<any>[] = []

        for (let index = 0; index < values.imageObjectService.length; index++) {
          const promise = imageMutation.mutateAsync(values.imageObjectService[index], {
            onError: () => {
              showCustomToast({
                status: 'error',
                title: 'Nahrávanie zlyhalo',
                label: <div>Fotografiu sa nepodarilo nahrať</div>,
              })
            },
          })
          imageMutationPromises.push(promise)
        }
        const imageMutationResults = await Promise.all(imageMutationPromises)
        imageMutationResults.forEach((result, index) => {
          if (result.data) {
            imageIdsAtReceive[index] = { id: result.data.id }
          }
        })
      }

      const imageIdsAtReturn: any[] = []
      if (values.imageObjectCustomer) {
        const imageMutationPromises: Promise<any>[] = []

        for (let index = 0; index < values.imageObjectCustomer.length; index++) {
          const promise = imageMutation.mutateAsync(values.imageObjectCustomer[index], {
            onError: () => {
              showCustomToast({
                status: 'error',
                title: 'Nahrávanie zlyhalo',
                label: <div>Fotografiu sa nepodarilo nahrať</div>,
              })
            },
          })
          imageMutationPromises.push(promise)
        }
        const imageMutationResults = await Promise.all(imageMutationPromises)
        imageMutationResults.forEach((result, index) => {
          if (result.data) {
            imageIdsAtReturn[index] = { id: result.data.id }
          }
        })
      }
      if (
        ['start_work', 'stop_work', 'finish_work', 'back_to_work', 'return_to_customer'].includes(
          values.status.value || '',
        )
      ) {
        updateStatus({ id: item.id, status: values.status.value })
      }
      updateService.mutate(
        {
          serviceData: {
            ...serviceData,
            photosAtReceiveIds: [...(values.photosAtReceiveIds || []), ...imageIdsAtReceive],
            photosAtReturnIds: [...(values.photosAtReturnIds || []), ...imageIdsAtReturn],
            vehicleOwnerDescription: {
              ...values.vehicleOwnerDescription,
              description:
                values.vehicleOwnerDescription && values.vehicleOwnerDescription.description !== ''
                  ? values.vehicleOwnerDescription.description
                  : null,
            },
            vehicleMaterialDescription: {
              ...values.vehicleMaterialDescription,
              description:
                values.vehicleMaterialDescription &&
                values.vehicleMaterialDescription.description !== ''
                  ? values.vehicleMaterialDescription.description
                  : null,
            },
            vehicleWorkDescription: {
              ...values.vehicleWorkDescription,
              description:
                values.vehicleWorkDescription && values.vehicleWorkDescription.description !== ''
                  ? values.vehicleWorkDescription.description
                  : null,
            },
          },
          id: item.id,
        },
        {
          onSuccess: () => {
            navigate('/' + RouterEnum.VEHICLE_SERVIS_OVERVIEW)
            query.invalidateQueries(ApiRouteEnum.VEHICLE_SERVIS)
            showCustomToast({
              status: 'success',
              title: 'Zmeny boli uložené',
              label: (
                <div>
                  Servis <b>{serviceData.displayName}</b> bol úspešne upravený
                </div>
              ),
            })
          },
          onError: () => {
            showCustomToast({
              status: 'error',
              title: 'Položku sa nepodarilo upraviť',
              label: (
                <div>
                  Servis <b>{serviceData.displayName}</b> sa nepodarilo upraviť
                </div>
              ),
            })
          },
        },
      )
    }
  }

  if ((location.state && !car) || isLoading) {
    return <Spinner />
  }

  const initialValues = {
    displayName: item ? item?.displayName : '',
    mileageAtReceive: item ? item?.mileageAtReceive : null,
    mileageAtReturn: item ? item?.mileageAtReturn : null,
    receivedAt: item ? item?.receivedAt : new Date().toISOString(),
    returnedAt: item ? item?.returnedAt : null,
    estimatedServiceTime: item ? item?.estimatedServiceTime : null,
    estimatedReturnedAt: item ? item?.estimatedReturnedAt : null,
    vehicle: car !== undefined ? car : { label: '', value: '' },
    vehicleOwnerDescription: item
      ? item?.vehicleOwnerDescription
      : {
          description: null,
          checkList: null,
        },
    vehicleMaterialDescription: item
      ? item?.vehicleMaterialDescription
      : {
          description: null,
          checkList: null,
        },
    vehicleWorkDescription: item
      ? item?.vehicleWorkDescription
      : {
          description: null,
          checkList: null,
        },
    price: item ? item?.price : null,
    photosAtReceiveIds: item ? item?.photosAtReceive : null,
    photosAtReturnIds: item ? item?.photosAtReturn : null,
    status: item
      ? {
          label: statusDisplayNames[item?.status.statusName as keyof typeof statusDisplayNames],
          value: item?.status.statusName,
        }
      : {
          label: 'Prijaté do servisu',
          value: 'start_work',
        },
    priceItemsChecked: item ? item?.price?.priceItems.length > 0 : false,
    serviceCheckList:
      item && item?.vehicleWorkDescription && item?.vehicleWorkDescription?.checkList?.length > 0
        ? true
        : false,
    materialCheckList:
      item &&
      item?.vehicleWorkDescription &&
      item?.vehicleMaterialDescription?.checkList?.length > 0
        ? true
        : false,
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={serviceFormSchema}
    >
      {({
        errors,
        values,
        touched,
        setFieldValue,
        submitForm,
        setFieldTouched,
        dirty,
        resetForm,
      }) => {
        unstable_usePrompt({
          when: dirty,
          message: 'Naozaj chcete opustiť stránku? Zmeny nebudú uložené.',
        })

        const handleKeyPressWork = (e: any) => {
          if (e.key === 'Enter') {
            setFieldValue('vehicleWorkDescription.checkList', [
              ...(values.vehicleWorkDescription?.checkList || []),
              { label: '', isChecked: false },
            ])
            setTimeout(() => {
              e.target.parentNode.nextSibling.querySelectorAll('input')[1].focus()
            }, 200)
          }
        }
        const handleKeyPressMaterial = (e: any) => {
          if (e.key === 'Enter') {
            setFieldValue('vehicleMaterialDescription.checkList', [
              ...(values.vehicleMaterialDescription?.checkList || []),
              { label: '', isChecked: false },
            ])
            setTimeout(() => {
              e.target.parentNode.nextSibling.querySelectorAll('input')[1].focus()
            }, 200)
          }
        }

        return (
          <Form
            style={{
              height: '100%',
              flexFlow: 'column',
              overflow: 'auto',
              background: colors.blue[50],
            }}
          >
            <Grid
              fontSize={'1.2rem'}
              fontWeight={'semibold'}
              w={'100%'}
              h={'4rem'}
              placeItems={'center'}
              bg={colors.gray[700]}
              color={colors.blue[100]}
              zIndex={100}
              position={'sticky'}
              top={'0px'}
            >
              Pridať / upraviť servis
            </Grid>

            <Flex
              w={'85%'}
              gap={'1rem'}
              justifyContent={'space-between'}
              flexFlow={'column'}
              m={'1rem auto 10rem auto'}
            >
              <Flex w={'100%'} m={'1rem 0'} justifyContent={'flex-start'}>
                <Grid w={'3rem'} h={'3rem'} bg={colors.gray[700]} placeItems={'center'}>
                  <Settings color={colors.blue[500]} />
                </Grid>
                <Box
                  w={'50%'}
                  m={'0 auto'}
                  fontSize={'2rem'}
                  textAlign={'center'}
                  fontWeight={'semibold'}
                >
                  {values.displayName}
                </Box>
              </Flex>
              <Field
                bg={colors.blue[100]}
                name="displayName"
                label={'meno'}
                placeholder={'Výmena oleja'}
                component={Input}
                status={getFieldStatus(errors, touched, 'displayName')}
              />
              <Field
                name="vehicle"
                label={'Zvoľte vozidlo'}
                title={values.vehicle.label !== '' && values.vehicle.label}
                options={optionsCars}
                isReadOnly={car ? true : false || optionsCars.length === 0}
                isDisabled={car ? true : false || optionsCars.length === 0}
                component={Select}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
              <Field
                name="vehicleOwnerDescription.description"
                label={'popis servisu'}
                helperText={'Obsah sa zobrazí majiteľovi vozidla pri zdieľaní'}
                component={Textarea}
              />
              <Field
                bg={colors.blue[100]}
                name="estimatedServiceTime"
                label={'dĺžka práce'}
                type={'number'}
                component={Input}
                status={getFieldStatus(errors, touched, 'estimatedServiceTime', true, values)}
              />
              <Field
                bg={colors.blue[100]}
                name="estimatedReturnedAt"
                label={'odhadovaný dátum dokončenia'}
                type="date"
                setFieldValue={setFieldValue}
                component={Input}
                status={getFieldStatus(errors, touched, 'estimatedReturnedAt', true, values)}
              />
              <Button size={'full'} variant={'blue'} onClick={() => onPriceModalOpen()}>
                <EuroIcon fontSize={'1rem'} />
                Nastavenie ceny
              </Button>

              <Flex
                h={'3rem'}
                justifyContent={'center'}
                alignItems={'center'}
                w={'100%'}
                bg={colors.gray[700]}
                color={colors.blue[100]}
                fontSize={'0.8rem'}
                fontWeight={'semibold'}
              >
                zadanie - servis
              </Flex>
              <Flex
                alignItems={'center'}
                justifyContent={'space-between'}
                fontSize={'0.8rem'}
                w={'100%'}
                fontWeight={'semibold'}
              >
                checklist:{' '}
                <Field
                  name="serviceCheckList"
                  isChecked={values.serviceCheckList}
                  component={Switch}
                />
              </Flex>

              {values.serviceCheckList ? (
                <Grid m={0} gap={'1rem'}>
                  {values?.vehicleWorkDescription &&
                    values.vehicleWorkDescription?.checkList &&
                    values.vehicleWorkDescription?.checkList.map((item, index) => {
                      return (
                        <Flex>
                          <Field
                            name={`vehicleWorkDescription.checkList.${index}.isChecked`}
                            component={Checkbox}
                            isChecked={item.isChecked}
                            onChange={(e: any) => {
                              setFieldValue(
                                `vehicleWorkDescription.checkList.${index}.isChecked`,
                                e.target.checked,
                              )
                            }}
                          />
                          <Field
                            name={`vehicleWorkDescription.checkList.${index}.label`}
                            type={'checkListItem'}
                            placeholder={'zadajte názov'}
                            component={Input}
                            onKeyPress={handleKeyPressWork}
                          />

                          <Close
                            color={colors.red[500]}
                            fontSize={'1rem'}
                            cursor={'pointer'}
                            onClick={() => {
                              const newCheckList = (
                                values.vehicleWorkDescription?.checkList || []
                              ).filter((item, i) => i !== index)
                              setFieldValue('vehicleWorkDescription.checkList', newCheckList)
                            }}
                          />
                        </Flex>
                      )
                    })}
                  <Button
                    variant={'transparent'}
                    onClick={() =>
                      setFieldValue('vehicleWorkDescription.checkList', [
                        ...(values.vehicleWorkDescription?.checkList || []),
                        { label: '', isChecked: false },
                      ])
                    }
                    p={'0'}
                    justifyContent={'flex-start'}
                  >
                    <AddIcon fontSize={'0.8rem'} m={'0 1rem 0 0.2rem'} />
                    pridať možnosť
                  </Button>
                </Grid>
              ) : (
                <Field
                  name="vehicleWorkDescription.description"
                  label={'poznámky'}
                  component={Textarea}
                />
              )}
              <Flex
                h={'3rem'}
                justifyContent={'center'}
                alignItems={'center'}
                w={'100%'}
                bg={colors.gray[700]}
                color={colors.blue[100]}
                fontSize={'0.8rem'}
                fontWeight={'semibold'}
              >
                zadanie - materiál
              </Flex>
              <Flex
                alignItems={'center'}
                justifyContent={'space-between'}
                fontSize={'0.8rem'}
                w={'100%'}
                fontWeight={'semibold'}
              >
                checklist:{' '}
                <Field
                  name="materialCheckList"
                  isChecked={values.materialCheckList}
                  component={Switch}
                />
              </Flex>
              {values.materialCheckList ? (
                <Grid m={0} gap={'1rem'}>
                  {(values.vehicleMaterialDescription?.checkList || []).map((item, index) => {
                    return (
                      <Flex>
                        <Field
                          name={`vehicleMaterialDescription.checkList.${index}.isChecked`}
                          component={Checkbox}
                          isChecked={item.isChecked}
                          onChange={(e: any) => {
                            setFieldValue(
                              `vehicleMaterialDescription.checkList.${index}.isChecked`,
                              e.target.checked,
                            )
                          }}
                        />
                        <Field
                          name={`vehicleMaterialDescription.checkList.${index}.label`}
                          type={'checkListItem'}
                          placeholder={'zadajte názov'}
                          component={Input}
                          onKeyPress={handleKeyPressMaterial}
                        />
                        <Close
                          color={colors.red[500]}
                          fontSize={'1rem'}
                          cursor={'pointer'}
                          onClick={() => {
                            const newCheckList = (
                              values.vehicleMaterialDescription?.checkList || []
                            ).filter((item, i) => i !== index)
                            setFieldValue('vehicleMaterialDescription.checkList', newCheckList)
                          }}
                        />
                      </Flex>
                    )
                  })}
                  <Button
                    variant={'transparent'}
                    onClick={() =>
                      setFieldValue('vehicleMaterialDescription.checkList', [
                        ...(values.vehicleMaterialDescription?.checkList || []),
                        { label: '', isChecked: false },
                      ])
                    }
                    p={'0'}
                    justifyContent={'flex-start'}
                  >
                    <AddBoxIcon fontSize={'0.8rem'} m={'0 1rem 0 0.2rem'} />
                    pridať možnosť
                  </Button>
                </Grid>
              ) : (
                <Field
                  name="vehicleMaterialDescription.description"
                  label={'poznámky'}
                  component={Textarea}
                />
              )}
              <Field
                name="status"
                label={'Stav servisu'}
                title={values.status.label !== '' && values.status.label}
                options={statusOptions}
                component={Select}
                isDisabled={statusOptions.length === 0}
                isReadOnly={statusOptions.length === 0}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
              <Field
                bg={colors.blue[100]}
                name="receivedAt"
                label={'dátum prevzatia'}
                type="date"
                setFieldValue={setFieldValue}
                component={Input}
                status={getFieldStatus(errors, touched, 'receivedAt')}
              />
              <Field
                bg={colors.blue[100]}
                name="mileageAtReceive"
                label={'KM pri prevzatí'}
                type={'number'}
                setFieldValue={setFieldValue}
                component={Input}
                status={getFieldStatus(errors, touched, 'mileageAtReceive', true, values)}
              />
              <PhotoDropdown
                photoIds={values.photosAtReceiveIds}
                mode={mode}
                type={'Service'}
                values={values}
                setFieldValue={setFieldValue}
              />
              <Field
                bg={colors.blue[100]}
                name="returnedAt"
                label={'dátum odovzdania'}
                type="date"
                setFieldValue={setFieldValue}
                component={Input}
                status={getFieldStatus(errors, touched, 'returnedAt')}
              />
              <Field
                bg={colors.blue[100]}
                name="mileageAtReturn"
                type={'number'}
                label={'KM pri odovzdaní'}
                setFieldValue={setFieldValue}
                component={Input}
                status={getFieldStatus(errors, touched, 'mileageAtReturn', true, values)}
              />
              <PhotoDropdown
                photoIds={values.photosAtReturnIds}
                mode={mode}
                type={'Customer'}
                values={values}
                setFieldValue={setFieldValue}
              />
              <BottomSideMenu
                blueClickCancel={
                  dirty
                    ? onConfirmLeaveModalOpen
                    : () => navigate('/' + RouterEnum.VEHICLE_SERVIS_OVERVIEW)
                }
                greenClick={() => {
                  resetForm({ values: values })
                  ToastHelper({
                    submitForm,
                    errors,
                    setFieldTouched,
                    labels: VehicleServiceFormCreateLabels,
                    showCustomToast,
                  })
                }}
                redClick={
                  item
                    ? () => {
                        onDeleteModalOpen()
                      }
                    : undefined
                }
              />
            </Flex>

            {isMobile ? (
              <PriceModalMobile
                isPriceModalOpen={isPriceModalOpen}
                onPriceModalClose={onPriceModalClose}
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
              />
            ) : (
              <PriceModal
                isPriceModalOpen={isPriceModalOpen}
                onPriceModalClose={onPriceModalClose}
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
              />
            )}

            <ConfirmLeaveModal
              isConfirmLeaveModalOpen={isConfirmLeaveModalOpen}
              onConfirmLeaveModalClose={onConfirmLeaveModalClose}
              onGreenClickAction={() => {
                resetForm({ values: values })
                ToastHelper({
                  submitForm,
                  errors,
                  setFieldTouched,
                  labels: VehicleServiceFormCreateLabels,
                  showCustomToast,
                })
              }}
              onRedClickAction={() => {
                resetForm()
                setTimeout(() => {
                  onConfirmLeaveModalClose()
                  navigate('/' + RouterEnum.VEHICLE_SERVIS_OVERVIEW)
                }, 200)
              }}
            />

            {item && (
              <DeleteModal
                isDeleteModalOpen={isDeleteModalOpen}
                onDeleteModalClose={onDeleteModalClose}
                titleValue={item.displayName}
                onClickAction={() => {
                  mutateDelete.mutate(item.id, {
                    onSuccess: () => {
                      query.invalidateQueries(ApiRouteEnum.VEHICLE_SERVIS)
                      navigate('/' + RouterEnum.VEHICLE_SERVIS_OVERVIEW)
                      onDeleteModalClose()
                      showCustomToast({
                        status: 'success',
                        title: 'Položka úspešne odstránená',
                        label: (
                          <div>
                            Servis <b>{item.displayName}</b> bol úspešne odstránený z evidencie
                          </div>
                        ),
                      })
                    },
                    onError: () => {
                      onDeleteModalClose()
                      showCustomToast({
                        status: 'error',
                        title: 'Položku sa nepodarilo odstrániť',
                        label: (
                          <div>
                            Servis <b>{item.displayName}</b> sa nepodarilo odstrániť
                          </div>
                        ),
                      })
                    },
                  })
                }}
              />
            )}
            <Menu back={() => navigate(-1)} type={'basic'} />
          </Form>
        )
      }}
    </Formik>
  )
}

export default ServiceFormMasterMobile
