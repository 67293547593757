import { useMutation } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

export const useUpdateWorkspace = (successCallback: () => void, errorCallback: () => void) => {
  return useMutation(
    ({ workspaceData, id }: { workspaceData: any; id: string }) =>
      api.put(`${ApiRouteEnum.WORKSPACE_BY_ID}/${id}`, workspaceData),
    {
      mutationKey: [ApiRouteEnum.WORKSPACE_BY_ID],
      onSuccess: () => {
        successCallback()
      },
      onError: () => {
        errorCallback()
      },
    },
  )
}
