import useIsMobile from "../../model/helpers/useIsMobile"
import LoginPage from "./LoginPage"
import LoginPageMobile from "./LoginPageMobile"

const LoginWrapper = () => {
  const isMobile = useIsMobile()

  return isMobile ? <LoginPageMobile /> : <LoginPage />
}
export default LoginWrapper
