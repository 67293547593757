import { Flex, useToast } from '@chakra-ui/react'
import { Box } from './Box'
import SuccessIcon from '../icons/SuccessIcon'
import ErrorIcon from '../icons/ErrorIcon'
import InfoIcon from '../icons/InfoIcon'
import WarningIcon from '../icons/WarningIcon'
import StatusIndicator from './StatusIndicator'
import Close from '../icons/Close'
import { useState } from 'react'
export const useCustomToast = () => {
  const toast = useToast()
  const [isHovering, setIsHovering] = useState(false)
  const [onCloseHandler, setOnCloseHandler] = useState<() => void | undefined>()

  const showCustomToast = ({
    status,
    title,
    label,
  }: {
    status: string
    title: string
    label: any
  }) => {
    const icon = (() => {
      if (status === 'error') {
        return <Close fontSize={'1.2rem'} m={'4px'} />
      }
      if (status === 'info') {
        return <InfoIcon fontSize={'1.2rem'} m={'4px'} />
      }
      if (status === 'warning') {
        return <WarningIcon fontSize={'1.2rem'} m={'4px'} />
      }
      return <SuccessIcon fontSize={'1.2rem'} m={'4px'} />
    })()
    toast({
      position: 'bottom-right',
      duration: isHovering ? null : 3000,
      isClosable: true,
      render: ({ onClose }) => {
        setOnCloseHandler(() => onClose)
        return (
          <Flex flexFlow={'row nowrap'} minH={'7rem'}>
            <StatusIndicator status={status} icon={icon} h={'unset'} />
            <Flex
              alignItems={'center'}
              flexFlow={'row nowrap'}
              color="white"
              w={{ sm: '20rem', md: '24rem' }}
              minH={'7rem'}
              p={5}
              bg="gray.700"
            >
              <Flex flexFlow={'column nowrap'}>
                <Box fontSize={'1rem'} m={0} fontWeight={'semibold'}>
                  {title}
                </Box>
                <Box fontSize={'0.8rem'}>{label}</Box>
              </Flex>
              <Close ml={'auto'} fontSize={'1.5rem'} onClick={() => onClose()} cursor={'pointer'} />
            </Flex>
          </Flex>
        )
      },
    })
  }

  return { showCustomToast, onCloseHandler, setIsHovering }
}
