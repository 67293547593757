import useIsMobile from '../../../model/helpers/useIsMobile'
import ServicesOverview from '../ServicesOverview'
import ServicesOverviewMobile from '../mobile/ServicesOverviewMobile'

const ServicesOverviewWrapper = () => {
  const isMobile = useIsMobile()

  return isMobile ? <ServicesOverviewMobile /> : <ServicesOverview />
}
export default ServicesOverviewWrapper
