import { useQuery } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useGetWorkspaceQuotas = (id: string) => {
  const { data, isLoading } = useQuery('workspaceByQuotas', async () => {
    if (id) {
      const response = await api.get(`${ApiRouteEnum.WORKSPACE_BY_ID}/${id}/quotas`)
      return response.data
    }
  })
  return { data, isLoading }
}

export default useGetWorkspaceQuotas
