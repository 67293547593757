const useInitialValuesAccountData = ({ data }: { data: any }) => {
  const initialUserData = {
    firstname: data ? data.firstname : '',
    lastname: data ? data.lastname : '',
    email: data ? data.email : '',
  }

  const initialPasswordData = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  }
  return { initialUserData, initialPasswordData }
}

export default useInitialValuesAccountData
