import { Button, Flex, Grid } from '@chakra-ui/react'
import { colors } from '../../theme'
import LogoVivem from '../../assets/logoVivem'
import { Field, Form, Formik } from 'formik'
import { Input } from '../../components/Input'
import getFieldStatus from '../../model/helpers/StatusHelper'
import * as Yup from 'yup'
import { IS_REQUIRED } from '../../model/constants/ValidationMessages'
import { useActivateUser } from '../../model/api/useActivateUser'
import { ForgottenPasswordSchema } from '../../model/schemas/ForgottenPasswordSchema'
import { unstable_usePrompt } from 'react-router-dom'


const RegisterAfterInviteForm = ({ onSetRegisterStep }: { onSetRegisterStep: () => void }) => {
  const {mutate: mutateActivateUser} = useActivateUser()
  const queryParams = new URLSearchParams(window.location.search)
  const userLink = queryParams.get('user_link')

  if (!userLink) {
    return null
  }

  const initialValues = {
    password: '',
    confirmPassword: '',
  }

  const handleFormSubmit = (values: any) => {
    mutateActivateUser({userLink: userLink, password: values.password})
    onSetRegisterStep()
  }

  return (
    <Grid h={'100%'} w={'100%'} placeItems={'center'} className="background">
      <Flex
        w={'20rem'}
        p={5}
        bg={colors.blue[50]}
        alignItems={'center'}
        flexFlow={'column nowrap'}
        justifyContent={'space-between'}
        zIndex={10}
      >
        <Flex
          alignItems={'center'}
          flexFlow={'column nowrap'}
          justifyContent={'space-between'}
          gap={'0.8rem'}
        >
          <LogoVivem width={'6rem'} />
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={ForgottenPasswordSchema}
          >
            {({ errors, touched, dirty }) => {
               unstable_usePrompt({
                 when: dirty,
                 message: 'Naozaj chcete opustiť stránku? Zmeny nebudú uložené.',
               })
              return (
                <Form>
                  <Grid gap={'1rem'}>
                    <Field
                      name="password"
                      label="heslo"
                      type="password"
                      component={Input}
                      status={getFieldStatus(errors, touched, 'password')}
                    />
                    <Field
                      name="confirmPassword"
                      label="zopakovať heslo"
                      type="password"
                      component={Input}
                      status={getFieldStatus(errors, touched, 'confirmPassword')}
                    />
                    <Button variant={'blue'} type="submit">
                      registrovať
                    </Button>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Flex>
      </Flex>
    </Grid>
  )
}

export default RegisterAfterInviteForm
