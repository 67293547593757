import {
  Progress,
  Step,
  StepIndicator,
  StepSeparator,
  Stepper,
  useSteps,
} from "@chakra-ui/react";
import { colors } from "../theme";
import { Box } from "./Box";

export default function ProgressBar(props: any) {
  const { step = 0 } = props;
  const { activeStep } = useSteps({
    index: step,
    count: 3,
  });
  const max = 3 - 1;
  const progressPercent = (activeStep / max) * 100;
  return (
    <Box position='relative' w={"60%"} zIndex={3} display={'flex'} alignItems={'center'} flexFlow={'column'}>
      <Stepper size='sm' index={activeStep} gap='0' w={'100%'}>
        {Array.from(Array(3)).map((item, index) => (
          <Step key={index}>
            <StepIndicator
              bg={index === activeStep ? colors.blue[500] : "white"}
              border={"none"}
            />
            <StepSeparator  />
          </Step>
        ))}
      </Stepper>
      <Progress
        value={progressPercent}
        position='absolute'
        height='7px'
        width='full'
        top='50%'
        transform={'translateY(-50%)'}
        zIndex={-1}
      />
    </Box>
  );
}
