import useIsMobile from "../../../model/helpers/useIsMobile"
import VehicleDetail from "../VehicleDetail"
import VehicleDetailMobile from "../mobile/VehicleDetailMobile"

const VehicleDetailWrapper = () => {
  const isMobile = useIsMobile()

  return isMobile ? <VehicleDetailMobile /> : <VehicleDetail />
}
export default VehicleDetailWrapper
