import { Grid, Flex } from '@chakra-ui/layout'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { unstable_usePrompt, useLocation, useNavigate } from 'react-router-dom'
import { useDisclosure } from '@chakra-ui/react'
import { useQueryClient } from 'react-query'
import useGetAllVehicleOwners from '../../../model/api/useGetAllVehicleOwners'
import useUpdateVehicle from '../../../model/api/useUpdateVehicle'
import useUpdateVehicleInsurance from '../../../model/api/useUpdateVehicleInsurance'
import { useAddInsurance } from '../../../model/api/useAddInsurance'
import { useAddCar } from '../../../model/api/useAddCar'
import { useUploadImage } from '../../../model/api/useUploadImage'
import useUpdateVehicleMOT from '../../../model/api/useUpdateVehicleMOT'
import { useAddMOT } from '../../../model/api/useAddMOT'
import { useDeleteCar } from '../../../model/api/useDeleteCar'
import { useCustomToast } from '../../../components/Toast'
import useGetCar from '../../../model/api/useGetCar'
import Spinner from '../../../components/Spinner'
import ApiRouteEnum from '../../../model/enums/ApiRouteEnum'
import RouterEnum from '../../../model/enums/RouterEnum'
import CarIcon from '../../../icons/CarIcon'
import { Box } from '../../../components/Box'
import { Input } from '../../../components/Input'
import getFieldStatus from '../../../model/helpers/StatusHelper'
import Textarea from '../../../components/Textarea'
import { Select } from '../../../components/Select'
import MOTdropdown from '../../../components/MOTdropdown'
import HighwaySignsDropdown from '../../../components/HighwaySignsDropdown'
import InsuranceDropdown from '../../../components/InsuranceDropdown'
import { VehicleFormCreateLabels } from '../../../model/constants/FormLabels'
import { colors } from '../../../theme'
import DeleteModal from '../../../components/DeleteModal'
import ConfirmLeaveModal from '../../../components/ConfirmLeaveModal'
import Menu from '../../../components/Menu'
import BottomSideMenu from '../../../components/BottomSideMenu '
import { vehicleFormSchema } from '../../../model/schemas/VehicleFormSchema'
import useInitialValuesVehicleForm from '../../../model/initialValues/useInitialValuesVehicleForm'
import { useRecognizeImage } from '../../../model/api/useRecognizeImage'
import UploadIcon from '../../../icons/UploadIcon'
import { ToastHelper } from '../../../model/helpers/ToastHelper'

const VehicleFormMasterMobile = (props: any) => {
  const [optionsOwners, setOptionsOwners] = useState([])
  const query = useQueryClient()
  const { data: usersData, isLoading } = useGetAllVehicleOwners()
  const updateCar = useUpdateVehicle()
  const { mutate: updateInsurance } = useUpdateVehicleInsurance()
  const { mutate: addInsurance } = useAddInsurance()
  const addCar = useAddCar()
  const imageMutation = useUploadImage()
  const updateMOT = useUpdateVehicleMOT()
  const addMOT = useAddMOT()
  const recognizeImage = useRecognizeImage()
  const mutateDelete = useDeleteCar()
  const navigate = useNavigate()
  const { showCustomToast } = useCustomToast()
  const location = useLocation()
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()
  const {
    isOpen: isConfirmLeaveModalOpen,
    onOpen: onConfirmLeaveModalOpen,
    onClose: onConfirmLeaveModalClose,
  } = useDisclosure()
  const mode = location?.state?.mode || 'edit'
  const carId = location?.state?.carId
  const { data: car, isLoading: isCarLoading } = useGetCar(carId)
  const { initialValues } = useInitialValuesVehicleForm({ car })

  useEffect(() => {
    const options: any = []
    if (!isLoading) {
      usersData &&
        usersData.map((item: any) => {
          options.push({ label: `${item.firstname} ${item.lastname}`, value: item.id })
          return null
        })
      setOptionsOwners(options)
    }
  }, [usersData, isLoading])

  if (!mode || (mode === 'edit' && !car) || isCarLoading) {
    return (
      <>
        <Grid
          fontSize={'1.2rem'}
          fontWeight={'semibold'}
          w={'100%'}
          h={'4rem'}
          placeItems={'center'}
          bg={colors.gray[700]}
          color={colors.blue[100]}
          zIndex={100}
          position={'sticky'}
          top={'0px'}
        >
          Prehľad vozidiel v evidencii
        </Grid>
        <Grid h={'40rem'}>
          <Spinner />
        </Grid>
        <Menu type={'full'} />
      </>
    )
  }

  const handleFormSubmit = async (values: Yup.InferType<typeof vehicleFormSchema>) => {
    if (mode === 'create') {
      const { newVehicleMOT, newInsurance, ...rest } = values

      const imageIds: string[] = []

      if (newInsurance && values.imageObject) {
        const imageMutationPromises: Promise<any>[] = []

        for (let index = 0; index < values.imageObject.length; index++) {
          const promise = imageMutation.mutateAsync(values.imageObject[index], {
            onError: () => {
              showCustomToast({
                status: 'error',
                title: 'Nahrávanie zlyhalo',
                label: <div>Fotografiu sa nepodarilo nahrať</div>,
              })
            },
          })
          imageMutationPromises.push(promise)
        }
        const imageMutationResults = await Promise.all(imageMutationPromises)
        imageMutationResults.forEach((result, index) => {
          if (result.data) {
            imageIds[index] = result.data.id
          }
        })
      }

      const newInsuranceData = newInsurance
        ? newInsurance?.map((item, index) => {
            return { ...item, greenCard: imageIds[index] ? imageIds[index] : null }
          })
        : []

      addCar.mutate(
        {
          ...rest,
          ownerId: values.ownerId.value,
          vehicleMOT: newVehicleMOT,
          vehicleInsurance: newInsuranceData,
          description: values.description !== '' ? values.description : null,
          licensePlate: values.licensePlate !== '' ? values.licensePlate : null,
          color: values.color !== '' ? values.color : null,
          engineModel: values.engineModel !== '' ? values.engineModel : null,
        },
        {
          onSuccess: () => {
            query.invalidateQueries(ApiRouteEnum.VEHICLE)
            query.invalidateQueries('workspaceByQuotas')
            navigate('/' + RouterEnum.VEHICLE_MANAGEMENT)
            onDeleteModalClose()
            showCustomToast({
              status: 'success',
              title: 'Položka bola úspešne vytvorená',
              label: (
                <div>
                  Vozidlo <b>{values.displayName}</b> bolo úspešne vytvorené
                </div>
              ),
            })
          },
          onError: () => {
            onDeleteModalClose()
            showCustomToast({
              status: 'error',
              title: 'Položku sa nepodarilo vytvoriť',
              label: (
                <div>
                  Vozidlo <b>{values.displayName}</b> sa nepodarilo vytvoriť
                </div>
              ),
            })
          },
        },
      )
    }

    if (mode === 'edit') {
      const mutateValuesCar = {
        displayName: values.displayName,
        description: values.description !== '' ? values.description : null,
        licensePlate: values.licensePlate !== '' ? values.licensePlate : null,
        model: values.model,
        engineModel: values.engineModel !== '' ? values.engineModel : null,
        brand: values.brand,
        vinCode: values.vinCode,
        color: values.color !== '' ? values.color : null,
        ownerId: values.ownerId.value,
        manufacturedAt: values.manufacturedAt,
        highwaySigns: values.highwaySigns,
      }

      const mutateValuesMOT = values.vehicleMOT
        ? values.vehicleMOT.map((item: any, index: number) => {
            return {
              validFrom: item.validFrom,
              validTo: item.validTo,
            }
          })
        : []

      values.newVehicleMOT &&
        values.newVehicleMOT.map((item: any, index: number) => {
          addMOT.mutate(
            {
              validFrom: item.validFrom,
              validTo: item.validTo,
              vehicleId: car.id,
            },
            {
              onSuccess: () => {
                query.invalidateQueries(ApiRouteEnum.VEHICLE_MOT)
              },
              onError: () => {
                showCustomToast({
                  status: 'error',
                  title: 'STK sa nepodarilo vytvoriť',
                  label: <div>STK sa nepodarilo vytvoriť</div>,
                })
              },
            },
          )
          return null
        })

      mutateValuesMOT.map((item: any, index: number) => {
        updateMOT.mutate(
          { updateMOTData: item, id: car.vehicleMOT[index].id },
          {
            onSuccess: () => {
              query.invalidateQueries(ApiRouteEnum.VEHICLE_MOT)
            },
            onError: () => {
              showCustomToast({
                status: 'error',
                title: 'STK sa nepodarilo upraviť',
                label: <div>STK sa nepodarilo upraviť</div>,
              })
            },
          },
        )
        return null
      })

      const imageIds: string[] = []
      const imageMutationObject = values.imageObjectWithIds as any
      const imageMutationPromises: Promise<any>[] = []

      for (let index = 0; index < imageMutationObject.length; index++) {
        const promise = imageMutation.mutateAsync(imageMutationObject[index].data, {
          onError: () => {
            showCustomToast({
              status: 'error',
              title: 'Nahrávanie zlyhalo',
              label: <div>Fotografiu sa nepodarilo nahrať</div>,
            })
          },
        })
        imageMutationPromises.push(promise)
      }
      const imageMutationResults = await Promise.all(imageMutationPromises)
      imageMutationResults.forEach((result, index) => {
        if (result.data) {
          imageIds[imageMutationObject[index].id] = result.data.id
        }
      })
      values.vehicleInsurance &&
        values.vehicleInsurance.map((item: any, index: number) => {
          updateInsurance(
            {
              updateInsuranceData: {
                validFrom: item.validFrom,
                validTo: item.validTo,
                insuranceCompany: item.insuranceCompany,
                contractNumber: item.contractNumber,
                greenCard: imageIds[item.id] || item.greenCard?.id || null,
              },
              id: item.id,
            },
            {
              onError: () => {
                showCustomToast({
                  status: 'error',
                  title: 'Poistenie sa nepodarilo upraviť',
                  label: <div>Poistenie sa nepodarilo upraviť</div>,
                })
              },
            },
          )
          return null
        })

      const imageIdsNew: string[] = []

      if (values.newInsurance && values.imageObject) {
        const imageMutationPromises: Promise<any>[] = []

        for (let index = 0; index < values.imageObject.length; index++) {
          const promise = imageMutation.mutateAsync(values.imageObject[index], {
            onError: () => {
              showCustomToast({
                status: 'error',
                title: 'Nahrávanie zlyhalo',
                label: <div>Fotografiu sa nepodarilo nahrať</div>,
              })
            },
          })
          imageMutationPromises.push(promise)
        }
        const imageMutationResults = await Promise.all(imageMutationPromises)
        imageMutationResults.forEach((result, index) => {
          if (result.data) {
            imageIdsNew[index] = result.data.id
          }
        })
      }

      values.newInsurance?.map((item, index) => {
        addInsurance(
          {
            ...item,
            greenCard: imageIdsNew[index] ? imageIdsNew[index] : null,
            vehicleId: car.id,
          },
          {
            onError: () => {
              showCustomToast({
                status: 'error',
                title: 'Poistenie sa nepodarilo vytvoriť',
                label: <div>Poistenie sa nepodarilo vytvoriť</div>,
              })
            },
          },
        )
        return null
      })

      updateCar.mutate(
        { updateCarData: mutateValuesCar, id: car.id },
        {
          onSuccess: () => {
            query.invalidateQueries(ApiRouteEnum.VEHICLE)
            navigate('/' + RouterEnum.VEHICLE_MANAGEMENT)
            showCustomToast({
              status: 'success',
              title: 'Zmeny boli uložené',
              label: (
                <div>
                  Vozidlo <b>{mutateValuesCar.displayName}</b> bolo úspešne upravené
                </div>
              ),
            })
          },
          onError: () => {
            showCustomToast({
              status: 'error',
              title: 'Položku sa nepodarilo upraviť',
              label: (
                <div>
                  Vozidlo <b>{mutateValuesCar.displayName}</b> sa nepodarilo upraviť
                </div>
              ),
            })
          },
        },
      )
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={vehicleFormSchema}
      enableReinitialize
    >
      {({
        errors,
        values,
        touched,
        setFieldValue,
        submitForm,
        setFieldTouched,
        dirty,
        resetForm,
      }) => {
        const handleFileUpload = (e: any, type: string) => {
          const file = e.target.files[0]
          if (file) {
            if (file.size > 5 * 1024 * 1024) {
              showCustomToast({
                status: 'error',
                title: 'Nahrávanie zlyhalo',
                label: <div>Fotografia musí byť menšia ako 5MB</div>,
              })
              return
            }
            const reader = new FileReader()

            reader.onload = () => {
              const binaryData = reader.result as ArrayBuffer
              const base64Data = btoa(
                new Uint8Array(binaryData).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  '',
                ),
              )
              recognizeImage.mutate(
                {
                  typeOfRequest: type,
                  mimeType: file.type,
                  data: base64Data,
                },
                {
                  onSuccess: (data) => {
                    if (data.data.result !== null) {
                      setFieldValue(
                        type === 'VIN' ? 'vinCode' : 'licensePlate',
                        data.data.result[0],
                      )
                    }
                  },
                },
              )
            }
            reader.readAsArrayBuffer(file)
          }
        }
        unstable_usePrompt({
          when: dirty,
          message: 'Naozaj chcete opustiť stránku? Zmeny nebudú uložené.',
        })
        return (
          <Form style={{ background: colors.blue[50] }}>
            <Grid
              fontSize={'1.2rem'}
              fontWeight={'semibold'}
              w={'100%'}
              h={'4rem'}
              placeItems={'center'}
              bg={colors.gray[700]}
              color={colors.blue[100]}
              zIndex={100}
              position={'sticky'}
              top={'0px'}
            >
              Prehľad vozidiel v evidencii
            </Grid>
            <Flex
              flexFlow={'column'}
              alignItems={'center'}
              gap={'1rem'}
              w={'85%'}
              m={'0 auto'}
              pb={'12rem'}
            >
              <Flex
                w={'100%'}
                m={'2rem 0 1rem 0'}
                justifyContent={'flex-start'}
                alignItems={'center'}
              >
                <Grid w={'3rem'} h={'3rem'} bg={colors.gray[700]} placeItems={'center'}>
                  <CarIcon color={colors.blue[500]} />
                </Grid>
                <Box m={'0 auto'} fontSize={'1rem'} textAlign={'center'} fontWeight={'semibold'}>
                  {values.displayName}
                </Box>
              </Flex>
              <Field
                bg={colors.blue[100]}
                name="displayName"
                placeholder={'Červená Fabia'}
                label={'meno'}
                component={Input}
                status={getFieldStatus(errors, touched, 'displayName')}
              />
              <Field
                bg={colors.blue[100]}
                name={`vinUpload`}
                component={Input}
                type={'file'}
                onChange={(e: any) => handleFileUpload(e, 'VIN')}
                hide={true}
              />
              <Field
                bg={colors.blue[100]}
                name="vinCode"
                label={'VIN'}
                component={Input}
                status={getFieldStatus(errors, touched, 'vinCode')}
              />
              <label htmlFor={`vinUpload`} style={{ width: '100%' }}>
                <Box
                  color={colors.blue[500]}
                  textDecoration={'underline'}
                  fontSize={'0.8rem'}
                  display={'flex'}
                  justifyContent={'space-around'}
                  alignItems={'center'}
                  textAlign={'center'}
                >
                  <UploadIcon fontSize={'1.3rem'} color={colors.blue[500]} />
                  Rozpoznať VIN
                  <br />
                  pomocou umelej inteligencie
                </Box>
              </label>
              <Field
                bg={colors.blue[100]}
                name={`spzUpload`}
                component={Input}
                type={'file'}
                onChange={(e: any) => handleFileUpload(e, 'SPZ')}
                hide={true}
              />
              <Field
                bg={colors.blue[100]}
                name="licensePlate"
                label={'SPZ'}
                component={Input}
                status={getFieldStatus(errors, touched, 'licensePlate', true, values)}
              />
              <label htmlFor={`spzUpload`} style={{ width: '100%' }}>
                <Box
                  color={colors.blue[500]}
                  textDecoration={'underline'}
                  fontSize={'0.8rem'}
                  display={'flex'}
                  justifyContent={'space-around'}
                  alignItems={'center'}
                  textAlign={'center'}
                >
                  <UploadIcon fontSize={'1.3rem'} color={colors.blue[500]} />
                  Rozpoznať SPZ
                  <br />
                  pomocou umelej inteligencie
                </Box>
              </label>
              <Field
                bg={colors.blue[100]}
                name="brand"
                label={'značka / výrobca vozidla'}
                component={Input}
                status={getFieldStatus(errors, touched, 'brand')}
              />
              <Field
                bg={colors.blue[100]}
                name="model"
                label={'model vozidla'}
                component={Input}
                status={getFieldStatus(errors, touched, 'model')}
              />
              <Field
                bg={colors.blue[100]}
                name="engineModel"
                label={'model motoru vozidla'}
                component={Input}
                status={getFieldStatus(errors, touched, 'engineModel', true, values)}
              />
              <Field
                bg={colors.blue[100]}
                name="color"
                label={'farba vozidla'}
                component={Input}
                status={getFieldStatus(errors, touched, 'color', true, values)}
              />
              <Field
                bg={colors.blue[100]}
                name="manufacturedAt"
                label={'rok výroby'}
                component={Input}
                status={getFieldStatus(errors, touched, 'manufacturedAt')}
              />

              <Field name="description" label={'Poznámky'} component={Textarea} />
              <Field
                name="ownerId"
                label={'Majiteľ vozidla'}
                options={optionsOwners}
                title={
                  car &&
                  car.vehicleOwner &&
                  `${car.vehicleOwner.firstname} ${car.vehicleOwner.lastname}`
                }
                component={Select}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />

              <MOTdropdown
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                mode={mode}
                values={values}
                vehicleMOT={car ? car.vehicleMOT : []}
              />

              <HighwaySignsDropdown
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                mode={mode}
                values={values}
                highwaySigns={car ? car.highwaySigns : []}
              />

              <InsuranceDropdown
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
                mode={mode}
                vehicleInsurance={car ? car.insurance : []}
              />

              <BottomSideMenu
                blueClickCancel={
                  dirty
                    ? onConfirmLeaveModalOpen
                    : () => navigate('/' + RouterEnum.VEHICLE_MANAGEMENT)
                }
                greenClick={() => {
                  resetForm({ values: values })
                  ToastHelper({
                    submitForm,
                    errors,
                    setFieldTouched,
                    labels: VehicleFormCreateLabels,
                    showCustomToast,
                  })
                }}
                redClick={mode === 'edit' ? () => onDeleteModalOpen() : undefined}
              />

              {car && (
                <DeleteModal
                  titleValue={car.displayName}
                  isDeleteModalOpen={isDeleteModalOpen}
                  onDeleteModalClose={onDeleteModalClose}
                  onClickAction={() => {
                    mutateDelete.mutate(car.id, {
                      onSuccess: () => {
                        query.invalidateQueries(ApiRouteEnum.VEHICLE)
                        query.invalidateQueries('workspaceByQuotas')
                        navigate('/' + RouterEnum.VEHICLE_MANAGEMENT)
                        showCustomToast({
                          status: 'success',
                          title: 'Položka úspešne odstránená',
                          label: (
                            <div>
                              Vozidlo <b>{car.displayName}</b> bolo úspešne odstránené z evidencie
                            </div>
                          ),
                        })
                      },
                      onError: () => {
                        showCustomToast({
                          status: 'error',
                          title: 'Položku sa nepodarilo odstrániť',
                          label: (
                            <div>
                              Vozidlo <b>{car.displayName}</b> sa nepodarilo odstrániť
                            </div>
                          ),
                        })
                      },
                    })
                  }}
                />
              )}

              <ConfirmLeaveModal
                isConfirmLeaveModalOpen={isConfirmLeaveModalOpen}
                onConfirmLeaveModalClose={onConfirmLeaveModalClose}
                onGreenClickAction={() => {
                  resetForm({ values: values })
                  ToastHelper({
                    submitForm,
                    errors,
                    setFieldTouched,
                    labels: VehicleFormCreateLabels,
                    showCustomToast,
                  })
                }}
                onRedClickAction={() => {
                  resetForm()
                  setTimeout(() => {
                    onConfirmLeaveModalClose()
                    navigate('/' + RouterEnum.VEHICLE_MANAGEMENT)
                  }, 200)
                }}
              />
            </Flex>
            <Menu back={() => navigate(-1)} type={'basic'} />
          </Form>
        )
      }}
    </Formik>
  )
}

export default VehicleFormMasterMobile
