import { Flex, Grid } from '@chakra-ui/react'
import { Box } from '../../components/Box'
import { colors } from '../../theme'
import ProgressBar from '../../components/ProgressBar'
import Menu from '../../components/Menu'
import { Field, Form, Formik } from 'formik'
import { Input } from '../../components/Input'
import * as Yup from 'yup'
import { IS_REQUIRED } from '../../model/constants/ValidationMessages'
import Switch from '../../components/Switch'
import Textarea from '../../components/Textarea'
import ConfirmModal from '../../components/ConfirmModal'
import getFieldStatus from '../../model/helpers/StatusHelper'
import IRegister from '../../model/interface/IRegister'
import { RegisterFormLabels } from '../../model/constants/FormLabels'
import { useCustomToast } from '../../components/Toast'
import { RegisterStep2Schema } from '../../model/schemas/RegisterStep2Schema'
import useInitialValuesRegisterStep2 from '../../model/initialValues/useInitialValuesRegisterStep2'
import useIsMobile from '../../model/helpers/useIsMobile'
import { ToastHelper } from '../../model/helpers/ToastHelper'

const RegisterStep2: React.FC<IRegister> = ({
  registerData,
  onSetRegisterData,
  onSetRegisterStep,
}) => {
  const { showCustomToast, setIsHovering, onCloseHandler } = useCustomToast()
  const isMobile = useIsMobile()
  const handleFormSubmit = (values: Yup.InferType<typeof RegisterStep2Schema>) => {
    onSetRegisterStep((prev) => prev + 1)
  }

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target as HTMLInputElement
    if (name === 'phone') {
      const phone = value.replace(/\s/g, '')
      onSetRegisterData({
        ...registerData,
        [name]: phone,
      })
    } else if (name === 'showOnWeb') {
      onSetRegisterData({
        ...registerData,
        workspaceMarketingData: {
          ...registerData.workspaceMarketingData,
          [name]: value === 'true' ? false : true,
        },
      })
    } else {
      onSetRegisterData({
        ...registerData,
        workspaceMarketingData: {
          ...registerData.workspaceMarketingData,
          [name]: value,
        },
      })
    }
  }

  const { initialValues } = useInitialValuesRegisterStep2({ registerData })

  return (
    <>
      <Flex
        w={'100%'}
        h={'15%'}
        bg={colors.gray[700]}
        flexFlow={'column nowrap'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Flex w={'25rem'} flexFlow={'column nowrap'} alignItems={'center'} h={'90%'}>
          <Box fontSize={'1.5rem'} fontWeight={'semibold'} color={colors.gray[50]} mb={'10px'}>
            Zobraziť na webe ViVeM
          </Box>
          <ProgressBar step={1} />
        </Flex>
      </Flex>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={RegisterStep2Schema}
      >
        {({ errors, touched, submitForm, setFieldTouched }) => {
          return (
            <>
              <ConfirmModal
                title={'Chcete, aby sa Váš servis zobrazil na webových stránkach VIVEM?'}
                onSetRegisterStep={onSetRegisterStep}
              />
              <Grid placeItems={'center'} bg={colors.blue[50]} h={'75%'} w={'100%'}>
                <Form style={{ width: '35rem' }}>
                  <Grid
                    w={'100%'}
                    templateColumns={'repeat(2, 1fr)'}
                    templateRows={'repeat(2, 1fr)'}
                    gridAutoFlow={'column'}
                    justifyItems={'center'}
                    gap={'0.8rem'}
                    onChange={handleFieldChange}
                  >
                    <Field
                      name="title"
                      label="webový názov"
                      type="text"
                      component={Input}
                      status={getFieldStatus(errors, touched, 'title')}
                    />
                    <Field
                      name="phone"
                      label="telefónne číslo"
                      type="text"
                      component={Input}
                      status={getFieldStatus(errors, touched, 'phone')}
                    />
                    <Field
                      name="url"
                      label="URL webstránky"
                      type="text"
                      component={Input}
                      status={getFieldStatus(errors, touched, 'url')}
                    />
                    <Flex
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      fontSize={'0.8rem'}
                      w={'100%'}
                      fontWeight={'semibold'}
                    >
                      zverejniť na stránke ViVeM{' '}
                      <Box
                        h={'unset'}
                        onMouseEnter={
                          registerData.address === null ||
                          registerData.address?.city === null ||
                          registerData.address?.street === null
                            ? () => {
                                setIsHovering(true)
                                showCustomToast({
                                  status: 'warning',
                                  title: 'Nie je možné zverejniť na stránke ViVeM',
                                  label: (
                                    <div>
                                      Vo Vašom pracovisku je nutné najprv uviesť mesto a ulicu
                                      Vašeho pracoviska.
                                    </div>
                                  ),
                                })
                              }
                            : undefined
                        }
                        onMouseLeave={() => onCloseHandler && onCloseHandler()}
                      >
                        <Field
                          name="showOnWeb"
                          isChecked={registerData.workspaceMarketingData.showOnWeb}
                          isDisabled={
                            registerData.address === null ||
                            registerData.address?.city === null ||
                            registerData.address?.street === null
                          }
                          component={Switch}
                        />
                      </Box>
                    </Flex>
                  </Grid>
                  <Field
                    mt={'0.8rem'}
                    name="description"
                    label={'text na webstránke'}
                    component={Textarea}
                    onChange={handleFieldChange}
                  />
                </Form>
              </Grid>
              <Menu
                h={'10%'}
                type={'arrowsOnly'}
                back={() => onSetRegisterStep((prev) => prev - 1)}
                next={() =>
                  ToastHelper({
                    submitForm,
                    errors,
                    setFieldTouched,
                    labels: RegisterFormLabels,
                    showCustomToast,
                  })
                }
              />
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default RegisterStep2
