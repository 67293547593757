export const IS_REQUIRED = 'Povinné pole'
export const IS_EMAIL = 'Neplatný formát emailu'
export const IS_PHONE = 'Neplatný formát telefonního čísla'
export const IS_PASSWORD = 'Heslo musí mať aspoň 8 znakov'
export const IS_PASSWORD_CONFIRM = 'Heslá sa nezhodujú'
export const IS_IDENTIFICATION_NUMBER = 'Neplatný formát IČO (6-18 znakov)'
export const IS_VAT = 'Neplatný formát DIČ (8-11 znakov)'
export const IS_HOUSENUMBER = 'Neplatný formát čísla domu (čísla "0-9" a "/")'
export const IS_ZIP = 'Neplatný formát PSČ (5 čísel)'
export const IS_URL = 'Neplatný formát URL'
