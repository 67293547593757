import { HStack } from '@chakra-ui/react'
import IconButton from './IconButton'
import Delete from '../icons/Delete'
import Save from '../icons/Save'
import Cancel from '../icons/Cancel'
import Settings from '../icons/Settings'
import Edit from '../icons/Edit'
import ShareIcon from '../icons/ShareIcon'
import { EmailIcon } from '@chakra-ui/icons'
import ConfirmIcon from '../icons/ConfirmIcon'

interface IRightSideMenu {
  blueClickCancel?: () => void
  blueClickEdit?: () => void
  greenClick?: () => void
  greenClickConfirm?: () => void
  redClick?: () => void
  darkClickSettings?: () => void
  darkClickEmail?: () => void
  darkClickShare?: () => void
}

const BottomSideMenu = ({
  blueClickEdit,
  blueClickCancel,
  greenClick,
  greenClickConfirm,
  redClick,
  darkClickSettings,
  darkClickEmail,
  darkClickShare,
}: IRightSideMenu) => {
  return (
    <HStack
      spacing={'1rem'}
      align={'center'}
      justify={'center'}
      position={'absolute'}
      bottom={'4rem'}
      w={'85%'}
      h={'3rem'}
      left={'50%'}
      transform={'translate(-50%, -50%)'}
    >
      {blueClickCancel && (
        <IconButton h={'100%'} icon={<Cancel />} variant={'blue'} onClick={blueClickCancel} />
      )}
      {blueClickEdit && (
        <IconButton h={'100%'} icon={<Edit />} variant={'blue'} onClick={blueClickEdit} />
      )}
      {darkClickSettings && (
        <IconButton h={'100%'} icon={<Settings />} variant={'dark'} onClick={darkClickSettings} />
      )}
      {darkClickShare && (
        <IconButton h={'100%'} icon={<ShareIcon />} variant={'dark'} onClick={darkClickShare} />
      )}
      {darkClickEmail && (
        <IconButton h={'100%'} icon={<EmailIcon />} variant={'dark'} onClick={darkClickEmail} />
      )}
      {greenClick && (
        <IconButton
          h={'100%'}
          icon={<Save />}
          variant={'green'}
          onClick={() => {
            greenClick()
          }}
        />
      )}
      {greenClickConfirm && (
        <IconButton
          h={'100%'}
          icon={<ConfirmIcon />}
          variant={'green'}
          onClick={() => {
            greenClickConfirm()
          }}
        />
      )}

      {redClick && <IconButton h={'100%'} icon={<Delete />} variant={'red'} onClick={redClick} />}
    </HStack>
  )
}

export default BottomSideMenu
