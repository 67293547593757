import useIsMobile from '../../../model/helpers/useIsMobile'
import ServiceDetail from '../ServiceDetail'
import ServiceDetailMobile from '../mobile/ServiceDetailMobile'

const ServiceDetailWrapper = () => {
  const isMobile = useIsMobile()

  return isMobile ? <ServiceDetailMobile /> : <ServiceDetail />
}
export default ServiceDetailWrapper
