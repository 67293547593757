import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Grid,
  Flex,
} from '@chakra-ui/react'
import { colors } from '../theme'
import EmailIcon from '../icons/EmailIcon'
import Close from '../icons/Close'
import { Field, Form, Formik } from 'formik'
import getFieldStatus from '../model/helpers/StatusHelper'
import { Input } from './Input'
import Switch from './Switch'
import Textarea from './Textarea'
import IconButton from './IconButton'
import Cancel from '../icons/Cancel'
import { ShareArrow } from '../icons/ShareArrow'
import { useSendNotification } from '../model/api/useSendNotification'
import { useCustomToast } from './Toast'
import { EmailModalFormLabels } from '../model/constants/FormLabels'
import useIsMobile from '../model/helpers/useIsMobile'
import { EmailShareSchema } from '../model/schemas/EmailShareSchema'
import { ToastHelper } from '../model/helpers/ToastHelper'

const EmailModal = ({
  isEmailModalOpen,
  onEmailModalClose,
  workspaceData,
  vehicleServiceId,
  status,
  email,
}: {
  isEmailModalOpen: boolean
  onEmailModalClose: () => void
  workspaceData: any
  vehicleServiceId: string
  status: string
  email: string
}) => {
  const mutateNotification = useSendNotification()
  const { showCustomToast } = useCustomToast()
  const isMobile = useIsMobile()
  const initialValues = {
    email: email,
    card: false,
    deliveryTime: null,
    address: status === 'ready_to_return' ? '' : null,
    note: null,
  }

  const handleFormSubmit = (values: any) => {
    const time = new Date(values.deliveryAt)
    mutateNotification.mutate(
      { notificationData: {...values, deliveryAt: `${time.getHours()}:${time.getMinutes()}`}, vehicleServiceId: vehicleServiceId },
      {
        onSuccess: () => {
          showCustomToast({
            status: 'success',
            title: 'Email odoslaný',
            label: <div>Email bol úspešne odoslaný</div>,
          })
        },
        onError: () => {
          showCustomToast({
            status: 'error',
            title: 'Email nebol odoslaný',
            label: <div>Email sa nepodarilo odoslať</div>,
          })
        },
      },
    )
    onEmailModalClose()
  }

  return (
    <Modal
      size={{ sm: 'full', md: '2xl' }}
      isOpen={isEmailModalOpen}
      onClose={onEmailModalClose}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display={'flex'} alignItems={'center'} p={0}>
          {isMobile ? (
            <Grid w={'100%'} placeItems={'center'} h={'4rem'}>
              odoslanie emailu
            </Grid>
          ) : (
            <>
              <EmailIcon fontSize={'1.6rem'} color={colors.blue[500]} m={'0 6rem 0 2rem'} />
              odoslanie emailu
              <Close
                fontSize={'1.6rem'}
                bg={colors.red[500]}
                ml={'auto'}
                cursor={'pointer'}
                onClick={() => onEmailModalClose()}
                p={4}
              />
            </>
          )}
        </ModalHeader>

        <ModalBody>
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={EmailShareSchema}
          >
            {({ values, errors, touched, setFieldValue, submitForm, setFieldTouched }) => {
              return (
                <Form>
                  <Grid
                    gridTemplateColumns={{ sm: 'initial', md: 'repeat(2, 1fr)' }}
                    p={'1rem'}
                    gap={'4rem'}
                  >
                    <Grid gap={'1rem'}>
                      <Field
                        name="email"
                        label="e-mail"
                        type="email"
                        component={Input}
                        status={getFieldStatus(errors, touched, 'email')}
                      />

                      {status !== 'received' && status !== 'returned' && (
                        <>
                          <Field
                            bg={colors.blue[100]}
                            name="deliveryTime"
                            label={'dátum pre prevzatie vozidla'}
                            type="date"
                            setFieldValue={setFieldValue}
                            component={Input}
                            status={getFieldStatus(errors, touched, 'deliveryTime')}
                          />
                        </>
                      )}
                      {status === 'ready_to_return' && (
                        <>
                          <Field
                            isDisabled={values.deliveryTime === null}
                            bg={colors.blue[100]}
                            name="deliveryAt"
                            label={'čas pre prevzatie vozidla'}
                            type="time"
                            setFieldValue={setFieldValue}
                            component={Input}
                            status={getFieldStatus(errors, touched, 'deliveryAt', true, values)}
                          />
                          <Flex
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            fontSize={'0.8rem'}
                            fontWeight={'semibold'}
                          >
                            je možné platiť kartou
                            <Field name="card" isChecked={values.card} component={Switch} />
                          </Flex>
                          <Field
                            name="address"
                            label="adresa"
                            type="text"
                            component={Input}
                            status={getFieldStatus(errors, touched, 'address')}
                          />
                        </>
                      )}
                    </Grid>
                    <Field
                      name="note"
                      label={'poznámka'}
                      setFieldValue={setFieldValue}
                      component={Textarea}
                      helperText={'Obsah sa zobrazí majiteľovi vozidla pri zdieľaní'}
                    />
                  </Grid>
                  <Flex
                    justifyContent={'space-evenly'}
                    w={{ sm: '100%', md: '50%' }}
                    m={'auto'}
                    mt={'1rem'}
                  >
                    <IconButton
                      size={'half'}
                      variant={'blue'}
                      icon={<Cancel />}
                      onClick={onEmailModalClose}
                    />
                    <IconButton
                      size={'half'}
                      variant={'green'}
                      icon={<ShareArrow />}
                      onClick={() =>
                        ToastHelper({
                          submitForm,
                          errors,
                          setFieldTouched,
                          labels: EmailModalFormLabels,
                          showCustomToast,
                        })
                      }
                    />
                  </Flex>
                </Form>
              )
            }}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default EmailModal
