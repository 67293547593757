import { Divider, Flex, Grid, useDisclosure } from '@chakra-ui/react'
import DownChevronIcon from '../icons/DownChevronIcon'
import { colors } from '../theme'
import Calendar from '../icons/Calendar'
import Button from './Button'
import { Add } from '../icons/Add'
import { format } from 'date-fns'
import { useState } from 'react'
import { Field } from 'formik'
import { Input } from './Input'
import getFieldStatus from '../model/helpers/StatusHelper'
import Delete from '../icons/Delete'
import { useDeleteMOT } from '../model/api/useDeleteMOT'

const MOTdropdown = ({
  vehicleMOT,
  mode,
  setFieldValue,
  errors,
  touched,
  values,
}: {
  vehicleMOT?: any
  mode: string
  setFieldValue?: any
  errors?: any
  touched?: any
  values?: any
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [count, setCount] = useState<string[]>([])
  const { mutate: deleteMOT } = useDeleteMOT()
  const handleOpenAndClose = () => {
    if (isOpen) {
      onClose()
    }
    if (!isOpen) {
      onOpen()
    }
  }
  return (
    <>
      <Flex
        w={'100%'}
        h={'3rem'}
        bg={colors.gray[700]}
        color={colors.blue[100]}
        justifyContent={'space-between'}
        onClick={handleOpenAndClose}
        cursor={'pointer'}
      >
        <Grid placeItems={'center'} w={'80%'} fontSize={'0.8rem'} fontWeight={'semibold'}>
          STK
        </Grid>
        <Grid placeItems={'center'} w={'20%'}>
          <DownChevronIcon fontSize={'1rem'} transform={isOpen && 'rotate(180deg)'} />
        </Grid>
      </Flex>
      {isOpen && (
        <Flex flexFlow={'column'} gap={'0.8rem'} w={{ sm: '100%', md: 'initial' }}>
          {mode === 'edit' &&
            vehicleMOT &&
            vehicleMOT.map((item: any, index: number) => {
              return (
                <>
                  <Field
                    bg={colors.blue[100]}
                    name={`vehicleMOT[${index}].validFrom`}
                    label={'platnosť od'}
                    type="date"
                    setFieldValue={setFieldValue}
                    component={Input}
                    status={getFieldStatus(errors, touched, `vehicleMOT[${index}].validFrom`)}
                  />
                  <Field
                    bg={colors.blue[100]}
                    name={`vehicleMOT[${index}].validTo`}
                    label={'platnosť do'}
                    type="date"
                    setFieldValue={setFieldValue}
                    component={Input}
                    status={getFieldStatus(errors, touched, `vehicleMOT[${index}].validTo`)}
                  />
                  <Button variant={'red'} size={'full'} onClick={() => deleteMOT(item.id)}>
                    <Delete />
                  </Button>
                  <Divider
                    borderWidth={'0.2rem 0'}
                    borderColor={colors.gray[700]}
                    bg={colors.gray[700]}
                  />
                </>
              )
            })}

          {count.map((item: any, index) => {
            return (
              <>
                <Field
                  bg={colors.blue[100]}
                  name={`newVehicleMOT[${index}].validFrom`}
                  label={'platnosť od'}
                  type="date"
                  setFieldValue={setFieldValue}
                  component={Input}
                  status={getFieldStatus(errors, touched, `newVehicleMOT[${index}].validFrom`)}
                />
                <Field
                  bg={colors.blue[100]}
                  name={`newVehicleMOT[${index}].validTo`}
                  label={'platnosť do'}
                  type="date"
                  setFieldValue={setFieldValue}
                  component={Input}
                  status={getFieldStatus(errors, touched, `newVehicleMOT[${index}].validTo`)}
                />
                <Button
                  variant={'red'}
                  size={'full'}
                  onClick={() => {
                    setCount((prev) => prev.filter((item, i) => i !== index))
                    const updatedNewVehicleMOT = [...values[`newVehicleMOT`]]
                    updatedNewVehicleMOT.splice(index, 1)
                    setFieldValue(`newVehicleMOT`, updatedNewVehicleMOT)
                  }}
                >
                  <Delete />
                </Button>
                {index !== count.length - 1 && (
                  <Divider
                    borderWidth={'0.2rem 0'}
                    borderColor={colors.gray[700]}
                    bg={colors.gray[700]}
                  />
                )}
              </>
            )
          })}

          {mode === 'viewOnly' &&
            vehicleMOT &&
            vehicleMOT.map((item: any, index: number) => {
              return (
                <>
                  <Grid templateColumns={'repeat(2, 1fr)'} gap={3}>
                    <Flex
                      h={'3rem'}
                      bg={colors.blue[100]}
                      justifyContent={'space-around'}
                      alignItems={'center'}
                      fontSize={'0.8rem'}
                      fontWeight={'semibold'}
                    >
                      {item.validFrom && (
                        <>
                          <Calendar fontSize={'1rem'} color={colors.blue[500]} />
                          {format(new Date(item.validFrom), 'dd.MM.yyyy')}
                        </>
                      )}
                    </Flex>
                    <Flex
                      h={'3rem'}
                      bg={colors.blue[100]}
                      justifyContent={'space-around'}
                      alignItems={'center'}
                      fontSize={'0.8rem'}
                      fontWeight={'semibold'}
                    >
                      {item.validTo && (
                        <>
                          <Calendar fontSize={'1rem'} color={colors.blue[500]} />
                          {format(new Date(item.validTo), 'dd.MM.yyyy')}
                        </>
                      )}
                    </Flex>
                  </Grid>
                  {vehicleMOT.length - 1 !== index && (
                    <Divider
                      borderWidth={'0.2rem 0'}
                      borderColor={colors.gray[700]}
                      bg={colors.gray[700]}
                    />
                  )}
                </>
              )
            })}

          {mode !== 'viewOnly' && (
            <Button
              size={'full'}
              variant={'blue'}
              onClick={() => setCount((prev) => [...prev, 'new'])}
            >
              <Add fontSize={'1rem'} /> pridať STK
            </Button>
          )}
        </Flex>
      )}
    </>
  )
}

export default MOTdropdown
