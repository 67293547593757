import { Flex, Grid } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { IS_REQUIRED } from '../../../model/constants/ValidationMessages'
import IRegister from '../../../model/interface/IRegister'
import { colors } from '../../../theme'
import ProgressBar from '../../../components/ProgressBar'
import { Input } from '../../../components/Input'
import getFieldStatus from '../../../model/helpers/StatusHelper'
import Textarea from '../../../components/Textarea'
import Switch from '../../../components/Switch'
import Menu from '../../../components/Menu'
import ConfirmModal from '../../../components/ConfirmModal'
import { RegisterStep2Schema } from '../../../model/schemas/RegisterStep2Schema'
import useInitialValuesRegisterStep2 from '../../../model/initialValues/useInitialValuesRegisterStep2'
import { Box } from '../../../components/Box'
import { useCustomToast } from '../../../components/Toast'

const RegisterStep2Mobile: React.FC<IRegister> = ({
  registerData,
  onSetRegisterData,
  onSetRegisterStep,
}) => {
  const handleFormSubmit = (values: Yup.InferType<typeof RegisterStep2Schema>) => {
    onSetRegisterStep((prev) => prev + 1)
  }
  const { showCustomToast, setIsHovering, onCloseHandler } = useCustomToast()

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target as HTMLInputElement
    if (name === 'phone') {
      const phone = value.replace(/\s/g, '')
      onSetRegisterData({
        ...registerData,
        [name]: phone,
      })
    } else if (name === 'showOnWeb') {
      onSetRegisterData({
        ...registerData,
        workspaceMarketingData: {
          ...registerData.workspaceMarketingData,
          [name]: value === 'true' ? false : true,
        },
      })
    } else {
      onSetRegisterData({
        ...registerData,
        workspaceMarketingData: {
          ...registerData.workspaceMarketingData,
          [name]: value,
        },
      })
    }
  }

  const { initialValues } = useInitialValuesRegisterStep2({ registerData })

  return (
    <>
      <Flex
        w={'100%'}
        h={'5rem'}
        bg={colors.gray[700]}
        flexFlow={'column nowrap'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Flex w={'25rem'} flexFlow={'column nowrap'} alignItems={'center'}>
          <ProgressBar step={1} />
        </Flex>
      </Flex>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={RegisterStep2Schema}
      >
        {({ errors, touched, submitForm }) => {
          return (
            <>
              <ConfirmModal
                title={'Chcete, aby sa Váš servis zobrazil na webových stránkach VIVEM?'}
                onSetRegisterStep={onSetRegisterStep}
              />
              <Form
                style={{
                  width: '100%',
                  height: '100%',
                  overflow: 'auto',
                  background: colors.blue[50],
                }}
              >
                <Grid
                  w={'85%'}
                  justifyItems={'center'}
                  gap={'1rem'}
                  onChange={handleFieldChange}
                  m={'2rem auto 4rem auto'}
                >
                  <Field
                    name="title"
                    label="webový názov"
                    type="text"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'title')}
                  />
                  <Field
                    name="phone"
                    label="telefónne číslo"
                    type="text"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'phone')}
                  />
                  <Field
                    name="url"
                    label="URL webstránky"
                    type="text"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'url')}
                  />
                  <Flex
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    fontSize={'0.8rem'}
                    w={'100%'}
                    fontWeight={'semibold'}
                  >
                    zverejniť na stránke ViVeM{' '}
                    <Box
                      h={'unset'}
                      onClick={
                        registerData.address === null ||
                        registerData.address?.city === null ||
                        registerData.address?.street === null
                          ? () => {
                              setIsHovering(true)
                              showCustomToast({
                                status: 'warning',
                                title: 'Nie je možné zverejniť na stránke ViVeM',
                                label: (
                                  <div>
                                    Vo Vašom pracovisku je nutné najprv uviesť mesto a ulicu Vašeho
                                    pracoviska.
                                  </div>
                                ),
                              })
                            }
                          : undefined
                      }
                    >
                      <Field
                        name="showOnWeb"
                        isChecked={registerData.workspaceMarketingData.showOnWeb}
                        isDisabled={
                          registerData.address === null ||
                          registerData.address?.city === null ||
                          registerData.address?.street === null
                        }
                        component={Switch}
                      />
                    </Box>
                  </Flex>
                  <Field
                    name="description"
                    label={'text na webstránke'}
                    component={Textarea}
                    onChange={handleFieldChange}
                  />
                </Grid>
              </Form>
              <Menu
                type={'arrowsOnly'}
                back={() => onSetRegisterStep((prev) => prev - 1)}
                next={submitForm}
              />
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default RegisterStep2Mobile
