import { useMutation } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useUpdateVehicleOwnerNotes = () => {
  return useMutation(
    ({
      noteContent,
      id,
    }: {
      noteContent: {content: string}
      id: string
    }) => api.put(`${ApiRouteEnum.VEHICLE_OWNER_NOTE}/${id}`, noteContent),
    {
      mutationKey: [ApiRouteEnum.VEHICLE_OWNER_NOTE],
    },
  )
}

export default useUpdateVehicleOwnerNotes
