import { useMutation } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

export const useAddVehicleOwner = (successCallback: () => void, errorCallback: () => void) => {
  return useMutation((userData: any) => api.post(`${ApiRouteEnum.VEHICLE_OWNER}`, userData), {
    mutationKey: [ApiRouteEnum.VEHICLE_OWNER],
    onSuccess: () => {
      successCallback()
    },
    onError: () => {
      errorCallback()
    },
  })
}
