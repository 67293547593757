import { useQuery } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useGetCar = (id?: string) => {
  const { data, isLoading } = useQuery([ApiRouteEnum.VEHICLE, id], async () => {
    if (id) {
      const response = await api.get(`${ApiRouteEnum.VEHICLE}/${id}`)
      return response.data
    }
    return null
  })
  return { data, isLoading }
}

export default useGetCar
