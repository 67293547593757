import { Switch as ChakraSwitch } from '@chakra-ui/react'
import Tick from '../icons/Tick'
import Close from '../icons/Close'
import { colors } from '@mui/material'
import { is } from 'date-fns/locale'
interface SwitchProps {
  field?: {
    name: string
    value?: string | number
    onBlur: () => void
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  }
  variant?: string
  isChecked?: boolean
}

const Switch = ({ field, variant, isChecked, ...otherProps }: SwitchProps) => {
  if (variant === 'alwaysOn') {
    return (
      <ChakraSwitch
        isChecked={(field && field.value?.toString() === 'true')}
        size="lg"
        style={{ position: 'relative' }}
        {...field}
        id={field && field.name}
        {...otherProps}
      >
        <Tick
          style={{
            color: colors.blue[500],
            position: 'absolute',
            left: '40%',
            top: '7%',
            transform: 'translateX(-40%)',
          }}
          fontSize={'xl'}
        />
      </ChakraSwitch>
    )
  }
  return (
    <ChakraSwitch
      isChecked={field && field.value?.toString() === 'true' || isChecked}
      size="lg"
      id={field && field.name}
      style={{ position: 'relative' }}
      {...field}
      {...otherProps}
    >
      <Tick
        style={{
          color: 'white',
          position: 'absolute',
          left: '5%',
          top: '50%',
          transform: 'translate(5%, -50%)',
        }}
        fontSize={'1rem'}
      />
      <Close
        style={{
          position: 'absolute',
          left: '35%',
          top: '50%',
          transform: 'translate(75%, -50%)',
          color: 'white',
        }}
        fontSize={'1rem'}
      />
    </ChakraSwitch>
  )
}

export default Switch
