import { useMutation } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useUpdateShareSettings = () => {
  return useMutation(
    ({
      updateShareSettings,
      id,
    }: {
      updateShareSettings: { totalPrice: boolean; priceItems: boolean }
      id: string
    }) => api.post(`${ApiRouteEnum.SHARE_SETTINGS}/${id}`, updateShareSettings),
    {
      mutationKey: [ApiRouteEnum.SHARE_SETTINGS],
    },
  )
}

export default useUpdateShareSettings
