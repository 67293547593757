import * as Yup from 'yup'
import { IS_REQUIRED } from '../constants/ValidationMessages'
export const serviceFormSchema = Yup.object({
  displayName: Yup.string().required(IS_REQUIRED),
  mileageAtReceive: Yup.number().nullable(),
  mileageAtReturn: Yup.number().nullable(),
  receivedAt: Yup.string().required(IS_REQUIRED),
  returnedAt: Yup.string().nullable(),
  estimatedServiceTime: Yup.number().nullable(),
  vehicle: Yup.object({
    label: Yup.string().required(IS_REQUIRED),
    value: Yup.string().required(IS_REQUIRED),
  }).required(IS_REQUIRED),
  vehicleOwnerDescription: Yup.object({
    description: Yup.string().nullable(),
  }).nullable(),
  vehicleMaterialDescription: Yup.object({
    description: Yup.string().nullable(),
    checkList: Yup.array(
      Yup.object({
        label: Yup.string().required(IS_REQUIRED),
        isChecked: Yup.boolean().required(IS_REQUIRED),
      }),
    ).nullable(),
  }).nullable(),
  vehicleWorkDescription: Yup.object({
    description: Yup.string().nullable(),
    checkList: Yup.array(
      Yup.object({
        label: Yup.string().required(IS_REQUIRED),
        isChecked: Yup.boolean().required(IS_REQUIRED),
      }),
    ).nullable(),
  }).nullable(),
  price: Yup.object({
    totalPrice: Yup.number().required(IS_REQUIRED),
    displayName: Yup.string().required(IS_REQUIRED).nullable(),
    description: Yup.string().nullable(),
    priceItems: Yup.array(
      Yup.object({
        displayName: Yup.string().required(IS_REQUIRED),
        price: Yup.number().required(IS_REQUIRED),
      }),
    ),
  }).nullable(),
  photosAtReceiveIds: Yup.array(
    Yup.object({
      id: Yup.string(),
    }),
  ).nullable(),
  photosAtReturnIds: Yup.array(
    Yup.object({
      id: Yup.string(),
    }),
  ).nullable(),
  estimatedReturnedAt: Yup.string().nullable(),
  status: Yup.object({
    label: Yup.string(),
    value: Yup.string(),
  }).required(IS_REQUIRED),
  priceItemsChecked: Yup.boolean().required(IS_REQUIRED),
  serviceCheckList: Yup.boolean().required(IS_REQUIRED),
  materialCheckList: Yup.boolean().required(IS_REQUIRED),
  imageObjectService: Yup.array(Yup.object()).nullable(),
  imageObjectCustomer: Yup.array(Yup.object()).nullable(),
})
