import { Button, Flex } from '@chakra-ui/react'
import { colors } from '../theme'
import { Box } from './Box'
import PersonIcon from '../icons/PersonIcon'
import TurnOffIcon from '../icons/TurnOffIcon'
import IndustryIcon from '../icons/IndustryIcon'
import { useNavigate } from 'react-router-dom'
import RouterEnum from '../model/enums/RouterEnum'
import useGetWorkspaceById from '../model/api/useGetWorkspaceById'
import Spinner from './Spinner'

const Header = ({ data }: { data: any }) => {
  const navigate = useNavigate()
  const { data: workspaceData, isLoading } = useGetWorkspaceById(data.workspace.id)
  const handleLogoutClick = () => {
    localStorage.setItem('token', '')
    navigate(RouterEnum.LOGIN)
  }
  return (
    <Flex
      w={'100%'}
      h={'5rem'}
      borderBottom={`3px solid ${colors.blue[500]}`}
      alignItems={'center'}
      bg={colors.blue[100]}
    >
      <Flex m={'0 2rem'} justifyContent={'space-evenly'} alignItems={'center'}>
        <PersonIcon color={colors.blue[500]} mr={'5px'} />
        <Flex flexFlow={'column nowrap'} fontSize={'0.7rem'}>
          <Box fontWeight={'semibold'}>Užívateľ:</Box>
          {data && (
            <Box>
              {data.firstname} {data.lastname}
            </Box>
          )}
        </Flex>
      </Flex>
      <Flex m={'0 2rem'} justifyContent={'space-evenly'} alignItems={'center'} mr={'auto'}>
        <IndustryIcon color={colors.blue[500]} style={{ marginRight: '5px' }} />
        <Flex flexFlow={'column nowrap'} fontSize={'0.7rem'}>
          {isLoading ? (
            <Spinner h={'1rem'} w={'1rem'} thickness={'4px'} />
          ) : (
            <>
              <Box fontWeight={'semibold'}>Pracovisko:</Box>
              <Box>{data && workspaceData && workspaceData.name}</Box>
            </>
          )}
        </Flex>
      </Flex>
      <Button
        w={'7rem'}
        h={'3rem'}
        variant={'blue'}
        flexFlow={'column'}
        m={'0 2rem'}
        onClick={handleLogoutClick}
      >
        <TurnOffIcon fontSize={'1rem'} />
        odhlásiť sa
      </Button>
    </Flex>
  )
}

export default Header
