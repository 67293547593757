import { useNavigate, useOutletContext } from 'react-router-dom'
import RouterEnum from '../../model/enums/RouterEnum'
import useGetWorkspaceQuotas from '../../model/api/useGetWorkspaceQuotas'
import { Box } from '../../components/Box'
import { Flex, Grid, useDisclosure } from '@chakra-ui/react'
import Button from '../../components/Button'
import CarIcon from '../../icons/CarIcon'
import WorkspaceCard from '../../components/WorkspaceCard'
import PersonIcon from '../../icons/PersonIcon'
import PaperPlaneIcon from '../../icons/PaperPlaneIcon'
import CloudIcon from '../../icons/CloudIcon'
import GlobeIcon from '../../icons/GlobeIcon'
import Edit from '../../icons/Edit'
import AddBoxIcon from '../../icons/AddBoxIcon'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody } from '@chakra-ui/react'
import Close from '../../icons/Close'
import { colors } from '../../theme'
import ShareIcon from '../../icons/ShareIcon'
import WorkspaceMarketingForm from './WorkspaceMarketingForm'
import useGetWorkspaceById from '../../model/api/useGetWorkspaceById'
import WorkspaceDataForm from './WorkspaceDataForm'
import { useCustomToast } from '../../components/Toast'
import Spinner from '../../components/Spinner'
import ApiCallIcon from '../../icons/ApiCallIcon'

const WorkspaceOverview = () => {
  const data = useOutletContext() as any
  const navigate = useNavigate()
  const { showCustomToast, onCloseHandler } = useCustomToast()
  const {
    isOpen: isMarketingModalOpen,
    onOpen: onMarketingModalOpen,
    onClose: onMarketingModalClose,
  } = useDisclosure()

  const {
    isOpen: isWorkspaceDataModalOpen,
    onOpen: onWorkspaceDataModalOpen,
    onClose: onWorkspaceDataModalClose,
  } = useDisclosure()

  const { data: workspaceDataQuotas } = useGetWorkspaceQuotas(data.workspace.id)
  const { data: workspaceData } = useGetWorkspaceById(data.workspace.id)
  if (!workspaceDataQuotas) {
    return <Spinner />
  }

  return (
    <Flex
      w={'100%'}
      h={'100%'}
      alignItems={'center'}
      flexFlow={'column'}
      overflow={'auto'}
      padding={'1%'}
    >
      <Box w={'75%'} mt={'1rem'}>
        <Box
          fontSize={'1.8rem'}
          fontWeight={'semibold'}
          w={'100%'}
          textAlign={'center'}
          mb={'2rem'}
        >
          Nastavenie pracoviska
        </Box>
        <Grid gridTemplateColumns={'repeat(3, 1fr)'} mt={'2rem'} gap={'1rem'}>
          <WorkspaceCard
            icon={<CarIcon />}
            current={workspaceDataQuotas.vehicle.current}
            maximum={workspaceDataQuotas.vehicle.maximum}
            label={'počet vozidiel'}
          />
          <WorkspaceCard
            icon={<PersonIcon />}
            current={workspaceDataQuotas.users.current}
            maximum={workspaceDataQuotas.users.maximum}
            label={'počet užívateľov'}
          />
          <WorkspaceCard
            icon={<PaperPlaneIcon />}
            current={workspaceDataQuotas.emails.current}
            maximum={workspaceDataQuotas.emails.maximum}
            label={'počet emailov'}
          />
          <WorkspaceCard
            icon={<CloudIcon />}
            current={workspaceDataQuotas.diskSize.current}
            maximum={workspaceDataQuotas.diskSize.maximum}
            label={'množstvo dát'}
          />
          <WorkspaceCard
            icon={<ApiCallIcon />}
            current={workspaceDataQuotas.apiCalls.current}
            maximum={workspaceDataQuotas.apiCalls.maximum}
            label={'API volania'}
          />
        </Grid>
        <Flex w={'100%'} mt={'1rem'} columnGap={'1rem'} justifyContent={'center'}>
          <Grid gap={'1rem'} w={'30%'}>
            <Button
              variant={'basic'}
              size="medium"
              fontSize={'0.8rem'}
              w={'100%'}
              onClick={onMarketingModalOpen}
            >
              <GlobeIcon />
              <Box w={'70%'} whiteSpace={'pre-wrap'}>
                nastavenia zobrazenia na webe ViVeM
              </Box>
            </Button>
            <Button
              variant={'basic'}
              size="medium"
              fontSize={'0.8rem'}
              w={'100%'}
              onClick={() => navigate(RouterEnum.EMPLOYEES_MANAGEMENT)}
            >
              <PersonIcon />
              <Box w={'70%'} whiteSpace={'pre-wrap'}>
                management užívateľov
              </Box>
            </Button>
          </Grid>
          <Grid gap={'1rem'} w={'30%'}>
            <Button
              variant={'basic'}
              size="medium"
              fontSize={'0.8rem'}
              w={'100%'}
              onClick={onWorkspaceDataModalOpen}
            >
              <Edit />
              <Box w={'70%'} whiteSpace={'pre-wrap'}>
                zmeniť údaje o pracovisku
              </Box>
            </Button>

            <Button
              variant={'blue'}
              size="medium"
              fontSize={'0.8rem'}
              w={'100%'}
              onClick={() =>
                showCustomToast({
                  status: 'warning',
                  title: 'Nie je možné zmeniť limity',
                  label: <div>Táto funkcionalita zatiaľ nie je k dispozícii</div>,
                })
              }
            >
              <AddBoxIcon />
              <Box w={'70%'} whiteSpace={'pre-wrap'}>
                zvýšiť limity
              </Box>
            </Button>
          </Grid>
        </Flex>
      </Box>

      <Modal
        size={'xl'}
        isOpen={isMarketingModalOpen}
        onClose={onMarketingModalClose}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display={'flex'} alignItems={'center'} p={0}>
            <ShareIcon fontSize={'1.6rem'} color={colors.blue[500]} m={'0 6rem 0 2rem'} />
            nastavenie workspace prezentácie
            <Close
              fontSize={'1.6rem'}
              bg={colors.red[500]}
              ml={'auto'}
              cursor={'pointer'}
              onClick={() => onMarketingModalClose()}
              p={4}
            />
          </ModalHeader>

          <ModalBody>
            <WorkspaceMarketingForm
              workspaceData={workspaceData}
              onMarketingModalClose={onMarketingModalClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        size={'xl'}
        isOpen={isWorkspaceDataModalOpen}
        onClose={onWorkspaceDataModalClose}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display={'flex'} alignItems={'center'} p={0}>
            <ShareIcon fontSize={'1.6rem'} color={colors.blue[500]} m={'0 6rem 0 2rem'} />
            zmeniť workspace údaje
            <Close
              fontSize={'1.6rem'}
              bg={colors.red[500]}
              ml={'auto'}
              cursor={'pointer'}
              onClick={() => onWorkspaceDataModalClose()}
              p={4}
            />
          </ModalHeader>

          <ModalBody>
            <WorkspaceDataForm
              workspaceData={workspaceData}
              onWorkspaceDataModalClose={onWorkspaceDataModalClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default WorkspaceOverview
