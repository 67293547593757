import * as Yup from 'yup'
import { IS_EMAIL, IS_REQUIRED } from '../constants/ValidationMessages'
export const EmailShareSchema = Yup.object({
  email: Yup.string()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, IS_EMAIL)
    .required(IS_REQUIRED),
  card: Yup.boolean(),
  deliveryTime: Yup.string().nullable(),
  address: Yup.string().required(IS_REQUIRED).nullable(),
  note: Yup.string().nullable(),
})
