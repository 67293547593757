import { useMutation, useQueryClient } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

export const useForgottenPassword = () => {
  const query = useQueryClient()
  return useMutation(
    (email: string) => api.post(`${ApiRouteEnum.FORGOTTEN_PASSWORD}`, { email: email }),
    {
      mutationKey: [ApiRouteEnum.FORGOTTEN_PASSWORD],
      onSuccess: () => {
        query.invalidateQueries(ApiRouteEnum.FORGOTTEN_PASSWORD)
      },
    },
  )
}
