import { useMutation, useQueryClient } from 'react-query'
import ApiRouteEnum from '../enums/ApiRouteEnum'
import api from './api'

export const useDeleteVehicleOwnerNote = () => {
  const query = useQueryClient()
  return useMutation((id) => api.delete(`${ApiRouteEnum.VEHICLE_OWNER_NOTE}/${id}`), {
    onSuccess: (data) => {
      query.invalidateQueries(ApiRouteEnum.VEHICLE_OWNER_NOTE)
      query.invalidateQueries(ApiRouteEnum.VEHICLE_OWNER)
    },
  })
}
