import * as Yup from 'yup'
import { IS_EMAIL, IS_PASSWORD, IS_REQUIRED } from '../constants/ValidationMessages'
export const LoginFormSchema = Yup.object({
  email: Yup.string()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, IS_EMAIL)
    .required(IS_REQUIRED),
  password: Yup.string()
    .required(IS_REQUIRED)
    .min(8, IS_PASSWORD),
})
