import { useQuery } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useGetImage = (id: string) => {
  const { data, isLoading } = useQuery(ApiRouteEnum.IMAGE, async () => {
    if(!id) return null
    const response = await api.get(`${ApiRouteEnum.IMAGE}/${id}`)
    return response.data
  })
  return { data, isLoading }
}

export default useGetImage
