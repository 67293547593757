import { useMutation } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useAddVehicleOwnerNotes = () => {
  return useMutation(
    ({ noteData }: { noteData: { content: string; vehicleOwnerId: string } }) =>
      api.post(`${ApiRouteEnum.VEHICLE_OWNER_NOTE}`, noteData),
    {
      mutationKey: [ApiRouteEnum.VEHICLE_OWNER_NOTE],
    },
  )
}

export default useAddVehicleOwnerNotes
