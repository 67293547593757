import { useMutation, useQueryClient } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

export const useSendNotification = () => {
  const query = useQueryClient()
  return useMutation(
    ({ notificationData, vehicleServiceId }: { notificationData: any; vehicleServiceId: string }) =>
      api.post(
        `${ApiRouteEnum.VEHICLE_SERVIS}/${vehicleServiceId}${ApiRouteEnum.NOTIFICATION}`,
        notificationData,
      ),
  )
}
