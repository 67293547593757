import axios from 'axios'
import RouterEnum from '../enums/RouterEnum'

const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('token') !== '' && localStorage.getItem('token') !== null) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && window.location.pathname !== RouterEnum.LOGIN) {
      window.location.href = RouterEnum.LOGIN
      return
    }

    if (error.response.status === 404) {
      window.location.href = '/not-found'
    }

    return Promise.reject(error)
  },
)

export default api
