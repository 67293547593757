import { useQuery } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useGetServices = (id?: string) => {
  const { data, isLoading } = useQuery(ApiRouteEnum.VEHICLE_SERVIS, async () => {
    const response = await api.get(`${ApiRouteEnum.VEHICLE_SERVIS}${id ? `?vehicleId=${id}` : ''}`)
    return response.data
  })
  return { data, isLoading }
}

export default useGetServices
