import { useQueryClient } from 'react-query'
import { useCustomToast } from '../../../components/Toast'
import { useDeleteCar } from '../../../model/api/useDeleteCar'
import { useLocation, useNavigate } from 'react-router-dom'
import { Flex, Grid, useDisclosure } from '@chakra-ui/react'
import { Box } from '../../../components/Box'
import { colors } from '../../../theme'
import CarIcon from '../../../icons/CarIcon'
import { DisabledInput } from '../../../components/DisabledInput'
import { Input } from '../../../components/Input'
import Textarea from '../../../components/Textarea'
import { Select } from '../../../components/Select'
import MOTdropdown from '../../../components/MOTdropdown'
import HighwaySignsDropdown from '../../../components/HighwaySignsDropdown'
import InsuranceDropdown from '../../../components/InsuranceDropdown'
import RouterEnum from '../../../model/enums/RouterEnum'
import DeleteModal from '../../../components/DeleteModal'
import ApiRouteEnum from '../../../model/enums/ApiRouteEnum'
import CreateServiceModal from '../../../components/CreateServiceModal'
import Spinner from '../../../components/Spinner'
import Menu from '../../../components/Menu'
import Button from '../../../components/Button'
import BottomSideMenu from '../../../components/BottomSideMenu '

const VehicleDetailMobile = () => {
  const query = useQueryClient()
  const { showCustomToast } = useCustomToast()
  const mutateDelete = useDeleteCar()
  const navigate = useNavigate()
  const location = useLocation()
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()
  const {
    isOpen: isConfirmModalOpen,
    onOpen: onConfirmModalOpen,
    onClose: onConfirmModalClose,
  } = useDisclosure()
  const car = location.state.car

  if (!car) {
    return (
      <>
        <Grid
          fontSize={'1.2rem'}
          fontWeight={'semibold'}
          w={'100%'}
          h={'4rem'}
          placeItems={'center'}
          bg={colors.gray[700]}
          color={colors.blue[100]}
          zIndex={100}
          position={'sticky'}
          top={'0px'}
        >
          Prehľad vozidiel v evidencii
        </Grid>
        <Grid h={'40rem'}>
          <Spinner />
        </Grid>
        <Menu type={'basic'} />
      </>
    )
  }
  return (
    <>
      <Grid
        fontSize={'1.2rem'}
        fontWeight={'semibold'}
        w={'100%'}
        h={'4rem'}
        placeItems={'center'}
        bg={colors.gray[700]}
        color={colors.blue[100]}
        zIndex={100}
        position={'sticky'}
        top={'0px'}
      >
        Prehľad vozidiel v evidencii
      </Grid>
      <Flex
        h={'100%'}
        alignItems={'center'}
        flexFlow={'column'}
        overflow={'auto'}
        pb={'10rem'}
        bg={colors.blue[50]}
      >
        <Flex w={'90%'} m={'2rem 0 1rem 0'} justifyContent={'flex-start'} alignItems={'center'}>
          <Grid w={'3rem'} h={'3rem'} bg={colors.gray[700]} placeItems={'center'}>
            <CarIcon color={colors.blue[500]} />
          </Grid>
          <Box m={'0 auto'} fontSize={'1rem'} textAlign={'center'} fontWeight={'semibold'}>
            {car.displayName}
          </Box>
        </Flex>
        <Button
          variant={'transparent'}
          onClick={() =>
            navigate('/' + RouterEnum.VEHICLE_SERVIS_OVERVIEW, { state: { car: car.id } })
          }
          mb={'0.8rem'}
        >
          servisná história
        </Button>
        <Flex w={'90%'} flexFlow={'column wrap'} gap={'1rem'}>
          <DisabledInput
            bg={colors.blue[100]}
            name="vinCode"
            defaultValue={car.vinCode}
            label={'VIN'}
            component={Input}
          />
          <DisabledInput
            bg={colors.blue[100]}
            name="licensePlate"
            defaultValue={car.licensePlate ? car.licensePlate : '-'}
            label={'SPZ'}
            component={Input}
          />
          <DisabledInput
            bg={colors.blue[100]}
            name="brand"
            defaultValue={car.brand}
            label={'značka / výrobca vozidla'}
            component={Input}
          />
          <DisabledInput
            bg={colors.blue[100]}
            name="model"
            defaultValue={car.model}
            label={'model vozidla'}
            component={Input}
          />
          <DisabledInput
            bg={colors.blue[100]}
            name="engineModel"
            defaultValue={car.engineModel ? car.engineModel : '-'}
            label={'model motoru vozidla'}
            component={Input}
          />
          <DisabledInput
            bg={colors.blue[100]}
            name="color"
            defaultValue={car.color ? car.color : '-'}
            label={'farba vozidla'}
            component={Input}
          />
          <DisabledInput
            bg={colors.blue[100]}
            name="manufacturedAt"
            defaultValue={new Date(car.manufacturedAt).getFullYear()}
            label={'rok výroby'}
            component={Input}
          />
        </Flex>
        <Flex w={'90%'} flexFlow={'column wrap'} mt={'1rem'} gap={'1rem'}>
          <Textarea isReadOnly label={'Poznámky'} placeholder={car.description} />
          <Select
            label={'Majiteľ vozidla'}
            title={`${car.vehicleOwner.firstname} ${car.vehicleOwner.lastname}`}
            isReadOnly={true}
          />
          {car.vehicleMOT && <MOTdropdown mode={'viewOnly'} vehicleMOT={car.vehicleMOT} />}
          {car.highwaySigns && (
            <HighwaySignsDropdown mode={'viewOnly'} highwaySigns={car.highwaySigns} />
          )}
          {car.insurance && (
            <InsuranceDropdown mode={'viewOnly'} vehicleInsurance={car.insurance} />
          )}
        </Flex>
        <Menu back={() => navigate(-1)} type={'basic'} />

        <BottomSideMenu
          blueClickEdit={() =>
            navigate('/' + RouterEnum.VEHICLE_MANAGEMENT + '/' + RouterEnum.VEHICLE_FORM_EDIT, {
              state: { carId: car.id },
            })
          }
          redClick={() => onDeleteModalOpen()}
          darkClickSettings={() => onConfirmModalOpen()}
        />

        <DeleteModal
          isDeleteModalOpen={isDeleteModalOpen}
          onDeleteModalClose={onDeleteModalClose}
          titleValue={car.displayName}
          onClickAction={() => {
            mutateDelete.mutate(car.id, {
              onSuccess: () => {
                query.invalidateQueries(ApiRouteEnum.VEHICLE)
                query.invalidateQueries('workspaceByQuotas')
                navigate('/' + RouterEnum.VEHICLE_MANAGEMENT)
                showCustomToast({
                  status: 'success',
                  title: 'Položka úspešne odstránená',
                  label: (
                    <div>
                      Vozidlo <b>{car.displayName}</b> bolo úspešne odstránené z evidencie
                    </div>
                  ),
                })
              },
              onError: () => {
                showCustomToast({
                  status: 'error',
                  title: 'Položku sa nepodarilo odstrániť',
                  label: (
                    <div>
                      Vozidlo <b>{car.displayName}</b> sa nepodarilo odstrániť
                    </div>
                  ),
                })
              },
            })
          }}
        />

        <CreateServiceModal
          isConfirmModalOpen={isConfirmModalOpen}
          onConfirmModalClose={onConfirmModalClose}
          onClickAction={() =>
            navigate(
              '/' + RouterEnum.VEHICLE_SERVIS_OVERVIEW + '/' + RouterEnum.VEHICLE_SERVIS_CREATE,
              { state: { car: { label: car.displayName, value: car.id } } },
            )
          }
        />
      </Flex>
    </>
  )
}

export default VehicleDetailMobile
