import { Flex, Grid } from '@chakra-ui/react'
import { Box } from '../../components/Box'
import { colors } from '../../theme'
import ProgressBar from '../../components/ProgressBar'
import Button from '../../components/Button'
import IRegister from '../../model/interface/IRegister'
import { useRegisterUser } from '../../model/api/useRegisterUser'
import { useEffect, useState } from 'react'
import LogoVivem from '../../assets/logoVivem'
import { useLoginUser } from '../../model/api/useLoginUser'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../components/Spinner'
import RouterEnum from '../../model/enums/RouterEnum'
import useIsMobile from '../../model/helpers/useIsMobile'

const RegisterComplete: React.FC<IRegister> = ({ registerData, onSetRegisterStep }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const { mutate: mutation, isLoading: isRegisterLoading } = useRegisterUser(
    () => setIsSuccess(true),
    () => setIsSuccess(false),
  )
  const { mutate: mutateLogin } = useLoginUser(
    isMobile ? () => navigate(RouterEnum.MENU) : () => navigate(RouterEnum.HOMEPAGE),
    () => {},
  )

  const handleRegister = () => {
    mutation({
      ...registerData,
      companyIdentificationNumber: registerData.companyIdentificationNumber !== '' ? registerData.companyIdentificationNumber : null,
      vatId: registerData.vatId !== '' ? registerData.vatId : null,
      workspaceMarketingData: {
        ...registerData.workspaceMarketingData,
        url: registerData.workspaceMarketingData.url !== '' ? registerData.workspaceMarketingData.url : null,
        title: registerData.workspaceMarketingData.title !== '' ? registerData.workspaceMarketingData.title : null,
        description:
          registerData.workspaceMarketingData.description !== ''
            ? registerData.workspaceMarketingData.description
            : null,
      },
      address: {
        ...registerData.address,
        city: registerData.address.city !== '' ? registerData.address.city : null,
        street: registerData.address.street !== '' ? registerData.address.street : null,
        houseNumber:
          registerData.address.houseNumber !== '' ? registerData.address.houseNumber : null,
        zipCode: registerData.address.zipCode !== '' ? registerData.address.zipCode : null,
        state: registerData.address?.state || 'slovensko',
      },
    })
  }

  useEffect(() => {
    handleRegister()
  }, [])

  const handleCompleteClick = () => {
    mutateLogin({ username: registerData.user.email, password: registerData.user.password })
  }

  return (
    <>
      <Flex
        w={'100%'}
        h={'15%'}
        bg={colors.gray[700]}
        flexFlow={'column nowrap'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Flex w={'25rem'} flexFlow={'column nowrap'} alignItems={'center'} h={'90%'}>
          <Box fontSize={'1.5rem'} fontWeight={'semibold'} color={colors.gray[50]} mb={'10px'}>
            Ďakujeme
          </Box>
          <ProgressBar step={2} />
        </Flex>
      </Flex>
      <Grid h={'75%'} w={'100%'} placeItems={'center'} className="background">
        <Flex
          w={'20rem'}
          p={5}
          bg={colors.blue[50]}
          alignItems={'center'}
          flexFlow={'column nowrap'}
          justifyContent={'space-between'}
          zIndex={10}
          fontWeight={'semibold'}
        >
          <LogoVivem width={'6rem'} />
          <br />
          {isRegisterLoading ? (
            <Spinner />
          ) : isSuccess ? (
            <>
              <Box textAlign={'center'}>
                Vitajte v aplikácii
                <br />
                Virtual Vehicle Mechanic
              </Box>
              <br />
              <Box>ďakujeme za Vašu registráciu</Box>
              <br />
              <Box mb={'2rem'}>Team ViVeM</Box>
              <Button
                variant={'blue'}
                size={'full'}
                fontSize={'1rem'}
                onClick={() => handleCompleteClick()}
              >
                dokončiť
              </Button>
            </>
          ) : (
            <>
              <Box mb={'2rem'}>Pri registrácii došlo k chybe</Box>
              <Box mb={'2rem'}>Pokúste sa o ňu znova</Box>
              <Button
                variant={'blue'}
                size={'full'}
                fontSize={'1rem'}
                onClick={() => onSetRegisterStep(0)}
              >
                začať znovu
              </Button>
            </>
          )}
        </Flex>
      </Grid>
      <Flex w={'100%'} h={'10%'} bg={colors.gray[700]} />
    </>
  )
}

export default RegisterComplete
