import { IconButton as ChakraIconButton } from "@chakra-ui/react";
import { Box } from "./Box";

const IconButton = (props: any) => {
  if (props.size === "full") {
    return (
      <Box w={"90%"}>
        <ChakraIconButton {...props} />
      </Box>
    );
  }
  return <ChakraIconButton {...props} />;
};

export default IconButton;
