import * as Yup from 'yup'
import { IS_PHONE, IS_REQUIRED, IS_URL } from '../constants/ValidationMessages'
export const RegisterStep2Schema = Yup.object({
  title: Yup.string().required(IS_REQUIRED),
  phone: Yup.string()
    .transform((value) => {
      return value ? value.replace(/\s/g, '') : value
    })
    .matches(/^((00|\+)42[0-1])?\d{9}$/, IS_PHONE)
    .required(IS_REQUIRED),
  url: Yup.string()
    .matches(
      /^(?:(https?:\/\/)?(?:www\.)?[a-z0-9.-]+\.[a-z]{2,})(\/[^\s]*)?$/i,
      IS_URL,
    )
    .required(IS_REQUIRED),
  description: Yup.string().optional().nullable(),
  showOnWeb: Yup.boolean().optional(),
})
