import { Flex, Grid, useDisclosure } from '@chakra-ui/react'
import Menu from '../../../components/Menu'
import useIsMobile from '../../../model/helpers/useIsMobile'
import { useEffect } from 'react'
import RouterEnum from '../../../model/enums/RouterEnum'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { colors } from '../../../theme'
import Button from '../../../components/Button'
import { Box } from '../../../components/Box'
import TurnOffIcon from '../../../icons/TurnOffIcon'
import GlobeIcon from '../../../icons/GlobeIcon'
import Edit from '../../../icons/Edit'
import PersonIcon from '../../../icons/PersonIcon'
import ServerIcon from '../../../icons/ServerIcon'
import useGetWorkspaceById from '../../../model/api/useGetWorkspaceById'
import Spinner from '../../../components/Spinner'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody } from '@chakra-ui/react'
import WorkspaceMarketingForm from '../WorkspaceMarketingForm'
import WorkspaceDataForm from '../WorkspaceDataForm'

const WorkspaceMenu = () => {
  const navigate = useNavigate()
  const data = useOutletContext() as any
  const { data: workspaceData, isLoading } = useGetWorkspaceById(data.workspace.id)
  const isMobile = useIsMobile()

  const {
    isOpen: isMarketingModalOpen,
    onOpen: onMarketingModalOpen,
    onClose: onMarketingModalClose,
  } = useDisclosure()

  const {
    isOpen: isWorkspaceDataModalOpen,
    onOpen: onWorkspaceDataModalOpen,
    onClose: onWorkspaceDataModalClose,
  } = useDisclosure()

  const handleLogoutClick = () => {
    localStorage.setItem('token', '')
    navigate(RouterEnum.LOGIN)
  }

  useEffect(() => {
    if (!isMobile) {
      navigate(RouterEnum.HOMEPAGE)
    }
  }, [isMobile])

  if (isLoading) {
    return (
      <>
        <Grid
          fontSize={'1.2rem'}
          fontWeight={'semibold'}
          w={'100%'}
          h={'4rem'}
          placeItems={'center'}
          bg={colors.gray[700]}
          color={colors.blue[100]}
          zIndex={100}
          position={'sticky'}
          top={'0px'}
        >
          Nastavenia workspace
        </Grid>
        <Grid h={'40rem'}>
          <Spinner />
        </Grid>
        <Menu type={'basic'} />
      </>
    )
  }

  return (
    <Flex flexFlow={'column'} h={'100%'} w={'100%'} bg={colors.blue[50]}>
      <Grid
        fontSize={'1.2rem'}
        fontWeight={'semibold'}
        w={'100%'}
        h={'6rem'}
        placeItems={'center'}
        bg={colors.gray[700]}
        color={colors.blue[100]}
        zIndex={100}
        position={'sticky'}
        top={'0px'}
      >
        Nastavenia workspace
        <Box fontSize={'0.9rem'}>
          {data.firstname} {data.lastname}
        </Box>
        <Box fontSize={'0.9rem'} fontWeight={'normal'}>
          {workspaceData.name}
        </Box>
      </Grid>
      <Flex
        flexFlow={'column'}
        justifyContent={'center'}
        h={'78%'}
        alignItems={'center'}
        gap={'1rem'}
      >
        <Button variant={'blue'} size="medium" onClick={() => handleLogoutClick()} h={'6rem'}>
          <TurnOffIcon />
          <Box w={'70%'} whiteSpace={'pre-wrap'}>
            odhlásiť sa
          </Box>
        </Button>
        <Button variant={'basic'} size="medium" onClick={() => onMarketingModalOpen()} h={'6rem'}>
          <GlobeIcon />
          <Box w={'70%'} whiteSpace={'pre-wrap'}>
            nastavenia workspace prezentácie
          </Box>
        </Button>
        <Button
          variant={'basic'}
          size="medium"
          onClick={() => onWorkspaceDataModalOpen()}
          h={'6rem'}
        >
          <Edit />
          <Box w={'70%'} whiteSpace={'pre-wrap'}>
            zmeniť workspace údaje
          </Box>
        </Button>
        <Button
          variant={'basic'}
          size="medium"
          onClick={() => navigate(RouterEnum.EMPLOYEES_MANAGEMENT)}
          h={'6rem'}
        >
          <PersonIcon />
          <Box w={'70%'} whiteSpace={'pre-wrap'}>
            správa zamestnancov
          </Box>
        </Button>
        <Button
          variant={'basic'}
          size="medium"
          onClick={() =>
            navigate('/' + RouterEnum.WORKSPACE_MANAGEMENT + RouterEnum.WORKSPACE_LIMITS)
          }
          h={'6rem'}
        >
          <ServerIcon />
          <Box w={'70%'} whiteSpace={'pre-wrap'}>
            limity
          </Box>
        </Button>
      </Flex>

      <Menu back={() => navigate(-1)} type={'basic'} />

      <Modal
        size={'full'}
        isOpen={isMarketingModalOpen}
        onClose={onMarketingModalClose}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display={'flex'} alignItems={'center'} p={0}>
            <Grid w={'100%'} placeItems={'center'} h={'4rem'}>
              nastavenie workspace prezentácie
            </Grid>
          </ModalHeader>

          <ModalBody>
            <WorkspaceMarketingForm
              workspaceData={workspaceData}
              onMarketingModalClose={onMarketingModalClose}
            />
            <Menu back={onMarketingModalClose} type={'basic'} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        size={'full'}
        isOpen={isWorkspaceDataModalOpen}
        onClose={onWorkspaceDataModalClose}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody w={'100%'} p={0}>
            <WorkspaceDataForm
              workspaceData={workspaceData}
              onWorkspaceDataModalClose={onWorkspaceDataModalClose}
            />
            <Menu back={onWorkspaceDataModalClose} type={'basic'} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default WorkspaceMenu
