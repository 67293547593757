import { useMutation } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useUpdateUser = (successCallback: () => void, errorCallback: () => void) => {
  return useMutation(
    ({
      updateUserData,
      id,
    }: {
      updateUserData: { firstname: string; lastname: string; email: string }
      id: string
    }) => api.put(`${ApiRouteEnum.USER}/${id}`, updateUserData),
    {
      mutationKey: [ApiRouteEnum.USER],
      onSuccess: () => {
        successCallback()
      },
      onError: () => {
        errorCallback()
      },
    },
  )
}

export default useUpdateUser
