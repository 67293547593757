import { useState } from 'react'
import RegisterAfterInviteForm from './RegisterAfterInviteForm'
import RegisterAfterInviteWelcome from './RegisterAfterInviteWelcome'
import RegisterAfterInviteComplete from './RegisterAfterInviteComplete'

const RegisterAfterInviteParent = () => {
  const [registerStep, setRegisterStep] = useState<number>(0)
  if (registerStep === 1) {
    return <RegisterAfterInviteForm onSetRegisterStep={() => setRegisterStep((prev) => prev + 1)} />
  }
  if (registerStep === 2) {
    return <RegisterAfterInviteComplete />
  }
  return (
    <RegisterAfterInviteWelcome onSetRegisterStep={() => setRegisterStep((prev) => prev + 1)} />
  )
}

export default RegisterAfterInviteParent
