import { Flex, Grid } from '@chakra-ui/react'
import { Box } from '../../components/Box'
import { colors } from '../../theme'
import ProgressBar from '../../components/ProgressBar'
import Menu from '../../components/Menu'
import { Field, Form, Formik } from 'formik'
import { Input } from '../../components/Input'
import * as Yup from 'yup'
import { IS_REQUIRED } from '../../model/constants/ValidationMessages'
import getFieldStatus from '../../model/helpers/StatusHelper'
import IRegister from '../../model/interface/IRegister'
import { useEffect, useState } from 'react'
import { InfoIcon } from '@chakra-ui/icons'
import { RegisterFormLabels } from '../../model/constants/FormLabels'
import { useCustomToast } from '../../components/Toast'
import { RegisterStep1Schema } from '../../model/schemas/RegisterStep1Schema'
import { ToastHelper } from '../../model/helpers/ToastHelper'



const RegisterStep1: React.FC<IRegister> = ({
  registerData,
  onSetRegisterData,
  onSetRegisterStep,
}) => {
  const [showInfo, setShowInfo] = useState(false)
  const { showCustomToast } = useCustomToast()
  useEffect(() => {
    if (registerData.email === '') {
      onSetRegisterData({
        ...registerData,
        email: registerData.user.email,
      })
    }
  }, [])

  const handleFormSubmit = (values: Yup.InferType<typeof RegisterStep1Schema>) => {
    onSetRegisterStep((prev) => prev + 1)
  }

  const handleFieldChange = (e: any) => {
    const { value, name } = e.target as HTMLInputElement
    if (name === 'city' || name === 'street' || name === 'houseNumber' || name === 'zipCode') {
      onSetRegisterData({
        ...registerData,
        address: {
          ...registerData.address,
          [name]: value,
          state: 'Slovensko',
        },
      })
    } else {
      onSetRegisterData({
        ...registerData,
        [name]: value,
      })
    }
  }

  const initialValues = {
    name: registerData.name || '',
    email: registerData.email !== '' ? registerData.email : registerData.user.email,
    companyIdentificationNumber: registerData.companyIdentificationNumber || '',
    vatId: registerData.vatId || '',
    city: registerData.address?.city || null,
    street: registerData.address?.street || null,
    houseNumber: registerData.address?.houseNumber || null,
    zipCode: registerData.address?.zipCode || null,
  }
  return (
    <>
      <Flex
        w={'100%'}
        h={'15%'}
        bg={colors.gray[700]}
        flexFlow={'column nowrap'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Flex w={'25rem'} flexFlow={'column nowrap'} alignItems={'center'} h={'90%'}>
          <Box fontSize={'1.5rem'} fontWeight={'semibold'} color={colors.gray[50]} mb={'10px'}>
            Údaje o servise
          </Box>
          <ProgressBar />
        </Flex>
      </Flex>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={RegisterStep1Schema}
        reValidateMode={'onChange'}
      >
        {({ errors, touched, values, submitForm, setFieldTouched }) => {
          return (
            <>
              <Grid placeItems={'center'} bg={colors.blue[50]} h={'75%'} w={'100%'}>
                <Form style={{ width: '35rem' }}>
                  <Grid
                    w={'100%'}
                    templateColumns={'repeat(2, 1fr)'}
                    templateRows={'repeat(4, 1fr)'}
                    gridAutoFlow={'column'}
                    justifyItems={'center'}
                    gap={'0.8rem'}
                    columnGap={'4rem'}
                    onChange={handleFieldChange}
                  >
                    <Field
                      name="name"
                      label="názov servisu"
                      type="text"
                      component={Input}
                      status={getFieldStatus(errors, touched, 'name')}
                    />
                    <Box position={'relative'}>
                      <Field
                        name="email"
                        label="e-mail"
                        type="email"
                        component={Input}
                        status={getFieldStatus(errors, touched, 'email')}
                      />
                      <Grid
                        position={'absolute'}
                        right={showInfo ? '-12.2rem' : '-1.7rem'}
                        top={0}
                        placeItems={'center'}
                        h={'3rem'}
                        p={1}
                        w={showInfo ? '12rem' : '1.5rem'}
                        bg={colors.gray[700]}
                        onMouseEnter={() => setShowInfo(true)}
                        onMouseLeave={() => setShowInfo(false)}
                        fontSize={'0.6rem'}
                        color={colors.blue[100]}
                        textAlign={'center'}
                        zIndex={100}
                      >
                        {showInfo ? (
                          'Tento email bude použitý, pokiaľ zákazník odpíše na systémové emaily.'
                        ) : (
                          <InfoIcon fontSize={'1rem'} color={colors.blue[500]} />
                        )}
                      </Grid>
                    </Box>
                    <Field
                      name="companyIdentificationNumber"
                      label="IČO"
                      type="text"
                      component={Input}
                      status={getFieldStatus(
                        errors,
                        touched,
                        'companyIdentificationNumber',
                        true,
                        values,
                      )}
                    />
                    <Field
                      name="vatId"
                      label="DIČ"
                      type="text"
                      component={Input}
                      status={getFieldStatus(errors, touched, 'vatId', true, values)}
                    />
                    <Field
                      name="city"
                      label="mesto"
                      type="text"
                      component={Input}
                      status={getFieldStatus(errors, touched, 'city', true, values)}
                    />
                    <Field
                      name="street"
                      label="ulica"
                      type="text"
                      component={Input}
                      status={getFieldStatus(errors, touched, 'street', true, values)}
                    />
                    <Field
                      name="houseNumber"
                      label="popisné číslo"
                      type="text"
                      component={Input}
                      status={getFieldStatus(errors, touched, 'houseNumber', true, values)}
                    />
                    <Field
                      name="zipCode"
                      label="PSČ"
                      type="text"
                      component={Input}
                      status={getFieldStatus(errors, touched, 'zipCode', true, values)}
                    />
                  </Grid>
                </Form>
              </Grid>
              <Menu
                h={'10%'}
                type={'arrowsOnly'}
                back={() => onSetRegisterStep((prev) => prev - 1)}
                next={() =>
                  ToastHelper({
                    submitForm,
                    errors,
                    setFieldTouched,
                    labels: RegisterFormLabels,
                    showCustomToast,
                  })
                }
              />
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default RegisterStep1
