import { createBrowserRouter } from 'react-router-dom'
import App from './App'
import RouterEnum from './model/enums/RouterEnum'
import RegisterParent from './pages/register/RegisterParent'
import RegisterAfterInviteParent from './pages/registerAfterInvite/RegisterAfterInviteParent'
import VehicleOverviewWrapper from './pages/vehicleManagement/wrappers/VehicleOverviewWrapper'
import VehicleDetailWrapper from './pages/vehicleManagement/wrappers/VehicleDetailWrapper'
import VehicleFormMasterWrapper from './pages/vehicleManagement/wrappers/VehicleFormMasterWrapper'
import LoginWrapper from './pages/login/LoginWrapper'
import ServicesOverviewWrapper from './pages/servicesManagement/wrappers/ServiceOverviewWrapper'
import ServiceFormMasterWrapper from './pages/servicesManagement/wrappers/ServicFormMasterWrapper'
import ServiceDetailWrapper from './pages/servicesManagement/wrappers/ServiceDetailWrapper'
import MainMenu from './components/MainMenu'
import ShareMenu from './components/ShareMenu'
import ForgottenPasswordWrapper from './pages/forgottenPassword/ForgottenPasswordWrapper'
import ForgottenPasswordChangeWrapper from './pages/forgottenPassword/ForgottenPasswordChangeWrapper'
import CustomersOverviewWrapper from './pages/customerManagement/wrappers/CustomerOverviewWrapper'
import AccountManagementWrapper from './pages/accountManagement/wrappers/AccountManagementWrapper'
import AddMenu from './components/AddMenu'
import WorkspaceOverviewWrapper from './pages/workspaceManagement/wrappers/WorkspaceOverviewWrapper'
import WorkspaceLimitsMobile from './pages/workspaceManagement/mobile/WorkspaceLimitsMobile'
import EmployeeOverviewWrapper from './pages/workspaceManagement/wrappers/EmployeeOverviewWrapper'

export const router = createBrowserRouter([
  {
    path: RouterEnum.HOMEPAGE,
    element: <App />,
    children: [
      // SERVICE MANAGEMENT
      {
        path: RouterEnum.VEHICLE_SERVIS_OVERVIEW,
        element: <ServicesOverviewWrapper />,
      },
      {
        path: RouterEnum.VEHICLE_SERVIS_OVERVIEW + '/' + RouterEnum.VEHICLE_SERVIS_CREATE,
        element: <ServiceFormMasterWrapper />,
      },
      {
        path: RouterEnum.VEHICLE_SERVIS_OVERVIEW + '/' + RouterEnum.VEHICLE_SERVIS_EDIT,
        element: <ServiceFormMasterWrapper />,
      },
      {
        path: RouterEnum.VEHICLE_SERVIS_OVERVIEW + '/' + RouterEnum.VEHICLE_SERVIS_DETAIL,
        element: <ServiceDetailWrapper />,
      },

      // VEHICLE MANAGEMENT
      {
        path: RouterEnum.VEHICLE_MANAGEMENT,
        element: <VehicleOverviewWrapper />,
      },
      {
        path: RouterEnum.VEHICLE_MANAGEMENT + '/' + RouterEnum.VEHICLE_FORM_CREATE,
        element: <VehicleFormMasterWrapper />,
      },
      {
        path: RouterEnum.VEHICLE_MANAGEMENT + '/' + RouterEnum.VEHICLE_FORM_EDIT,
        element: <VehicleFormMasterWrapper />,
      },
      {
        path: RouterEnum.VEHICLE_MANAGEMENT + '/' + RouterEnum.VEHICLE_DETAIL,
        element: <VehicleDetailWrapper />,
      },

      // CUSTOMERS MANAGEMENT
      {
        path: RouterEnum.CUSTOMER_MANAGEMENT,
        element: <CustomersOverviewWrapper />,
      },
      // ACCOUNT MANAGEMENT
      {
        path: RouterEnum.ACCOUNT_MANAGEMENT,
        element: <AccountManagementWrapper />,
      },

      // WORKSPACE MANAGEMENT
      {
        path: RouterEnum.WORKSPACE_MANAGEMENT,
        element: <WorkspaceOverviewWrapper />,
      },
      {
        path: RouterEnum.WORKSPACE_MANAGEMENT + '/' + RouterEnum.EMPLOYEES_MANAGEMENT,
        element: <EmployeeOverviewWrapper />,
      },
      {
        path: RouterEnum.WORKSPACE_MANAGEMENT + RouterEnum.WORKSPACE_LIMITS,
        element: <WorkspaceLimitsMobile />,
      },
    ],
  },
  {
    path: RouterEnum.LOGIN,
    element: <LoginWrapper />,
  },
  {
    path: RouterEnum.REGISTER,
    element: <RegisterParent />,
  },
  {
    path: RouterEnum.FORGOTTEN_PASSWORD,
    element: <ForgottenPasswordWrapper />,
  },
  {
    path: RouterEnum.FORGOTTEN_PASSWORD_CHANGE,
    element: <ForgottenPasswordChangeWrapper />,
  },
  {
    path: RouterEnum.REGISTER_AFTER_INVITE,
    element: <RegisterAfterInviteParent />,
  },
  {
    path: RouterEnum.MENU,
    element: <MainMenu />,
  },
  {
    path: RouterEnum.SHARE_MENU,
    element: <ShareMenu />,
  },
  {
    path: RouterEnum.ADD_MENU,
    element: <AddMenu />,
  },
])
