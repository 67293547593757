import { Flex } from '@chakra-ui/react'
import { colors } from '../../theme'
import { Input } from '../../components/Input'
import Button from '../../components/Button'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { IS_REQUIRED } from '../../model/constants/ValidationMessages'
import { useNavigate } from 'react-router-dom'
import getFieldStatus from '../../model/helpers/StatusHelper'
import LogoVivem from '../../assets/logoVivem'
import { useState } from 'react'
import RouterEnum from '../../model/enums/RouterEnum'
import { useForgottenPasswordChange } from '../../model/api/useForgottenPasswordChange'
import Save from '../../icons/Save'
import { Box } from '../../components/Box'
import { ForgottenPasswordSchema } from '../../model/schemas/ForgottenPasswordSchema'

const ForgottenPasswordChangeMobile = () => {
  const navigate = useNavigate()
  const { mutate: changePassword } = useForgottenPasswordChange()
  const [isSuccess, setIsSuccess] = useState(false)
  const queryParams = new URLSearchParams(window.location.search)
  const userLink = queryParams.get('user_link')
  if (!userLink) {
    return null
  }

  const initialValues = {
    password: '',
    confirmPassword: '',
  }

  const handleFormSubmit = (values: Yup.InferType<typeof ForgottenPasswordSchema>) => {
    changePassword({
      userLink: userLink,
      password: values.password,
    })
    setIsSuccess(true)
    setTimeout(() => {
      navigate(RouterEnum.LOGIN)
    }, 5000)
  }

  return (
    <Flex
      w={'100%'}
      h={'100%'}
      bg={colors.blue[50]}
      alignItems={'center'}
      flexFlow={'column nowrap'}
      justifyContent={'space-between'}
      zIndex={10}
      p={'1rem'}
    >
      <LogoVivem width={'6rem'} style={{ marginTop: '4rem' }} />
      {!isSuccess ? (
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={ForgottenPasswordSchema}
        >
          {({ errors, touched, values }) => {
            return (
              <Form style={{ width: '90%' }}>
                <Flex
                  alignItems={'center'}
                  flexFlow={'column nowrap'}
                  justifyContent={'space-between'}
                  gap={'1rem'}
                >
                  <Field
                    name="password"
                    label="nové heslo"
                    type="password"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'password')}
                  />
                  <Field
                    name="confirmPassword"
                    label="zopakovať heslo"
                    type="password"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'confirmPassword')}
                  />
                  <Button variant={'green'} size={'full'} type="submit" m={'10rem 0 3rem 0'}>
                    <Save />
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>
      ) : (
        <Box m={'auto'} fontWeight={'semibold'} textAlign={'center'} w={'80%'}>
          vaše heslo bolo úspešne zmenené, budete presmerovaný na prihlásenie do 5 sekúnd
        </Box>
      )}
    </Flex>
  )
}

export default ForgottenPasswordChangeMobile
