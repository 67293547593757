import { Flex, Grid } from '@chakra-ui/react'
import { Box } from '../../components/Box'
import { colors } from '../../theme'
import { Field, Form, Formik } from 'formik'
import { Input } from '../../components/Input'
import * as Yup from 'yup'
import { IS_REQUIRED } from '../../model/constants/ValidationMessages'
import getFieldStatus from '../../model/helpers/StatusHelper'
import Button from '../../components/Button'
import Edit from '../../icons/Edit'
import Lock from '../../icons/Lock'
import useGetCurrentUser from '../../model/api/useGetCurrentUser'
import useUpdateUser from '../../model/api/useUpdateUser'
import useUpdatePassword from '../../model/api/useUpdatePassword'
import { useQueryClient } from 'react-query'
import { useCustomToast } from '../../components/Toast'
import ApiRouteEnum from '../../model/enums/ApiRouteEnum'
import { AccountDataFormLabels, AccountPasswordFormLabels } from '../../model/constants/FormLabels'
import { userDataFormSchema } from '../../model/schemas/UserDataFormSchema'
import { passwordDataFormSchema } from '../../model/schemas/PasswordDataFormSchema'
import useInitialValuesAccountData from '../../model/initialValues/useInitialValuesAccountData'
import { ToastHelper } from '../../model/helpers/ToastHelper'

const AccountOverview = () => {
  const successCallback = () => {
    query.invalidateQueries(ApiRouteEnum.USER)
    query.invalidateQueries(ApiRouteEnum.ME)
    showCustomToast({
      status: 'success',
      title: 'Údaje úspešne zmenené',
      label: <div>Vaše údaje boli úspešne zmenené</div>,
    })
  }
  const errorCallback = () => {
    showCustomToast({
      status: 'error',
      title: 'Údaje sa nepodarilo zmeniť',
      label: <div>Pri zmene Vašich údajov nastala chyba</div>,
    })
  }
  const { data, isLoading } = useGetCurrentUser()
  const query = useQueryClient()
  const { showCustomToast } = useCustomToast()
  const mutateUser = useUpdateUser(successCallback, errorCallback)
  const mutatePassword = useUpdatePassword()
  const { initialUserData, initialPasswordData } = useInitialValuesAccountData({ data })

  const handleFormSubmit1 = (values: Yup.InferType<typeof userDataFormSchema>) => {
    mutateUser.mutate({ updateUserData: values, id: data.id })
  }

  const handleFormSubmit2 = (values: Yup.InferType<typeof passwordDataFormSchema>) => {
    mutatePassword.mutate(
      {
        updatePasswordData: { password: values.oldPassword, newPassword: values.newPassword },
        id: data.id,
      },
      {
        onSuccess: () => {
          query.invalidateQueries(ApiRouteEnum.USER)

          showCustomToast({
            status: 'success',
            title: 'Heslo bolo zmenené',
            label: <div>Vaše heslo bolo úspešne zmenené</div>,
          })
        },
        onError: () => {
          showCustomToast({
            status: 'error',
            title: 'Heslo sa nepodarilo zmeniť',
            label: <div>Pri zmene Vašeho hesla nastala chyba</div>,
          })
        },
      },
    )
  }

  if (isLoading) {
    return null
  }

  return (
    <Flex
      w={'100%'}
      h={'100%'}
      alignItems={'center'}
      flexFlow={'column'}
      overflow={'auto'}
      padding={'1%'}
    >
      <Box fontSize={'1.8rem'} fontWeight={'semibold'} w={'100%'} textAlign={'center'} m={'1rem 0'}>
        Nastavenia účtu
      </Box>
      <Grid placeItems={'center'} h={'100%'}>
        <Grid templateColumns={'repeat(2, 1fr)'} templateRows={'repeat(1, 1fr)'} gap={'1rem'}>
          <Formik
            initialValues={initialUserData}
            onSubmit={handleFormSubmit1}
            validationSchema={userDataFormSchema}
          >
            {({ errors, touched, submitForm, setFieldTouched }) => {
              return (
                <>
                  <Grid placeItems={'center'}>
                    <Form style={{ width: '20rem' }}>
                      <Flex justifyItems={'center'} flexFlow={'column nowrap'} gap={1}>
                        <Button
                          onClick={() =>
                            ToastHelper({
                              submitForm,
                              errors,
                              setFieldTouched,
                              labels: AccountDataFormLabels,
                              showCustomToast,
                            })
                          }
                          size="medium"
                          variant={'blue'}
                          w={'100%'}
                        >
                          <Edit fontSize={'2rem'} />
                          zmeniť údaje
                        </Button>
                        <Field
                          bg={colors.blue[100]}
                          name="firstname"
                          label="krstné meno"
                          type="text"
                          component={Input}
                          status={getFieldStatus(errors, touched, 'firstname')}
                        />
                        <Field
                          bg={colors.blue[100]}
                          name="lastname"
                          label="priezvisko"
                          type="text"
                          component={Input}
                          status={getFieldStatus(errors, touched, 'lastname')}
                        />
                        <Field
                          bg={colors.blue[100]}
                          name="email"
                          label="e-mail"
                          type="email"
                          component={Input}
                          status={getFieldStatus(errors, touched, 'email')}
                        />
                      </Flex>
                    </Form>
                  </Grid>
                </>
              )
            }}
          </Formik>
          <Formik
            initialValues={initialPasswordData}
            onSubmit={handleFormSubmit2}
            validationSchema={passwordDataFormSchema}
          >
            {({ errors, touched, submitForm, setFieldTouched}) => {
              return (
                <>
                  <Grid placeItems={'center'}>
                    <Form style={{ width: '20rem' }}>
                      <Flex justifyItems={'center'} flexFlow={'column nowrap'} gap={1}>
                        <Button
                          onClick={() =>
                            ToastHelper({
                              submitForm,
                              errors,
                              setFieldTouched,
                              labels: AccountPasswordFormLabels,
                              showCustomToast,
                            })
                          }
                          size="medium"
                          variant={'blue'}
                          w={'100%'}
                        >
                          <Lock fontSize={'2rem'} />
                          zmena hesla
                        </Button>
                        <Field
                          bg={colors.blue[100]}
                          name="oldPassword"
                          label="staré heslo:"
                          type="password"
                          component={Input}
                          status={getFieldStatus(errors, touched, 'oldPassword')}
                        />
                        <Field
                          bg={colors.blue[100]}
                          name="newPassword"
                          label="nové heslo:"
                          type="password"
                          component={Input}
                          status={getFieldStatus(errors, touched, 'newPassword')}
                        />
                        <Field
                          bg={colors.blue[100]}
                          name="confirmPassword"
                          label="zopakovať heslo:"
                          type="password"
                          component={Input}
                          status={getFieldStatus(errors, touched, 'confirmPassword')}
                        />
                      </Flex>
                    </Form>
                  </Grid>
                </>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Flex>
  )
}

export default AccountOverview
