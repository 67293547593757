import { useQuery } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useGetWorkspaceById = (id: string) => {
  const { data, isLoading } = useQuery(ApiRouteEnum.WORKSPACE_BY_ID, async () => {
    if (!id) return null
    const response = await api.get(`${ApiRouteEnum.WORKSPACE_BY_ID}/${id}`)
    return response.data
  })
  return { data, isLoading }
}

export default useGetWorkspaceById
